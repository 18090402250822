/* eslint-disable */

export const onChangeAmountHandler = (e, setAmount) => {
  const formatCurrency = (number) => {
    const formattedNumber = Intl.NumberFormat("de-DE").format(number);

    return formattedNumber;
  };

  const lastInput = e.target.value.toString().slice(-1);

  const secondLastInput = e.target.value.toString().slice(-2, -1);

  const isNotNumber = isNaN(lastInput);
  const string = e.target.value.toString().slice(0, -1);

  const findDecimal = () => {
    const find = e.target.value.toString().indexOf(",");
    if (find === -1) {
      return false;
    } else {
      return find;
    }
  };

  if (!lastInput || !e.target.value) {
    return setAmount("");
  }

  if (!isNotNumber) {
    if (findDecimal()) {
      const lengthString = e.target.value.toString().slice(findDecimal() + 1);
      if (lengthString.length > 2) {
        return;
      }

      const reformatted =
        string.replace(/\./g, "").replace(/,/, ".") + lastInput;
      if (lastInput === "0" && secondLastInput === ",") {
        return setAmount(formatCurrency(reformatted) + "," + "0");
      }

      if (lastInput === "0" && secondLastInput === "0") {
        return setAmount(formatCurrency(reformatted) + "," + "0" + "0");
      }

      if (lastInput === "0" && secondLastInput !== ",") {
        const reformatted =
          string.replace(/\./g, "").replace(/,/, ".") + lastInput;

        if (lastInput !== "," && secondLastInput === ",") {
          return setAmount(formatCurrency(reformatted) + "0");
        } else if (lastInput !== "," && secondLastInput !== ",") {
          return setAmount(formatCurrency(reformatted) + "0");
        }
      }
    }

    const reformatted = string.replace(/\./g, "").replace(/,/, ".") + lastInput;

    return setAmount(formatCurrency(reformatted));
  } else if (!findDecimal() && lastInput === ".") {
    const reformatted = e.target.value
      .toString()
      .replace(/\./g, "")
      .replace(/,/, ".");

    return setAmount(formatCurrency(reformatted) + ",");
  } else {
    if (lastInput === "," && findDecimal()) {
      const reformatted = string.replace(/\./g, "").replace(/,/, ".");

      const reformatted2 = formatCurrency(reformatted) + ",";
      let commas = [];

      for (var i = 0; i < reformatted2.length; i++) {
        if (reformatted2[i] === ",") commas.push(i);
      }

      if (commas.length > 1) {
        return;
      }
    }
    if (lastInput === ",") {
      const reformatted = string.replace(/\./g, "").replace(/,/, ".");

      return setAmount(formatCurrency(reformatted) + ",");
    } else {
      return;
    }
  }
};

export const amountStringToNumber = (amount) => {
  if (typeof amount === "string") {
    const reformattedAmount = amount
      .toString()
      .replace(/\./g, "")
      .replace(/,/, ".");

    return Number(reformattedAmount);
  } else if (typeof amount === "number") {
    return amount;
  } else {
    throw "Not a number";
  }
};
