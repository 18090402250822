import React from "react";

export default function HouseIcon(props) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...props.style }}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.350008 19.412C0.460605 19.4764 0.581003 19.5058 0.700001 19.5058C0.942196 19.5058 1.17739 19.3812 1.30759 19.1558L4.20429 14.1448V25.4535C4.20429 26.8576 5.34667 28 6.75224 28H21.255C22.6592 28 23.803 26.8576 23.803 25.4535V14.149L26.6933 19.1558C26.8865 19.4904 27.3149 19.6024 27.6495 19.412C27.9855 19.2174 28.1003 18.7904 27.9071 18.4558L18.8744 2.81114C17.8735 1.07798 16.0087 0 14.0067 0C12.0062 0 10.1414 1.07658 9.13903 2.80834L0.0938128 18.4558C-0.0993834 18.7904 0.0154144 19.2188 0.350008 19.412ZM22.403 11.7238L17.6621 3.51113C16.9103 2.20916 15.5103 1.39997 14.0067 1.39997C12.5046 1.39997 11.1032 2.20916 10.3528 3.50833L5.60426 11.7229V25.4535C5.60426 26.0848 6.11945 26.6 6.75224 26.6H21.255C21.8878 26.6 22.403 26.0848 22.403 25.4535V11.7238ZM14.9657 9.80036C12.4983 9.80036 10.2748 11.3511 9.40076 13.6254H8.75026C8.36247 13.6254 8.05027 13.939 8.05027 14.3254C8.05027 14.7117 8.36247 15.0253 8.75026 15.0253H16.4011C16.7875 15.0253 17.1011 14.7117 17.1011 14.3254C17.1011 13.939 16.7875 13.6254 16.4011 13.6254H10.9369C11.7164 12.163 13.2646 11.2003 14.9657 11.2003C16.4231 11.2003 17.8035 11.9045 18.6602 13.0861C18.887 13.3997 19.3252 13.4697 19.6374 13.2415C19.951 13.0147 20.021 12.5779 19.7942 12.2643C18.6742 10.7215 16.8697 9.80036 14.9657 9.80036ZM10.9366 17.895H16.4011C16.7875 17.895 17.1011 17.5814 17.1011 17.195C17.1011 16.8086 16.7875 16.495 16.4011 16.495H9.99384C9.93501 16.4874 9.87449 16.4872 9.81352 16.495H8.75026C8.36247 16.495 8.05027 16.8086 8.05027 17.195C8.05027 17.5814 8.36247 17.895 8.75026 17.895H9.4007C10.276 20.1723 12.4918 21.7203 14.9663 21.7203C16.9052 21.7203 18.7294 20.7725 19.8452 19.1835C20.0678 18.8671 19.9922 18.4318 19.6758 18.2092C19.358 17.9852 18.924 18.0636 18.7 18.3786C17.846 19.5937 16.4502 20.3203 14.9663 20.3203C13.2598 20.3203 11.7149 19.3587 10.9366 17.895Z"
        fill={props.color || "#DD5858"}
      />
    </svg>
  );
}
