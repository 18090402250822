import React, { useState } from "react";

import {
  UiWrapper,
  NavigationWrapper,
  View,
  Text,
  PickerHOC,
  Layout,
  Enfatize,
  TextField,
  Page,
} from "coinscrap-webapp-core";

import Footer from "../common/Footer";
import ButtonFooterDouble from "../common/ButtonFooterDouble";
import Steps from "../common/Steps";
import colors from "../../config/colors";
import routes from "../../config/routes";

import KeyIcon from "../common/svgs/SVGKeyIcon";

import { ExpensesCheck } from "../common/ExpensesCheck";
import CreateExpense from "../CreatePocket/components/CreateExpense";

export default PickerHOC()(() => {
  const {
    query: { name: nameQuery },
  } = Page.use();
  const [name, setName] = useState(nameQuery ? nameQuery : "");
  const [imageUrl, setImageUrl] = useState();
  const [disabled, setDisabled] = useState(true);
  const [tab, setTab] = useState("new");
  const [visibleModal, setVisibleModal] = useState(false);
  const [movements, setMovements] = useState([]);
  const [newMovements, setNewMovements] = useState([
    {
      name: "Plaza Garaje Madrid",
      last: "2020-07-01",
      value: -87,
    },
  ]);

  const { useBackground, useFooter } = UiWrapper.use();
  // eslint-disable-next-line no-unused-vars
  const { navigateTo, useBackRoute } = NavigationWrapper.use();

  useBackground(colors.white);

  // useBackRoute(routes.createPocketIntro);

  useFooter(
    <Footer>
      <ButtonFooterDouble
        icon1
        action2={() =>
          // Pasar datos
          navigateTo(routes.settings)
        }
      />
    </Footer>,
    [disabled, name, imageUrl]
  );
  return (
    <Layout.Container>
      <View horizontal24St topSt>
        <View width95St topSt style={{ marginTop: 10, marginBottom: 10 }}>
          <KeyIcon />
          <Text font28St style={{ margin: "10px 0px" }}>
            Garaje
          </Text>
          <Text font16St>
            Hemos encontrado estos productos. Si lo necesitas puedes buscarlos
            manualmente o añadir los que quieras.
          </Text>
        </View>
      </View>
      <View
        flexSt
        startSt
        fullWidthSt
        fullHeightSt
        borderTop2St
        style={{ padding: "15px 16px 19px" }}
      >
        <View fullWidthSt>
          <View fullWidthSt horizontalSt tabWhiteSt>
            <Text
              font14St
              boldSt
              notActivebuttonSt
              activebuttonSt={tab === "actual"}
              onClick={() => setTab("actual")}
            >
              Movimientos
            </Text>
            <Text
              font14St
              boldSt
              notActivebuttonSt
              activebuttonSt={tab === "new"}
              onClick={() => setTab("new")}
            >
              Nuevos movimientos
            </Text>
          </View>
        </View>
        {tab === "actual" ? (
          movements.length > 0 ? (
            movements.map((item, index) => {
              return <ExpensesCheck item={item} index={index} data={[]} />;
            })
          ) : (
            <Text font24St style={{ margin: "10px 0px", lineHeight: 1.2 }}>
              Estamos pendientes de gastos que se registren en{" "}
              <Enfatize font24St boldSt>
                Garaje
              </Enfatize>{" "}
              para añadirlos
            </Text>
          )
        ) : newMovements.length > 0 ? (
          newMovements.map((item, index) => {
            return <ExpensesCheck item={item} index={index} data={[]} />;
          })
        ) : (
          <Text font24St style={{ margin: "10px 0px", lineHeight: 1.2 }}>
            Estamos pendientes de gastos que se registren en
            <Enfatize font24St boldSt>
              {" "}
              Garaje
            </Enfatize>{" "}
            para añadirlos
          </Text>
        )}
        <CreateExpense
          title="Añadir nuevo"
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
        />
      </View>
    </Layout.Container>
  );
});
