import React from "react";

export default function CutleryIcon(props) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...props.style }}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3 0.777778C3 0.347873 3.34787 0 3.77778 0C4.20768 0 4.55556 0.347873 4.55556 0.777778V7C4.55556 8.28668 5.60221 9.33333 6.88889 9.33333C7.31879 9.33333 7.66667 9.68121 7.66667 10.1111V25.6667C7.66667 26.0958 8.0153 26.4444 8.44444 26.4444C8.87359 26.4444 9.22222 26.0958 9.22222 25.6667V10.1111C9.22222 9.68121 9.5701 9.33333 10 9.33333C11.2867 9.33333 12.3333 8.28668 12.3333 7V0.777778C12.3333 0.347873 12.6812 0 13.1111 0C13.541 0 13.8889 0.347873 13.8889 0.777778V7C13.8889 8.87836 12.5506 10.4491 10.7778 10.8107V25.6667C10.7778 26.9533 9.73112 28 8.44444 28C7.15777 28 6.11111 26.9533 6.11111 25.6667V10.8107C4.33832 10.4491 3 8.87836 3 7V0.777778ZM7.66667 0.777778V7C7.66667 7.4299 7.31879 7.77778 6.88889 7.77778C6.45898 7.77778 6.11111 7.4299 6.11111 7V0.777778C6.11111 0.347873 6.45898 0 6.88889 0C7.31879 0 7.66667 0.347873 7.66667 0.777778ZM10.7778 7C10.7778 7.4299 10.4299 7.77778 10 7.77778C9.5701 7.77778 9.22222 7.4299 9.22222 7V0.777778C9.22222 0.347873 9.5701 0 10 0C10.4299 0 10.7778 0.347873 10.7778 0.777778V7ZM21.6667 17.1111V25.6667C21.6667 26.9533 20.62 28 19.3333 28C18.0467 28 17 26.9533 17 25.6667V0.777778C17 0.347873 17.3479 0 17.7778 0H19.3333C22.3351 0 24.7778 2.44271 24.7778 5.44444V16.3333C24.7778 16.7632 24.4299 17.1111 24 17.1111H21.6667ZM18.5556 1.55556V25.6667C18.5556 26.0958 18.9042 26.4444 19.3333 26.4444C19.7625 26.4444 20.1111 26.0958 20.1111 25.6667V16.3333C20.1111 15.9034 20.459 15.5556 20.8889 15.5556H23.2222V5.44444C23.2222 3.30024 21.4775 1.55556 19.3333 1.55556H18.5556Z"
        fill="#DD5858"
      />
    </svg>
  );
}
