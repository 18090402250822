import React from 'react'

export default function CameraIcon(props){
    return(
        <svg 
            width="28" 
            height="24" 
            viewBox="0 0 28 24" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            style={{...props.styleSVG}}
        >
            <path fill-rule="evenodd" clip-rule="evenodd" d="M27.6111 17.6C27.6111 20.6878 25.1694 23.2 22.1667 23.2H5.83336C2.83068 23.2 0.388916 20.6878 0.388916 17.6V7.70845C0.388916 5.44302 2.18069 3.60005 4.38319 3.60005L8.55558 3.59663C8.55558 2.05444 9.77447 0.800049 11.2738 0.800049H16.7262C18.2256 0.800049 19.4445 2.05444 19.4445 3.59663V3.60005H23.6169C25.8194 3.60005 27.6111 5.44302 27.6111 7.70845V17.6ZM4.38319 5.00005C2.93169 5.00005 1.75003 6.2148 1.75003 7.70845V17.6C1.75003 19.9161 3.58168 21.8 5.83336 21.8H22.1667C24.4184 21.8 26.25 19.9161 26.25 17.6V7.70845C26.25 6.2148 25.0684 5.00005 23.6169 5.00005H18.7639C18.3878 5.00005 18.0834 4.68696 18.0834 4.30005V3.59663C18.0834 2.82622 17.4746 2.20005 16.7262 2.20005H11.2738C10.5255 2.20005 9.91669 2.82622 9.91669 3.59663V4.30005C9.91669 4.68696 9.6123 5.00005 9.23614 5.00005H4.38319ZM20.125 9.20005H22.8472C23.2234 9.20005 23.5278 8.88696 23.5278 8.50005C23.5278 8.11313 23.2234 7.80005 22.8472 7.80005H20.125C19.7489 7.80005 19.4445 8.11313 19.4445 8.50005C19.4445 8.88696 19.7489 9.20005 20.125 9.20005ZM14 19C17.0027 19 19.4445 16.4878 19.4445 13.4C19.4445 10.3123 17.0027 7.80005 14 7.80005C10.9973 7.80005 8.55558 10.3123 8.55558 13.4C8.55558 16.4878 10.9973 19 14 19ZM14 9.20005C16.2517 9.20005 18.0834 11.084 18.0834 13.4C18.0834 15.7161 16.2517 17.6 14 17.6C11.7483 17.6 9.91669 15.7161 9.91669 13.4C9.91669 11.084 11.7483 9.20005 14 9.20005Z" fill="#137E84"/>
        </svg>
    )
}