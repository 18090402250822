import React from "react";

export default function LogoSantander(props) {
  let fill = "#EC0000";

  if (props.style.color) {
    fill = props.style.color;
  }
  return (
    <svg
      width="41"
      height="38"
      viewBox="0 0 41 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...props.style }}
    >
      <g id="Shape 3">
        <path
          id="Shape"
          d="M28.7723 17.4052C28.7119 16.0124 28.3148 14.655 27.615 13.4495L21.3297 2.61832C20.8576 1.80577 20.5216 0.92128 20.3352 0L20.0731 0.453548C18.5177 3.13174 18.5177 6.43936 20.0731 9.11755L25.1006 17.7828C26.6558 20.461 26.6558 23.7685 25.1006 26.4468L24.8385 26.9003C24.6521 25.9787 24.3161 25.0938 23.8441 24.2808L19.2403 16.3486L16.301 11.2835C15.8295 10.4707 15.4936 9.58632 15.3065 8.66523L15.0444 9.11755C13.4949 11.7863 13.4893 15.0806 15.0298 17.7546L20.0731 26.4468C21.6288 29.1253 21.6288 32.4334 20.0731 35.112L19.8098 35.5655C19.6233 34.6439 19.2873 33.7591 18.8154 32.946L12.5301 22.1148C11.7023 20.6889 11.3006 19.0552 11.3727 17.4077C4.64413 19.1545 0 22.8674 0 27.1675C0 33.1507 8.98699 38 20.0731 38C31.1581 38 40.1451 33.1507 40.1451 27.1688C40.1451 22.8686 35.5009 19.1545 28.7723 17.4052Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}
