import React, { useEffect, useState } from "react";

import { View, Text, UiWrapper, Var } from "coinscrap-webapp-core";
import moment from "moment";
import { getCurrency } from "../../../libs/Currency";

export default ({ list, tab, setTab }) => {
  const [max, setMax] = useState(0);
  const [expenses, setExpenses] = useState(list);
  console.log({ list });
  const { windowDimensions } = UiWrapper.use();

  useEffect(() => {
    expenses.map((item) => {
      if (item.value > max) {
        setMax(item.value);
      }
      return null;
    });
  }, [expenses, max]);

  useEffect(() => {
    setExpenses(list);
  }, [list]);

  const width = windowDimensions.width - 16;

  return (
    <View relativeSt endSt style={{ width: width, height: 132, marginTop: 15 }}>
      <View
        spaceBetweenSt
        fullWidthSt
        horizontalSt
        relativeSt
        style={{ zIndex: 10 }}
      >
        {expenses.map((item, index) => {
          const height = (100 * expenses[index].value) / max;
          return (
            <View
              endSt
              style={{ width: width / 3, height: 58 }}
              onClick={() => setTab(expenses[index])}
            >
              <View
                graphicRedSt
                style={{
                  height: height < 1 ? 1 : height + "%",
                  background: "#732645",
                }}
              />
            </View>
          );
        })}
      </View>
      <View
        spaceBetweenSt
        fullWidthSt
        horizontalSt
        whiteBc2St
        relativeSt
        style={{
          height: 52,
          borderRadius: 5,
          bottom: 0,
          zIndex: 1,
        }}
      >
        {expenses.map((item, index) => {
          const month = new Date(item.date);
          return (
            <View style={{ width: width / 3 }}>
              <View
                endSt
                tabGraphicSt
                tabGraphicSelectedSt={tab === item}
                onClick={() => setTab(item)}
              >
                <Text
                  font15St
                  capitalizeSt
                  regularSt
                  boldSt={tab === item}
                  greenSt={tab === item}
                >
                  {moment(month).format("MMMM")}
                </Text>
                <Text
                  font15St
                  regularSt
                  boldSt={tab === item}
                  greenSt={tab === item}
                >
                  <Var>{getCurrency(item.value)}</Var>
                </Text>
              </View>
            </View>
          );
        })}
      </View>
    </View>
  );
};
