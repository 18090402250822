import React, { useState } from "react";

import {
  UiWrapper,
  NavigationWrapper,
  View,
  Text,
  PickerHOC,
  Layout,
  Enfatize,
  Var,
} from "coinscrap-webapp-core";

import moment from "moment";
// import Animated from "animated/lib/targets/react-dom";

import { pocketMovements } from "../data/categories/categories";

import SlideItem from "../common/slider/SlideItem";
import colors from "../../config/colors";
import routes from "../../config/routes";
import { AddButton } from "../common/AddButton";
import { useSave } from "../../libs/hooks/useSave";

// import routes from "../config/routes";
export default PickerHOC()(() => {
  const { useBackground } = UiWrapper.use();
  const { navigateTo, useBackRoute } = NavigationWrapper.use();

  // eslint-disable-next-line no-unused-vars
  const [cardSelectedIndex, setCardSelectedIndex] = useState(0);
  // const [cardPosition, setCardPosition] = useState(new Animated.Value(0));
  // eslint-disable-next-line no-unused-vars
  const [localStorage, setLocalStorage] = useState(
    JSON.parse(global.localStorage.getItem("pocketData"))
  );

  const [myData, saveMyData] = useSave();

  // const userTargetParticipations = [
  //   {
  //     state: 1,
  //     id: 1,
  //     name: "Casa de la playa",
  //     price: 2113.44,
  //     img: CasaPlaya,
  //   },
  //   {
  //     id: 2,
  //     state: 1,
  //     name: "Comunión Beatriz",
  //     price: 0,
  //   },
  // ];

  const userTargetParticipations = (myData?.pockets || []).map(
    (pocket, index) => {
      let price = 0;
      pocket.categories.forEach((item) => {
        let movementValue = 0;
        (item.movements || []).forEach((item2) => {
          movementValue = movementValue + item2.value;
        });

        price = price + movementValue;
      });
      // pocket.categories.reduce(
      //   (accumulator, currentValue) => {
      //     return accumulator + Math.abs(currentValue.value);
      //   },
      //   0
      // );

      const array = [];
      [...Array(3)].reduce((acc, curr) => {
        const object = {
          year: Number(moment().subtract(acc, "months").format("YYYY")),
          month: Number(moment().subtract(acc, "months").format("M")),
        };
        array.push(object);
        return acc + 1;
      }, 0);

      const monthsArray = array.reverse();

      const formatLast3Months = monthsArray.map((item) => {
        let price = 0;
        let expenseArray = [];
        pocket.categories.forEach((category) => {
          let movementValue = 0;
          (category.movements || [])
            .filter((filterItem) => {
              return (
                moment(filterItem.last).format("YYYY M") ===
                `${item.year} ${item.month}`
              );
            })
            .forEach((item2) => {
              movementValue = movementValue + item2.value;
            });

          price = price + movementValue;
          expenseArray.push(category);
        });

        return {
          date: moment(item.month, "M"),
          value: Math.abs(price),
        };
      });

      return {
        img: pocket.img,
        state: 1,
        id: pocket.id,
        name: pocket.name,
        price: formatLast3Months[2].value,
        imgColor: pocket.imgColor,
      };
    }
  );
  useBackground(colors.white);

  useBackRoute(undefined);

  return (
    <Layout.Container>
      <View whiteGradientSt fullWidthSt fullHeightSt startSt>
        <View gruposHeaderSt endSt>
          <Text titleHeaderSt>
            Mis <Enfatize black2St>Pockets</Enfatize>
          </Text>
        </View>

        <View fullWidthSt style={{ paddingBottom: 100 }}>
          {userTargetParticipations.map((tp, cardIndex) => {
            const grayScale =
              "grayscale(" + (cardSelectedIndex === cardIndex ? 0 : 100) + "%)";
            console.log({ tp });
            return (
              <CardTarget
                targetParticipation={tp}
                navigateTo={navigateTo}
                showIsPending
                filter={grayScale}
                img={tp.img}
                imgColor={tp.imgColor}
                onClick={() => {
                  const data = {
                    selectedPocket: tp,
                  };
                  const mergedData = { ...localStorage, ...data };
                  global.localStorage.setItem(
                    "pocketData",
                    JSON.stringify(mergedData)
                  );

                  navigateTo(routes.details, {
                    queryParams: { name: tp.name, pocketId: tp.id },
                  });
                }}
              />
            );
          })}
        </View>
      </View>

      <AddButton
        onClick={() => {
          if (userTargetParticipations.length === 0) {
            navigateTo(routes.createPocketIntro);
          } else {
            navigateTo(routes.selectPocket);
          }
        }}
      />
    </Layout.Container>
  );
});

export function CardTarget({
  targetParticipation,
  showIsPending,
  filter,
  opacity,
  img,
  imgColor,
  onClick,
}) {
  console.log({ img });
  const target = targetParticipation;

  return (
    <SlideItem
      image={img}
      imgColor={imgColor}
      title={target.name}
      objective={target.price}
      people={target.people}
      state={targetParticipation.status}
      showIsPending={showIsPending}
      onClick={onClick}
      filter={filter}
      opacity={opacity}
    />
  );
}
