import { UiWrapper } from "coinscrap-webapp-core";
import colors from "./../colors";

UiWrapper.defineComponent("inputSwitch", {
  styles: {
    default: {
      containerComponent: {
        // border: "1px solid #f60",
      },
      switchContainerComponent: {
        // border: "1px solid #f60",
      },

      switchComponent: {
        border: "0px solid #6f0",
        padding: 0,
        marginTop: 9,
      },

      thumbComponent: {
        // border: "1px solid #6f0",
      },

      trackComponent: {
        // border: "1px solid #6f0",
        height: 24,
        margin: "-5px 0 0 -10px",
        width: 50,
        backgroundColor: "#f0f0f0",
      },

      checkedMode: {
        containerComponent: {
          border: "0px solid #fff",
          //padding: 0,
          // margin: "8px 0 0 0",
        },
        thumbComponent: {
          backgroundColor: colors.yellow,
        },
        trackComponent: {
          border: "0px solid #0f6",
        },
      },
    },

    smallCircleSt: {
      labelComponent: {},
      containerComponent: {
        padding: 0,
        margin: 0,
        width: 40,
        marginBottom: 8,
      },
      switchContainerComponent: {
        width: 100,
        position: "relative",
      },
      switchComponent: {
        // top: '-50%',
        left: 6,
        top: 4.5,
      },
      thumbComponent: {
        width: 16,
        height: 16,
        backgroundColor: "white",
        // transform: ' translateX(8px)',
      },
      trackComponent: {
        height: 24,

        marginTop: -2,
        // borderRadius: 26 / 2,
        borderRadius: 14.4,
        width: "40px",
        padding: 0,
        backgroundColor: colors.grey2,
      },
      checkedMode: {
        switchComponent: {
          left: 2,
        },
        trackComponent: {
          backgroundColor: "#86E570",
          opacity: 1,
        },
        thumbComponent: {
          backgroundColor: colors.white,
        },
      },
    },
  },
});
