import React, { useEffect, useState } from "react";

import {
  UiWrapper,
  NavigationWrapper,
  View,
  Text,
  PickerHOC,
  Layout,
  Enfatize,
  Page,
  Var,
} from "coinscrap-webapp-core";

import Footer from "../common/Footer";
import ButtonFooterDouble from "../common/ButtonFooterDouble";
import Steps from "../common/Steps";
import colors from "../../config/colors";
import routes from "../../config/routes";

import { CheckOption } from "../common/CheckOption";
import LoadingMovements from "./components/LoadingMovements";

import { getCategory } from "../data/categories/categories";
import { useSave } from "../../libs/hooks/useSave";

export default PickerHOC()(() => {
  const {
    query: { category, name },
  } = Page.use();
  const [myData, saveMyData] = useSave();

  const [data, setData] = useState([]);
  const [tab, setTab] = useState("owner");
  const [expenses, setExpenses] = useState([]);
  const [expensesOwner, setExpensesOwner] = useState([]);
  const [expensesRental, setExpensesRental] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [localStorage, setLocalStorage] = useState(
    JSON.parse(global.localStorage.getItem("pocketData"))
  );

  const { useBackground, useFooter } = UiWrapper.use();
  const { navigateTo, useBackRoute } = NavigationWrapper.use();

  useEffect(() => {
    setData({ name });
  }, [name]);

  useBackground(colors.white);

  useBackRoute(routes.createPocketName);

  useFooter(
    <Footer>
      <ButtonFooterDouble
        icon1
        disabled2={
          expensesOwner.length > 0 || expensesRental.length > 0 ? false : true
        }
        // Pasar datos y añadir los expenses
        action2={async () => {
          await saveMyData({
            ...myData,
            creation: {
              ...myData.creation,
              categories:
                tab === "owner"
                  ? expensesOwner.map((item) => {
                      return {
                        name: item.name,
                        movements: [],
                      };
                    })
                  : expensesRental.map((item) => {
                      return {
                        name: item.name,
                        movements: [],
                      };
                    }),
            },
          });
          // const route = expenses[0].route;
          tab === "owner"
            ? await setExpenses(expensesOwner)
            : await setExpenses(expensesRental);
          setVisibleModal(true);
        }}
      />
    </Footer>,
    [data, expenses, expensesOwner, expensesRental]
  );

  let categoryObject = getCategory(category);

  useEffect(() => {
    if (categoryObject) {
      const data = {
        category,
        subcategories: tab === "owner" ? expensesOwner : expensesRental,
      };
      const mergedData = { ...localStorage, ...data };
      global.localStorage.setItem("pocketData", JSON.stringify(mergedData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    expensesOwner,
    expensesRental,
    categoryObject,
    category,
    localStorage,
    tab,
  ]);

  console.log({ expensesOwner });

  return !categoryObject ? null : (
    <Layout.Container>
      <View horizontal24St topSt>
        <Steps step={3} total={4} />
        <View topSt style={{ marginBottom: 10 }}>
          <Text font28St style={{ margin: "10px 0px" }}>
            ¿Qué gastos quieres incluir en{" "}
            {data.name && (
              <Enfatize blackLightSt>
                <Var>"{data.name}"</Var>
              </Enfatize>
            )}
            ?
          </Text>
          <Text font16St>
            Marca todos los que quieras, después podrás modificarlos.
          </Text>
        </View>
      </View>
      {categoryObject.title === "Mi casa" ? (
        <>
          <View fullWidthSt style={{ padding: "0 16px" }}>
            <View fullWidthSt horizontalSt tabWhiteSt>
              <Text
                font14St
                boldSt
                notActivebuttonSt
                activebuttonSt={tab === "owner"}
                onClick={() => setTab("owner")}
              >
                Soy propietario
              </Text>
              <Text
                font14St
                boldSt
                notActivebuttonSt
                activebuttonSt={tab === "rental"}
                onClick={() => setTab("rental")}
              >
                Estoy de alquiler
              </Text>
            </View>
          </View>
          <View
            flexSt
            startSt
            fullWidthSt
            grayBc2St
            borderTop2St
            style={{ padding: "15px 16px 19px" }}
          >
            {tab === "owner"
              ? categoryObject.categories.map((item, index) => {
                  if (item.owner)
                    return (
                      <CheckOption
                        item={item}
                        index={index}
                        expenses={expensesOwner}
                        setExpenses={setExpensesOwner}
                      />
                    );
                  else return null;
                })
              : categoryObject.categories.map((item, index) => {
                  if (item.rental)
                    return (
                      <CheckOption
                        item={item}
                        index={index}
                        expenses={expensesRental}
                        setExpenses={setExpensesRental}
                      />
                    );
                  else return null;
                })}
          </View>
        </>
      ) : (
        <View
          flexSt
          startSt
          fullWidthSt
          grayBc2St
          borderTop2St
          style={{ padding: "15px 16px 19px" }}
        >
          {categoryObject.categories.map((item, index) => {
            return (
              <CheckOption
                item={item}
                index={index}
                expenses={expensesOwner}
                setExpenses={setExpensesOwner}
              />
            );
          })}
        </View>
      )}

      <LoadingMovements
        visibleModal={visibleModal}
        setVisibleModal={setVisibleModal}
        action={() => navigateTo(routes.subcategories)}
      />
    </Layout.Container>
  );
});
