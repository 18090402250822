/* eslint-disable react/jsx-fragments */
/* eslint-disable no-console */
import React, { Component } from "react";

import config from "./config";

import Home from "./views/Home";
import Pockets from "./views/Pockets/Pockets";
import Details from "./views/Pockets/Details";
import Settings from "./views/Pockets/Settings";
import EditName from "./views/Settings/EditName";
import EditExpenses from "./views/Settings/EditExpenses";
import CreateTarget from "./views/Pockets/CreateTarget";
import ExpenseDetails from "./views/Pockets/ExpenseDetails";
import ExpenseInfo from "./views/Pockets/ExpenseInfo";

// CREATE POCKET
import CreatePocketIntro from "./views/CreatePocket/Intro";
import CreatePocketSelect from "./views/CreatePocket/SelectPocket";
import CreatePocketName from "./views/CreatePocket/CreateName";
import createPocketFinishedAuto from "./views/CreatePocket/FinishedAuto";
import CreatePocketExpenses from "./views/CreatePocket/CreateExpenses";
import CreateMortgage from "./views/CreatePocket/CreateMortgage";
import CreateTaxes from "./views/CreatePocket/CreateTaxes";
import CreateCommunity from "./views/CreatePocket/CreateCommunity";
import CreateSubscription from "./views/CreatePocket/CreateSubscription";
import CreateOthers from "./views/CreatePocket/CreateOthers";
import CreateFood from "./views/CreatePocket/CreateFood";
import CreatePocketFinished from "./views/CreatePocket/Finished";

import ManageSubcategories from "./views/CreatePocket/ManageSubcategories";

// test notifications page
import Notifications from "./views/testNotification/Notification";
import { LoaderProvider } from "./views/common/loader/LoaderWrapperContext";

import {
  ApplicationWrapper,
  NavigationWrapper,
  BackendWrapper,
  Page,
  Module,
  UiWrapper,
  InternationalizationWrapper,
} from "coinscrap-webapp-core";
import routes from "./config/routes";
import BasicLayout from "./views/common/BasicLayout";
import all from "./config/lang/all.json";

import "chartjs-plugin-labels";

let lang;
ApplicationWrapper.init({
  projectName: "pockets",
  contextTheme: "light",
  onStartupParams: ({ language: receivedLang }) => (lang = receivedLang),
});
const pushAnimation = {
  exit: {
    opacity: 0,
    x: "100%",
  },
  initial: {
    opacity: 0,
    x: 300,
  },
  animate: {
    opacity: 1,
    x: 0,
  },
  transition: {
    duration: 0.4,
  },
};

const backAnimation = {
  exit: {
    opacity: 0,
    x: "100%",
  },
  initial: {
    opacity: 0,
    x: -300,
  },
  animate: {
    opacity: 1,
    x: 0,
  },
  transition: {
    duration: 0.4,
  },
};

export default class App extends Component {
  render() {
    return (
      <React.Fragment>
        <ApplicationWrapper>
          <InternationalizationWrapper
            languageResources={all}
            defaultLang={lang || "es"}
          >
            <BackendWrapper enableNativeBridge>
              <UiWrapper config={config}>
                <NavigationWrapper>
                  <LoaderProvider>
                    <BasicLayout>
                      <Page path="/" name={"/"} component={Home} />
                      <Page
                        path="/notifications"
                        name={"/notifications"}
                        component={Notifications}
                      />
                      <Module path="/home">
                        <Page
                          name={routes.home}
                          path="/"
                          pushAnimation={pushAnimation}
                          backAnimation={backAnimation}
                          component={Pockets}
                        />
                      </Module>
                      <Module path="/details">
                        <Page
                          name={routes.details}
                          path="/"
                          pushAnimation={pushAnimation}
                          backAnimation={backAnimation}
                          component={Details}
                        />
                        <Page
                          name={routes.settings}
                          pushAnimation={pushAnimation}
                          backAnimation={backAnimation}
                          component={Settings}
                        />
                        <Page
                          name={routes.editName}
                          pushAnimation={pushAnimation}
                          backAnimation={backAnimation}
                          component={EditName}
                        />
                        <Page
                          name={routes.editExpenses}
                          pushAnimation={pushAnimation}
                          backAnimation={backAnimation}
                          component={EditExpenses}
                        />
                        <Page
                          name={routes.createTarget}
                          pushAnimation={pushAnimation}
                          backAnimation={backAnimation}
                          component={CreateTarget}
                        />
                        <Page
                          name={routes.expenseDetails}
                          pushAnimation={pushAnimation}
                          backAnimation={backAnimation}
                          component={ExpenseDetails}
                        />
                        <Page
                          name={routes.expenseInfo}
                          pushAnimation={pushAnimation}
                          backAnimation={backAnimation}
                          component={ExpenseInfo}
                        />
                      </Module>
                      <Module path="/createPocket">
                        <Page
                          name={routes.createPocketIntro}
                          pushAnimation={pushAnimation}
                          backAnimation={backAnimation}
                          component={CreatePocketIntro}
                        />
                        <Page
                          name={routes.selectPocket}
                          pushAnimation={pushAnimation}
                          backAnimation={backAnimation}
                          component={CreatePocketSelect}
                        />
                        <Page
                          name={routes.createPocketName}
                          pushAnimation={pushAnimation}
                          backAnimation={backAnimation}
                          component={CreatePocketName}
                        />
                        <Page
                          name={routes.createPocketFinishedAuto}
                          pushAnimation={pushAnimation}
                          backAnimation={backAnimation}
                          component={createPocketFinishedAuto}
                        />
                        <Page
                          name={routes.createPocketExpenses}
                          pushAnimation={pushAnimation}
                          backAnimation={backAnimation}
                          component={CreatePocketExpenses}
                        />
                        <Page
                          name={routes.createPocketFinished}
                          pushAnimation={pushAnimation}
                          backAnimation={backAnimation}
                          component={CreatePocketFinished}
                        />
                        <Module path="/expenses">
                          <Page
                            name={routes.subcategories}
                            pushAnimation={pushAnimation}
                            backAnimation={backAnimation}
                            component={ManageSubcategories}
                          />
                          <Page
                            name={routes.expenseMortgage}
                            pushAnimation={pushAnimation}
                            backAnimation={backAnimation}
                            component={CreateMortgage}
                          />
                          <Page
                            name={routes.expenseTaxes}
                            pushAnimation={pushAnimation}
                            backAnimation={backAnimation}
                            component={CreateTaxes}
                          />
                          <Page
                            name={routes.expenseCommunity}
                            pushAnimation={pushAnimation}
                            backAnimation={backAnimation}
                            component={CreateCommunity}
                          />
                          <Page
                            name={routes.expenseSubscription}
                            pushAnimation={pushAnimation}
                            backAnimation={backAnimation}
                            component={CreateSubscription}
                          />
                          <Page
                            name={routes.expenseOthers}
                            pushAnimation={pushAnimation}
                            backAnimation={backAnimation}
                            component={CreateOthers}
                          />
                          <Page
                            name={routes.expenseFood}
                            pushAnimation={pushAnimation}
                            backAnimation={backAnimation}
                            component={CreateFood}
                          />
                        </Module>
                      </Module>
                    </BasicLayout>
                  </LoaderProvider>
                </NavigationWrapper>
              </UiWrapper>
            </BackendWrapper>
          </InternationalizationWrapper>
        </ApplicationWrapper>
      </React.Fragment>
    );
  }
}
