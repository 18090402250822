import React from "react";

import {
  UiWrapper,
  NavigationWrapper,
  View,
  Text,
  PickerHOC,
  Layout,
  Enfatize,
} from "coinscrap-webapp-core";

import ButtonFooter from "../common/ButtonFooter";
import LogoSantander from "../common/svgs/LogoSantander";

import colors from "../../config/colors";
import routes from "../../config/routes";
import CreatePocketBackground from "../../assets/images/bg_createPocket.png";

// import routes from "../config/routes";
export default PickerHOC()(() => {
  const { useBackground } = UiWrapper.use();
  const { navigateTo } = NavigationWrapper.use();

  useBackground(colors.white);

  // useFooter(
  //   <Footer noBorder>
  //     <View width80St>
  //       <ButtonFooter
  //         text="Crear pocket"
  //         action={() => navigateTo(routes.createPocketName)}
  //       />
  //     </View>
  //   </Footer>
  // );
  return (
    <Layout.Container>
      <View
        spaceBetweenSt
        style={{
          height: "100%",
          backgroundColor: colors.white,
          // backgroundImage: 'url(/images/WelcomeImage.png)',
          backgroundImage: `linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.2) ),
        url(${CreatePocketBackground})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <View flexSt centerSt>
          <View width80St topSt style={{ margin: "0px 0px 23px" }}>
            <LogoSantander style={{ color: "white" }} />
            <Text whiteSt font32St style={{ margin: "10px 0px" }}>
              Lleva tus cuentas de gastos a otro nivel con{" "}
              <Enfatize whiteSt>Pockets</Enfatize>
            </Text>
            <Text whiteSt>
              Crea un pocket para{" "}
              <Enfatize whiteSt boldSt>
                conocer mejor todos tus gastos{" "}
              </Enfatize>
              de transporte, uno para tu casa o para la segunda residencia, tu
              hobbies o lo que quieras.
            </Text>
          </View>
          {/* <View width80St centerSt horizontalSt spaceBetweenSt>
            <Sofa style={{ marginBottom: -26 }} />
            <Travel style={{ marginLeft: 20 }} />
            <Coffee />
          </View> */}
        </View>
        <View width80St style={{ marginBottom: 24 }}>
          <ButtonFooter
            fill
            text="Crear pocket"
            action={() => navigateTo(routes.selectPocket)}
          />
        </View>
      </View>
    </Layout.Container>
  );
});
