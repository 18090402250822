import { UiWrapper } from "coinscrap-webapp-core";
import colors from "../colors";
import fonts from "../fonts";

UiWrapper.defineComponent("button", {
  styles: {
    default: {
      backgroundColor: colors.white,
      borderWidth: 0,
      borderStyle: "solid",
      fontSize: 18,
      margin: 10,
      borderRadius: 24,
      // paddingLeft: 35,
      // paddingRight: 35,
      maxWidth: 350,
      width: "100%",
      textComponent: {
        color: colors.white,
        // marginBottom: 2,
        fontSize: 16,
        fontFamily: fonts.regular,
        fontWeight: "bold",
      },
    },

    // Pildora hueca con borde rojo
    pillSt: {
      borderWidth: 1,
      borderColor: colors.green,
      margin: 0,
      padding: 0,
      backgroundColor: "transparent",
      height: 40,
      textComponent: {
        color: colors.green,
        fontSize: 16,
        lineHeight: "23px",
        fontWeight: 400,
        fontFamily: fonts.SantanderTextRegular,
      },
    },

    pillFillSt: {
      borderColor: colors.green,
      borderStyle: "solid",
      borderRadius: 20,
      borderWidth: 1,
      margin: 0,
      padding: 0,
      backgroundColor: colors.green,
      height: 40,

      textComponent: {
        color: colors.white,
        padding: 0,
        fontWeight: 700,
        fontSize: 16,
        fontFamily: fonts.SantanderTextRegular,
      },
    },

    backButtonSt: {
      // width: "45%",
      height: 40,
      borderRadius: 24,
      alignSelf: "flex-end",
      fontSize: 14,
      borderWidth: 0,
      backgroundColor: "transparent",
      flexDirection: "row",
      padding: 0,
      margin: "16px 0 16px 24px",
      textComponent: {
        display: "flex",
        flexDirection: "row",
        verticalAlign: "center",
      },
    },

    nextButtonSt: {
      // width: "45%",
      maxWidth: 160,
      height: 40,
      borderRadius: 24,
      alignSelf: "flex-end",
      fontSize: 16,
      margin: "16px 24px 16px 0",
    },

    // Para cuando se deshabilita
    disabledSt: {
      backgroundColor: "white",
      borderColor: "silver",

      textComponent: {
        color: colors.grey,
      },
    },

    autoWidthSt: {
      width: "auto",
      margin: 0,
    },
  },
});
