import React from 'react';

export default function AddWhite(props) {
  return (
    <svg
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ ...props.style }}
    >
      <path
        d='M14.3044 2.43457V26.1737'
        stroke='white'
        stroke-width='2'
        stroke-linecap='square'
      />
      <path
        d='M14.3044 2.43457V26.1737'
        stroke='white'
        stroke-width='2'
        stroke-linecap='square'
      />
      <path
        d='M2.43481 14.3045H26.1739'
        stroke='white'
        stroke-width='2'
        stroke-linecap='square'
      />
      <path
        d='M2.43481 14.3045H26.1739'
        stroke='white'
        stroke-width='2'
        stroke-linecap='square'
      />
    </svg>
  );
}
