import React, { useEffect, useState } from "react";

import {
  UiWrapper,
  NavigationWrapper,
  View,
  Text,
  PickerHOC,
  Layout,
} from "coinscrap-webapp-core";

import Footer from "../common/Footer";
import ButtonFooterDouble from "../common/ButtonFooterDouble";
import Steps from "../common/Steps";
import colors from "../../config/colors";
import routes from "../../config/routes";
import HouseIcon from "../common/svgs/HouseIcon.js";
import { ExpensesCheck } from "../common/ExpensesCheck";
import CreateExpense from "./components/CreateExpense";

export default PickerHOC()(() => {
  const [expenses, setExpenses] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);

  const { useBackground, useFooter } = UiWrapper.use();
  const { navigateTo, useBackRoute, query } = NavigationWrapper.use();

  const mortgageList = [
    {
      name: "Liquidación Periódica Préstamo",
      last: "12 Jul 2020",
      value: -1036.37,
      account: "0049 1770 103 0008214",
    },
    {
      name: "Liquidación Periódica Préstamo",
      last: "26 Jul 2020",
      value: -426.25,
      account: "0049 5770 103 0004606",
    },
    {
      name: "Liquidación Periódica Préstamo",
      last: "30 May 2020",
      value: -412.29,
      account: "0049 5770 103 0004606",
    },
  ];

  useEffect(() => {
    setExpenses(query);
  }, [query]);

  useBackRoute(routes.createPocketExpenses);

  useBackground(colors.white);

  useFooter(
    <Footer>
      <ButtonFooterDouble
        icon1
        // Pasar datos y añadir los expenses
        action2={() => navigateTo(routes.expenseTaxes)}
      />
    </Footer>,
    [expenses]
  );
  return (
    <Layout.Container>
      <View horizontal24St topSt>
        <Steps step={4} total={4} />
        <View width95St topSt style={{ marginBottom: 10 }}>
          <HouseIcon />
          <Text font28St style={{ margin: "10px 0px" }}>
            Hipotecas y préstamos
          </Text>
          <Text font16St>
            Hemos encontrado estos productos. Si lo necesitas puedes buscarlos
            manualmente o añadir los que quieras.
          </Text>
        </View>
      </View>
      <View
        flexSt
        startSt
        fullWidthSt
        fullHeightSt
        grayBc2St
        borderTop2St
        style={{ padding: "15px 16px 19px" }}
      >
        {mortgageList.map((item, index) => {
          return (
            <ExpensesCheck
              item={item}
              index={index}
              data={expenses}
              setData={setExpenses}
            />
          );
        })}

        <CreateExpense
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
        />
      </View>
    </Layout.Container>
  );
});
