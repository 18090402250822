/*
Archivo de configuración donde se asocia una acción [key] con el nombre [valor]
Los valores que asignemos simplemente son nombres cortos de las pantallas para que la app las use internamente,
Las navegaciones no se realizan en base a paths completos si no a nombres cortos
*/

const routes = {
  home: "home",
  createPocketIntro: "intro",
  selectPocket: "select",
  details: "details",
  settings: "settings",
  editName: "editName",
  editExpenses: "editExpenses",
  createTarget: "createTarget",
  expenseDetails: "expense",
  expenseInfo: "expenseInfo",
  createPocketName: "createName",
  createPocketFinishedAuto: "finishedAuto",
  createPocketExpenses: "createExpenses",
  expenseMortgage: "mortgage",
  expenseTaxes: "taxes",
  expenseCommunity: "community",
  expenseSubscription: "subscription",
  expenseOthers: "others",
  expenseFood: "food",
  createPocketFinished: "finished",
  subcategories: "subcategories",
};

export default routes;
