import React from "react";

export default function SVGRestaurantIcon(props) {
  return (
    <svg
      width="26"
      height="33"
      viewBox="0 0 26 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...props.style }}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.04925 32.5C5.60151 32.5 4.42812 31.3222 4.42812 29.8689V12.4523C2.37042 12.0691 0.8125 10.2572 0.8125 8.0793V0.816091C0.8125 0.365376 1.17627 0 1.625 0C2.07373 0 2.4375 0.365376 2.4375 0.816091V8.0793C2.4375 9.63454 3.69223 10.8948 5.24062 10.8948C5.68936 10.8948 6.05312 11.2602 6.05312 11.7109V29.8689C6.05312 30.4205 6.49874 30.8678 7.04925 30.8678C7.59827 30.8678 8.04375 30.4204 8.04375 29.8689V11.7109C8.04375 11.2602 8.40752 10.8948 8.85625 10.8948C10.4046 10.8948 11.6594 9.63454 11.6594 8.0793V0.816091C11.6594 0.365376 12.0231 0 12.4719 0C12.9206 0 13.2844 0.365376 13.2844 0.816091V8.0793C13.2844 10.2572 11.7265 12.0691 9.66875 12.4523V29.8689C9.66875 31.3218 8.49573 32.5 7.04925 32.5ZM4.4278 8.0793V0.816091C4.4278 0.365376 4.79157 0 5.2403 0C5.68903 0 6.0528 0.365376 6.0528 0.816091V8.0793C6.0528 8.53001 5.68903 8.89539 5.2403 8.89539C4.79157 8.89539 4.4278 8.53001 4.4278 8.0793ZM8.04359 8.0793V0.816091C8.04359 0.365376 8.40736 0 8.85609 0C9.30482 0 9.66859 0.365376 9.66859 0.816091V8.0793C9.66859 8.53001 9.30482 8.89539 8.85609 8.89539C8.40736 8.89539 8.04359 8.53001 8.04359 8.0793ZM25.9378 18.9741C25.9378 19.4248 25.574 19.7902 25.1253 19.7902H22.3238V29.8689C22.3238 31.3222 21.1504 32.5 19.7026 32.5C18.2562 32.5 17.0831 31.3218 17.0831 29.8689V0.816091C17.0831 0.365376 17.4469 0 17.8956 0H19.7026C23.1462 0 25.9378 2.80386 25.9378 6.26268V18.9741ZM20.6988 18.9741C20.6988 18.5234 21.0625 18.158 21.5113 18.158H24.3128V6.26268C24.3128 3.70528 22.2488 1.63218 19.7026 1.63218H18.7081V29.8689C18.7081 30.4204 19.1536 30.8678 19.7026 30.8678C20.2531 30.8678 20.6988 30.4205 20.6988 29.8689V18.9741Z"
        fill={props.color || "#DD5858"}
      />
    </svg>
  );
}
