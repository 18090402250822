import React, { useEffect, useState } from "react";
import { getPockets, updatePockets } from "../../actions";
import { useLoader } from "../../views/common/loader/LoaderWrapperContext";

export const useSave = () => {
  const [savedData, setSavedData] = useState(
    JSON.parse(global.localStorage.getItem("customPhillipSavedData") || "{}")
  );
  // const { loading, setLoading } = useLoader();

  const saveData = async (data) => {
    await new Promise((res) => {
      setTimeout(res, 200);
    });

    setSavedData((oldData) => {
      // usage of lodash.merge(oldData, data)
      return {
        ...oldData,
        ...data,
      };
    });
  };

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       setLoading(true);
  //       // setSavedData({ ...savedData, loading: true });
  //       await getPockets();
  //       // setSavedData({ ...res, loading: false });
  //       setLoading(false);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   })();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    localStorage.setItem("customPhillipSavedData", JSON.stringify(savedData));
    updatePockets(savedData);
  }, [savedData]);

  return [savedData, saveData];
};
