import React from "react";

import { View } from "coinscrap-webapp-core";
export default ({ children, value }) => {
  return (
    <View fullWidthSt topSt progressBarSt style={{ margin: "6px 0" }}>
      <View
        progressBarSt
        greenBcSt
        style={{
          width: value + "%",
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }}
      />
    </View>
  );
};
