import React from "react";

export default function Arrow(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...props.style }}
    >
      <g id="Group 7 Copy">
        <rect
          id="icon-box"
          y="24"
          width="24"
          height="24"
          transform="rotate(-90 0 24)"
          fill="white"
          fill-opacity="0.01"
        />
        <path
          id="icon"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.11591 6.81581C8.93921 6.60373 8.96774 6.28844 9.17964 6.11159C9.39154 5.93474 9.70656 5.96329 9.88326 6.17538L14.8841 12.1776C15.0386 12.363 15.0386 12.6325 14.8841 12.818L9.88326 18.8202C9.70656 19.0323 9.39154 19.0608 9.17964 18.884C8.96774 18.7071 8.93921 18.3919 9.11591 18.1798L13.85 12.4978L9.11591 6.81581Z"
          fill="#444444"
        />
      </g>
    </svg>
  );
}
