import React from "react";

export default function SVGKeyIcon(props) {
  return (
    <svg
      width="28"
      height="27"
      viewBox="0 0 28 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...props.style }}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.5527 7.39792L23.6001 3.49849L25.3932 1.70057C25.6864 1.40655 25.6864 0.929504 25.3917 0.63622C25.0977 0.343672 24.6214 0.343672 24.3273 0.63769L9.51025 15.4947C8.55359 14.8921 7.44762 14.5595 6.28487 14.5595C6.2709 14.5595 6.25694 14.5595 6.24297 14.5602C4.61778 14.5705 3.09844 15.21 1.96426 16.3596C-0.38495 18.7412 -0.358488 22.5906 2.02306 24.9398C3.16239 26.0637 4.67291 26.6819 6.28193 26.6819C6.29589 26.6819 6.31059 26.6819 6.32456 26.6811C7.94901 26.6709 9.46836 26.0314 10.6025 24.8817C12.9036 22.5497 12.9108 18.8197 10.6747 16.4588L19.0898 8.02096L23.0733 11.9515C23.2196 12.0963 23.4107 12.1684 23.6018 12.1684C23.7959 12.1684 23.9907 12.0934 24.1377 11.9442C24.4295 11.6487 24.4266 11.1716 24.1303 10.8798L20.1527 6.95514L22.5371 4.56431L26.4957 8.46962C26.642 8.61442 26.8331 8.68646 27.0242 8.68646C27.2183 8.68646 27.4131 8.61148 27.5601 8.46227C27.8519 8.16678 27.8489 7.68973 27.5527 7.39792ZM9.53083 23.8247C8.67892 24.6877 7.53665 25.1677 6.31427 25.1765C5.12202 25.2169 3.94301 24.7208 3.07933 23.8681C1.28949 22.1018 1.26964 19.2072 3.03596 17.4166C3.88788 16.5537 5.03014 16.0737 6.25326 16.0649C6.26355 16.0649 6.27384 16.0649 6.28487 16.0649C7.49549 16.0649 8.63187 16.5287 9.48747 17.3732C10.3504 18.2252 10.8304 19.3674 10.8392 20.5898C10.8473 21.8122 10.3828 22.9611 9.53083 23.8247Z"
        fill={props.color || "#DD5858"}
      />
    </svg>
  );
}
