import React from 'react';

export default function Check(props) {
  return (
    <svg
      width='15'
      height='14'
      viewBox='0 0 15 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ ...props.style }}
    >
      <path d='M1 8.33333L5.33333 12L14 1' stroke='white' stroke-width='2' />
    </svg>
  );
}
