import colors from "../colors";
import { UiWrapper } from "coinscrap-webapp-core";

UiWrapper.defineComponent("tooltip", {
  styles: {
    default: {
      containerComponent: {
        backgroundColor: "#ffffff",
      },
    },
  },
});
