// import colors from "../colors";
// import commonStyles from "./common";

import { UiWrapper } from "coinscrap-webapp-core";

UiWrapper.defineComponent("layout", {
  styles: {
    default: {
      bodyComponent: {
        backgroundColor: "white",
        justifyContent: "flex-start",
      },
      bodyContentComponent: {
        overflowX: "hidden",
        justifyContent: "flex-start",
        // flexShrink: 1,
      },
      footerComponent: {
        height: 75,
      },
      modalComponent: {
        backgroundComponent: {
          backgroundColor: "rgba(128,128,128,0.7)",
        },
        contentComponent: {
          background: "none",
          boxShadow: "none",
        },
      },
    },
  },
});
