import React, { useState } from "react";

import {
  UiWrapper,
  NavigationWrapper,
  View,
  Text,
  PickerHOC,
  Layout,
  Enfatize,
} from "coinscrap-webapp-core";

import Footer from "../common/Footer";
import ButtonFooterDouble from "../common/ButtonFooterDouble";
import Steps from "../common/Steps";
import colors from "../../config/colors";
import routes from "../../config/routes";

import { ExpensesCheck } from "../common/ExpensesCheck";
import CreateExpense from "./components/CreateExpense";
import {
  mockDataCasa,
  mockDataTransporte,
  mockDataEstudios,
  mockDataOcio,
  getCategoryIcon,
} from "../data/categories/categories";
import { useSave } from "../../libs/hooks/useSave";

export default PickerHOC()(() => {
  // eslint-disable-next-line no-unused-vars
  const [localStorage, setLocalStorage] = useState(
    JSON.parse(global.localStorage.getItem("pocketData"))
  );
  const [myData, saveMyData] = useSave();

  const [subcategoryIndex, setSubcategoryIndex] = useState(0);
  const [expenses, setExpenses] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);

  const { useBackground, useFooter } = UiWrapper.use();
  const { navigateTo, useBackRoute, goBack } = NavigationWrapper.use();

  useBackRoute(routes.createPocketExpenses);

  useBackground(colors.white);
  let subCategory = localStorage?.subcategories[subcategoryIndex] || null;

  useFooter(
    <Footer>
      <ButtonFooterDouble
        icon1
        // Pasar datos y añadir los expenses
        action1={() => {
          console.log((localStorage?.subcategories).length);
          if (subcategoryIndex === 0) {
            goBack();
          } else {
            setSubcategoryIndex((prevState) => prevState - 1);
          }
        }}
        action2={async () => {
          console.log({ subCategory });
          console.log({ subCategory });
          await saveMyData({
            ...myData,
            creation: {
              ...myData.creation,
              categories: myData.creation.categories.map((item) => {
                if (item.name === subCategory.name) {
                  return {
                    ...item,
                    movements: expenses,
                  };
                } else return item;
              }),
            },
          });
          console.log((localStorage?.subcategories).length);
          if (
            (localStorage?.subcategories || []).length ===
            subcategoryIndex + 1
          ) {
            navigateTo(routes.createPocketFinished);
          } else {
            setExpenses([]);
            setSubcategoryIndex((prevState) => prevState + 1);
          }
        }}
      />
    </Footer>,
    [expenses, localStorage, subcategoryIndex]
  );
  console.log({ subcategoryIndex });
  let mockData =
    localStorage?.category === "miCasa"
      ? mockDataCasa
      : localStorage?.category === "transporte"
      ? mockDataTransporte
      : localStorage?.category === "estudios"
      ? mockDataEstudios
      : localStorage?.category === "ocio"
      ? mockDataOcio
      : null;
  console.log({ mockData });
  const findCategoryInMockData = mockData?.categories.find(
    (mock) => mock.name === subCategory.name
  );
  console.log({ expenses });
  console.log({ subCategory });
  console.log({ test: subCategory?.subcategories });

  let Icon = getCategoryIcon(subCategory.name || "");
  return !subCategory ? null : (
    <Layout.Container>
      <View horizontal24St topSt>
        <Steps step={4} total={4} />
        <View width95St topSt style={{ marginBottom: 10 }}>
          {/* <Image src={subCategory.icon} style={{ height: 28, width: 28 }} /> */}
          {Icon && <Icon.svg />}

          <Text font28St style={{ margin: "10px 0px" }}>
            {subCategory.name}
          </Text>
          <Text font16St>
            {(findCategoryInMockData?.subcategories?.length < 1 ||
              !findCategoryInMockData?.subcategories) &&
            findCategoryInMockData?.movements < 1
              ? "Por ahora no hemos encontrado productos para este concepto, pero si lo necesitas puedes buscarlos manualmente o añadir los que quieras."
              : "Hemos encontrado estos productos. Si lo necesitas puedes buscarlos manualmente o añadir los que quieras."}
          </Text>
        </View>
      </View>
      <View
        flexSt
        startSt
        fullWidthSt
        fullHeightSt
        grayBc2St
        borderTop2St
        style={{ padding: "15px 16px 19px" }}
      >
        {findCategoryInMockData?.subcategories?.length > 0 ? (
          findCategoryInMockData?.subcategories.map((item, index) => {
            let Icon = getCategoryIcon(item.name || "");
            return (
              <>
                <View
                  fullWidthSt
                  topSt
                  startSt
                  horizontalSt
                  style={{ margin: "15px 0px" }}
                >
                  {/* <Image
                    src={item.icon}
                    style={{ height: "auto", width: 28, marginRight: 10 }}
                  /> */}
                  {Icon && <Icon.svg style={{ marginRight: 10 }} />}
                  <View topSt>
                    <Text font18St boldSt>
                      {item.name}
                    </Text>
                  </View>
                </View>
                {findCategoryInMockData.subcategories
                  .find((mock) => mock.name === item.name)
                  .movements.sort(function (a, b) {
                    return new Date(b.last) - new Date(a.last);
                  })
                  .filter(
                    (v, i, a) => a.findIndex((t) => t.value === v.value) === i
                  )
                  .map((movement) => (
                    <ExpensesCheck
                      item={movement}
                      index={index}
                      data={expenses}
                      setData={setExpenses}
                    />
                  ))}
              </>
            );
          })
        ) : findCategoryInMockData.movements.length < 1 ? (
          <View style={{ marginTop: 15, marginBottom: 15 }}>
            <Text style={{ fontSize: 21 }}>
              Estamos pendientes de los gastos que se registren en{" "}
              <Enfatize>{subCategory.name}</Enfatize> para añadirlos{" "}
            </Text>
          </View>
        ) : (
          findCategoryInMockData.movements
            .sort(function (a, b) {
              return new Date(b.last) - new Date(a.last);
            })
            .filter((v, i, a) => a.findIndex((t) => t.value === v.value) === i)
            .map((item, index) => {
              return (
                <ExpensesCheck
                  item={item}
                  index={index}
                  data={expenses}
                  setData={setExpenses}
                />
              );
            })
        )}

        <CreateExpense
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
        />
      </View>
    </Layout.Container>
  );
});
