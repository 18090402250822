import React, { useState } from 'react';
import {
  UiWrapper,
  NavigationWrapper,
  View,
  Text,
  PickerHOC,
  Layout,
  Enfatize,
  InputSwitch,
} from 'coinscrap-webapp-core';

const Notification = () => {
  return (
    <View>
      <NotificationCard
        header="Notificaciones comerciales"
        description="Basado en tus productos y tu situación financiera, recibe avisos personalizados en tu móvil con los productos y servicios que puedan ser de tu interés."
      />
      <NotificationCard
        header="Nuevos productos y campañas"
        description="Recibe ofertas sobre nuestros nuevos productos, funcionalidades, sugerencias y promociones."
      />
      <NotificationCard
        header="Estado de tus productos"
        description="Te avisamos sobre cambios y novedades en tus productos contratados y servicios actuales para que no se te escape nada."
      />
    </View>
  );
};

export default Notification;

const NotificationCard = ({ header, description }) => {
  const [isEnabledNotification, setIsEnabledNotification] = useState(false);

  return (
    <View style={{ borderBottom: '1px solid #DBE0E3', width: '100%' }}>
      <View
        horizontalSt
        spaceBetweenSt
        style={{ padding: '20px 0px', width: '88%' }}
      >
        <View autoWidthSt shrinkSt topSt style={{ marginRight: 10 }}>
          <Text
            style={{
              fontSize: 18,
              fontFamily: 'SantanderTextRegular',
              fontWeight: 700,
              marginBottom: 8,
            }}
          >
            {header}
          </Text>
          <Text style={{ fontSize: 16 }}>{description}</Text>
        </View>
        <View autoWidthSt>
          <InputSwitch
            smallCircleSt
            style={{
              containerComponent: {
                marginLeft: 'auto',
              },
            }}
            // disabled={changing}
            onChange={async () => {
              setIsEnabledNotification((prevState) => !prevState);
            }}
            value={isEnabledNotification}
          />
        </View>
      </View>
    </View>
  );
};
