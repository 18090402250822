import React from "react";

export default function Bin(props) {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...props.style }}
    >
      <g id="Group 9 Copy 2">
        <g id="Combined Shape 3">
          <path
            id="Combined Shape"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.4375 4.44444H16.875V15.5827C16.875 18.0165 14.87 19.9967 12.4052 19.9967H5.59479C3.13 19.9967 1.125 18.0165 1.125 15.5827V4.44444H0.5625C0.251587 4.44444 0 4.19596 0 3.88889C0 3.58181 0.251587 3.33333 0.5625 3.33333H1.67116C1.67394 3.33324 1.67649 3.33247 1.67903 3.33171L1.67903 3.33171C1.68176 3.33089 1.68448 3.33008 1.6875 3.33008C1.69052 3.33008 1.69324 3.33089 1.69597 3.33171L1.69597 3.33171C1.69851 3.33247 1.70106 3.33324 1.70384 3.33333H4.5V2.21951C4.5 0.995551 5.508 0 6.74725 0H11.2527C12.492 0 13.5 0.995551 13.5 2.21951V3.33333H16.2962C16.2989 3.33324 16.3015 3.33247 16.304 3.33171C16.3068 3.33089 16.3095 3.33008 16.3125 3.33008C16.3155 3.33008 16.3182 3.33089 16.321 3.33171C16.3235 3.33247 16.3261 3.33324 16.3288 3.33333H17.4375C17.7484 3.33333 18 3.58181 18 3.88889C18 4.19596 17.7484 4.44444 17.4375 4.44444ZM6.74725 1.11111C6.12817 1.11111 5.625 1.60807 5.625 2.21951V3.33333H12.375V2.21951C12.375 1.60807 11.8718 1.11111 11.2527 1.11111H6.74725ZM15.75 15.5827C15.75 17.404 14.2493 18.8856 12.4052 18.8856H5.59479C3.75073 18.8856 2.25 17.404 2.25 15.5827V4.44444H15.75V15.5827ZM4.5 7.22222C4.5 6.91515 4.75159 6.66667 5.0625 6.66667C5.37341 6.66667 5.625 6.91515 5.625 7.22222V16.1111C5.625 16.4182 5.37341 16.6667 5.0625 16.6667C4.75159 16.6667 4.5 16.4182 4.5 16.1111V7.22222ZM8.4375 7.22222C8.4375 6.91515 8.68909 6.66667 9 6.66667C9.31091 6.66667 9.5625 6.91515 9.5625 7.22222V16.1111C9.5625 16.4182 9.31091 16.6667 9 16.6667C8.68909 16.6667 8.4375 16.4182 8.4375 16.1111V7.22222ZM12.9375 6.66667C12.6266 6.66667 12.375 6.91515 12.375 7.22222V16.1111C12.375 16.4182 12.6266 16.6667 12.9375 16.6667C13.2484 16.6667 13.5 16.4182 13.5 16.1111V7.22222C13.5 6.91515 13.2484 6.66667 12.9375 6.66667Z"
            fill="#047E85"
          />
        </g>
      </g>
    </svg>
  );
}
