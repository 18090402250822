import React from "react";

export default function CartIcon(props) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...props.style }}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 2.73591C0 3.14268 0.329148 3.47182 0.735912 3.47182H3.26273L5.69739 7.52949C5.69747 7.52967 5.69747 7.52994 5.69757 7.53012L5.96958 7.98314L10.463 15.4721C11.1795 16.6658 11.1508 18.1908 10.389 19.3564L9.13493 21.276C7.74252 21.4841 6.66776 22.6768 6.66776 24.1269C6.66776 25.7231 7.96567 27.021 9.5611 27.021C11.1573 27.021 12.4552 25.7231 12.4552 24.1269C12.4552 24.11 12.4504 24.0942 12.4501 24.0773H20.7097C20.7095 24.0942 20.7047 24.11 20.7047 24.1269C20.7047 25.7231 22.0026 27.021 23.5988 27.021C25.1949 27.021 26.4928 25.7231 26.4928 24.1269C26.4928 22.5308 25.1949 21.2329 23.5988 21.2329C22.5623 21.2329 21.6587 21.7846 21.1475 22.6055H12.0124C11.7105 22.1209 11.2736 21.7323 10.7496 21.4947L11.6208 20.1613C12.0806 19.4573 12.3381 18.659 12.4156 17.8456C12.8299 18.0608 13.2902 18.1901 13.7689 18.1901H20.8535C21.8819 18.1901 22.8506 17.6424 23.3817 16.7621L27.8166 9.39361C28.1091 8.73532 28.0487 7.98216 27.657 7.37848C27.2647 6.77552 26.6006 6.41547 25.8812 6.41547H6.74556L4.31054 2.35718C4.17759 2.13583 3.93828 2 3.67956 2H0.735912C0.329148 2 0 2.32915 0 2.73591ZM23.5988 22.7047C24.3828 22.7047 25.021 23.3429 25.021 24.1269C25.021 24.911 24.3828 25.5492 23.5988 25.5492C22.8147 25.5492 22.1765 24.911 22.1765 24.1269C22.1765 23.3429 22.8147 22.7047 23.5988 22.7047ZM9.5611 25.5492C8.77704 25.5492 8.13958 24.911 8.13958 24.1269C8.13958 23.3429 8.77704 22.7047 9.5611 22.7047C10.3452 22.7047 10.9833 23.3429 10.9833 24.1269C10.9833 24.911 10.3452 25.5492 9.5611 25.5492ZM25.8812 7.88729C26.1974 7.88729 26.3656 8.09283 26.4231 8.18123C26.4806 8.26962 26.5999 8.50606 26.5137 8.71447L22.1212 16.0017C21.8553 16.4437 21.3695 16.7182 20.8535 16.7182H13.7689C13.25 16.7182 12.7635 16.4415 12.4968 15.9945L7.62861 7.88729H25.8812Z"
        fill="#DD5858"
      />
    </svg>
  );
}
