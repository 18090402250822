import React from "react";

export default function UmbrellaGreyIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...props.style }}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.61293 3.66713L3.09144 2.14436C2.8211 1.87389 2.38316 1.87389 2.11282 2.14436C1.84248 2.41484 1.84248 2.853 2.11282 3.12348L3.63448 4.64642C-1.32494 10.1191 -1.21833 18.563 4.01262 23.7971C4.14238 23.927 4.3181 24 4.50193 24C4.68575 24 4.86147 23.927 4.99124 23.7971L13.9037 14.8802L20.9432 21.96C21.0783 22.0959 21.2561 22.1642 21.4338 22.1642C21.6102 22.1642 21.7866 22.0972 21.9218 21.9627C22.1928 21.6929 22.1941 21.2547 21.9245 20.9835L14.8823 13.9011L23.7972 4.98163C23.927 4.85181 24 4.676 24 4.49208C24 4.30815 23.927 4.13235 23.7972 4.00252C18.5663 -1.23166 10.1055 -1.32049 4.61293 3.66713ZM5.4035 5.06345C9.17665 5.06345 13.3572 6.94553 16.724 10.1003L10.0877 16.7399C6.93302 13.3706 5.11965 9.25901 5.11965 5.34745C5.11965 5.26555 5.09912 5.18948 5.0731 5.11653C5.0747 5.11493 5.07614 5.11307 5.07774 5.11146C5.11078 5.07841 5.14727 5.05111 5.18056 5.01849C5.25135 5.04291 5.32434 5.06345 5.4035 5.06345ZM4.51544 22.3149C0.57967 17.9133 0.359767 11.3404 3.80969 6.61386C4.15023 10.4942 6.03279 14.4453 9.10932 17.7188L4.51544 22.3149ZM17.7029 9.12081C14.4128 6.01805 10.499 4.11341 6.69706 3.75673C8.90141 2.17969 11.4862 1.37622 14.0671 1.37622C17.0314 1.37622 19.9814 2.41822 22.3158 4.5056L17.7029 9.12081Z"
        fill="#999999"
      />
    </svg>
  );
}
