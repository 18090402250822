import React from "react";

export default function SVGGroceryIcon(props) {
  return (
    <svg
      width="28"
      height="25"
      viewBox="0 0 28 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...props.style }}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.60479 5.5391L3.6072 1.95296H1.33353C0.965082 1.95296 0.6664 1.65449 0.6664 1.2863C0.6664 0.918106 0.965082 0.619629 1.33353 0.619629H3.99936C4.24144 0.619629 4.46452 0.750676 4.58226 0.962047L6.61936 4.6191H25.4325C26.8205 4.6191 27.7483 6.04818 27.1462 7.38956L23.1705 13.9903C22.6873 14.7945 21.818 15.2858 20.88 15.2858H12.617C12.3836 15.2858 12.1545 15.2553 11.9343 15.1968C12.0031 16.2478 11.7578 17.3155 11.2012 18.2331L10.3754 19.5953C10.8644 19.8096 11.2755 20.169 11.5541 20.6191H21.1358C21.5979 19.873 22.424 19.376 23.3664 19.376C24.8135 19.376 25.9869 20.5497 25.9869 21.9974C25.9869 23.445 24.8135 24.6187 23.3664 24.6187C21.9183 24.6187 20.7446 23.4453 20.7446 21.9974C20.7446 21.9824 20.7447 21.9674 20.745 21.9524H11.9448C11.9451 21.9674 11.9452 21.9824 11.9452 21.9974C11.9452 23.445 10.7718 24.6187 9.32476 24.6187C7.87663 24.6187 6.70295 23.4453 6.70295 21.9974C6.70295 20.6835 7.66942 19.5956 8.93042 19.4055L10.0601 17.542C10.7693 16.3729 10.7962 14.8605 10.1291 13.6612L6.9468 7.94831L5.64548 5.6215C5.6302 5.59537 5.61666 5.5681 5.60479 5.5391ZM8.13261 7.33572L11.4677 13.2993C11.7089 13.704 12.1462 13.9524 12.617 13.9524H20.88C21.3497 13.9524 21.7847 13.7066 22.027 13.3033L25.965 6.775C26.1372 6.38791 25.8544 5.95243 25.4325 5.95243H7.36207L8.13261 7.33572ZM22.1836 21.4877C22.1747 21.5154 22.1642 21.5423 22.152 21.5683C22.1046 21.7025 22.0789 21.8469 22.0789 21.9974C22.0789 22.709 22.6553 23.2854 23.3664 23.2854C24.0763 23.2854 24.6526 22.7089 24.6526 21.9974C24.6526 21.2858 24.0763 20.7094 23.3664 20.7094C22.8363 20.7094 22.3809 21.0297 22.1836 21.4877ZM9.32476 23.2854C10.0347 23.2854 10.611 22.7089 10.611 21.9974C10.611 21.2858 10.0347 20.7094 9.32476 20.7094C8.61366 20.7094 8.0372 21.2857 8.0372 21.9974C8.0372 22.709 8.61366 23.2854 9.32476 23.2854Z"
        fill={props.color || "#DD5858"}
      />
    </svg>
  );
}
