import React from "react";

export default function RecibosEImpuestos(props) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...props.style }}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.1367 19.3279C18.3153 19.3279 18.4864 19.2562 18.6117 19.1289L21.0424 16.6593H25.3338V25.9994H4.00045V16.6593H14.666V18.6608C14.666 19.0292 14.9645 19.3279 15.3327 19.3279H18.1367ZM17.8576 17.9936L20.2777 15.5349C20.2846 15.5276 20.2916 15.5204 20.2989 15.5134L27.812 7.88032C28.0623 7.62982 28.0623 7.22364 27.8286 6.98917L26.974 6.19063C26.7251 5.94086 26.3197 5.94086 26.0695 6.19125L15.9994 16.2685V17.9936H17.8576ZM28.7583 8.82022L22.3558 15.3251H26.0004C26.3686 15.3251 26.6671 15.6237 26.6671 15.9922V26.6665C26.6671 27.035 26.3686 27.3337 26.0004 27.3337H3.33378C2.96559 27.3337 2.66711 27.035 2.66711 26.6665V15.9922C2.66711 15.6237 2.96559 15.3251 3.33378 15.3251H15.0566L25.1266 5.24777C25.8978 4.47609 27.1483 4.47609 27.9009 5.23174L28.7548 6.02966C29.5258 6.80123 29.5258 8.05223 28.7583 8.82022ZM11.3451 19.3455H6.01178C5.64359 19.3455 5.34511 19.0468 5.34511 18.6784C5.34511 18.3099 5.64359 18.0112 6.01178 18.0112H11.3451C11.7133 18.0112 12.0118 18.3099 12.0118 18.6784C12.0118 19.0468 11.7133 19.3455 11.3451 19.3455ZM6.01178 21.9965H23.3331C23.7013 21.9965 23.9998 21.6978 23.9998 21.3294C23.9998 20.9609 23.7013 20.6622 23.3331 20.6622H6.01178C5.64359 20.6622 5.34511 20.9609 5.34511 21.3294C5.34511 21.6978 5.64359 21.9965 6.01178 21.9965ZM23.3334 24.6651H19.3334C18.9652 24.6651 18.6667 24.3664 18.6667 23.9979C18.6667 23.6295 18.9652 23.3308 19.3334 23.3308H23.3334C23.7016 23.3308 24 23.6295 24 23.9979C24 24.3664 23.7016 24.6651 23.3334 24.6651Z"
        fill={props.color || "#DD5858"}
      />
    </svg>
  );
}
