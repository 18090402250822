import React, { useState, useRef, useEffect, useCallback } from "react";

import * as moment from "moment";

import Modal from "@material-ui/core/Modal";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";

import {
  onChangeAmountHandler,
  amountStringToNumber,
} from "../../../libs/currencyInput";

import {
  UiWrapper,
  View,
  Button,
  Text,
  TextField,
  InputDate,
  Layout,
  InputCheck,
  InputSelect,
  SelectItem,
} from "coinscrap-webapp-core";

import colors from "../../../config/colors";

import { CloseButton } from "../../common/closeButton";
import Add from "../../common/svgs/Add";
import EuroIcon from "../../common/svgs/EuroIcon";
import CalendarIcon from "../../common/svgs/CalendarIcon";
import ArrowDown from "../../common/svgs/ArrowDown";

export default (props) => {
  const { useBackground } = UiWrapper.use();
  useBackground(colors.blue4);

  const [title, setTitle] = useState("");
  const [amount, setAmount] = useState("");
  const [name, setName] = useState("");
  const [team, setTeam] = useState("");
  const [date, setDate] = useState(moment());

  const [checkOption, setCheckOption] = useState(false);

  const [tab, setTab] = useState("mensual");
  const inputRefAmount = useRef(null);

  const onChangeDateHandler = (date) => {
    setDate(date);
  };

  const formatNumber = (value) => {
    return Intl.NumberFormat("de-DE", { minimumFractionDigits: 2 }).format(
      amountStringToNumber(value)
    );
  };

  const handleBlur = useCallback(() => {
    setAmount((prevState) => formatNumber(prevState));
  }, []);

  useEffect(() => {
    const { current } = inputRefAmount;

    if (current) {
      current.addEventListener("blur", handleBlur);

      return () => {
        current.removeEventListener("blur", handleBlur);
      };
    }
  }, [handleBlur]);

  return (
    <Layout.Container>
      {!props.noText && (
        <View
          startSt
          selfCenterSt
          horizontalSt
          style={{ marginTop: 14, padding: 2, marginBottom: 12 }}
        >
          <Add />
          <Text
            regularSt
            font14St
            greenSt
            style={{
              color: colors.green,
              marginLeft: 7,
              lineHeight: "20px",
            }}
            onClick={() => props.setVisibleModal(true)}
          >
            {props.title ? props.title : 'Añadir manualmente'}
          </Text>
        </View>
      )}

      <Modal
        disablePortal
        open={props.visibleModal}
        onClose={() => props.setVisibleModal(false)}
      >
        <View modalSt>
          <View
            fullWidthSt
            startSt
            whiteBcSt
            borderRadiusTopSt
            style={{ padding: "0 24px" }}
          >
            <View horizontalSt fullWidthSt endSt>
              <CloseButton
                color={"#A1A1A1"}
                action={() => {
                  props.setVisibleModal(false);
                }}
              />
            </View>
            <View wideWidthPeriodicSt>
              <TextField
                centerVerticalLabelSt
                squareInputSt
                squareWithBorderSt
                textLeftSt
                inputMovementSt
                type="number"
                label="Nombre del gasto"
                step="1"
                disableUnderline={true}
                currencyTextFieldWithBorderSt
                height56St
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </View>

            <InputSelect
              centerVerticalLabelSt
              label="Grupo"
              value={team}
              onChange={(e) => setTeam(e.target.value)}
              selectIcon={() => (
                <View
                  autoWidthSt
                  style={{
                    borderLeft: "1px solid " + colors.greyLightBorders2,
                    height: 28,
                  }}
                >
                  <View
                    autoWidthSt
                    style={{
                      padding: 15,
                    }}
                  >
                    <ArrowDown />
                  </View>
                </View>
              )}
              selectWithBorderSt
              borderGreenSt
            >
              <SelectItem value="hipotecas">Hipotecas y préstamos</SelectItem>
              <SelectItem value="impuestos">Recibos e impuestos</SelectItem>
              <SelectItem value="comunidad">Comunidad de vecinos</SelectItem>
              <SelectItem value="suscripciones">
                Suscripciones y otros recibos
              </SelectItem>
              <SelectItem value="comida">Comida</SelectItem>
            </InputSelect>

            <View gridSt wideWidthPeriodicSt style={{ columnGap: 12 }}>
              <TextField
                centerVerticalLabelSt
                inputRef={inputRefAmount}
                squareInputSt
                squareWithBorderSt
                textLeftSt
                inputMovementSt
                height56St
                id="amountInput"
                type="text"
                endAdornment={
                  <EuroIcon
                    style={{
                      borderLeft: "1px solid #E5E5E5",
                      paddingLeft: 6.5,
                      paddingRight: 0,
                      // paddingTop: 15,
                      // paddingBottom: 5,
                      height: "28px",
                      width: "36px",
                      boxSizing: "content-box",
                      marginTop: 0,
                      color: colors.green,
                    }}
                  />
                }
                label="Cantidad"
                disableUnderline={true}
                currencyTextFieldWithBorderSt
                value={amount}
                onChange={(e) => onChangeAmountHandler(e, setAmount)}
              />

              <InputDate
                dateWithBorderSt
                label="Fecha"
                onChange={onChangeDateHandler}
                toolbarComponent={Toolbarcomponent}
                pickOnSelect={true}
                minDate={new Date()}
                inputMovementSt
                dateIcon={<CalendarIcon styleSVG={{ maxWidth: "24px" }} />}
                value={date}
              />
            </View>
            <View centerSt fullWidthSt>
              <View
                fullWidthSt
                startSt
                horizontalSt
                onClick={() => setCheckOption(!checkOption)}
              >
                <InputCheck
                  noPaddingSt
                  value={checkOption}
                  checkedIcon={<CheckBoxOutlinedIcon />}
                  onChange={(e) => setCheckOption(!checkOption)}
                />
                <Text font16St regularSt style={{ marginLeft: 5 }}>
                  Es un gasto recurrente
                </Text>
              </View>
              {checkOption && (
                <InputSelect
                  centerVerticalLabelSt
                  label="Periodicidad"
                  value={tab}
                  onChange={(e) => setTab(e.target.value)}
                  selectIcon={() => (
                    <View
                      autoWidthSt
                      style={{
                        borderLeft: "1px solid " + colors.greyLightBorders2,
                        height: 28,
                      }}
                    >
                      <View
                        autoWidthSt
                        style={{
                          padding: 15,
                        }}
                      >
                        <ArrowDown />
                      </View>
                    </View>
                  )}
                  selectWithBorderSt
                  borderGreenSt
                  marginTopSt
                >
                  <SelectItem value="mensual">Mensual</SelectItem>
                  <SelectItem value="trimestral">Trimestral</SelectItem>
                  <SelectItem value="semastral">Semestral</SelectItem>
                  <SelectItem value="anual">Anual</SelectItem>
                </InputSelect>
              )}
            </View>
          </View>
          <View
            fullWidthSt
            footerSt
            style={{ borderTop: "1px solid #D8D8D8", height: 75 }}
          >
            <Button
              id="siguienteButton"
              pillSt
              onClick={() => props.setVisibleModal(false)}
            >
              Añadir gasto
            </Button>
          </View>
        </View>
      </Modal>
    </Layout.Container>
  );
};

const Toolbarcomponent = ({ close }) => {
  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "7px 0 0 15px",
      }}
    >
      <Text
        regularSt
        style={{ fontSize: 18, lineHeight: "24px", color: colors.blackLight }}
      >
        Elige fecha
      </Text>
      <CloseButton onClick={close} color={colors.red} />
    </View>
  );
};
