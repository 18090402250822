import { UiWrapper } from "coinscrap-webapp-core";

UiWrapper.defineComponent("modal", {
  styles: {
    default: {
      backgroundComponent: {
        backgroundColor: "rgba(128,128,128,0.7)",
      },
      contentComponent: {
        background: "none",
        boxShadow: "none",
      },
    },
  },
});
