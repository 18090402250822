import React, { useState } from "react";
import { InputCheck, Text, View, Var } from "coinscrap-webapp-core";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";
import { getCurrency } from "../../libs/Currency";
import moment from "moment";
export const ExpensesCheck = ({
  children,
  item,
  index,
  data,
  setData,
  ...props
}) => {
  const [checked, setChecked] = useState(false);
  let refName = item.name.replace(/ /g, "");

  const change = () => {
    setChecked((prevState) => !prevState);

    if (!checked) setData((prevState) => [...prevState, item]);
    else {
      setData(data.filter((element) => element.name !== item.name));
    }
  };
  return (
    <View
      fullWidthSt
      spaceEvenlySt
      checkOptionSt
      onClick={() => {
        change();
      }}
    >
      <View flexSt topSt horizontalSt fullWidthSt spaceBetweenSt>
        {item.icon && (
          <View selfCenterSt style={{ marginRight: 15 }}>
            <item.icon />
          </View>
        )}
        <View flexSt selfCenterSt topSt style={{ width: "75%" }}>
          <Text font16St leftSt boldSt ellipsisSt style={{ width: "100%" }}>
            {item.name}
          </Text>
          {item.info && (
            <Text font14St leftSt>
              {item.info}
            </Text>
          )}
        </View>
        <InputCheck
          key={refName}
          // value={checked}
          value={data.find((element) => element.name === item.name)}
          checkedIcon={<CheckBoxOutlinedIcon />}
          // onChange={(e) => {
          //   // setChecked(!checked);
          //   change();
          // }}
        />
      </View>
      <View fullWidthSt startSt topSt>
        {item.last && (
          <Text font12St leftSt greyTextSt regularSt>
            Último cargo <Var>({moment(item.last).format("D MMM YYYY")})</Var>
          </Text>
        )}
        {item.value && (
          <Text boldSt leftSt>
            {getCurrency(item.value, 2, false)}
          </Text>
        )}
        {item.account && (
          <Text font12St leftSt regularSt>
            {item.account}
          </Text>
        )}
        {children}
      </View>
    </View>
  );
};
