import React, { useState } from "react";

import {
  UiWrapper,
  NavigationWrapper,
  View,
  Text,
  PickerHOC,
  Layout,
  Enfatize,
  TextField,
  Page,
  ImageSelector,
} from "coinscrap-webapp-core";
import { defaultImages } from "../common/defaultImages";

import Footer from "../common/Footer";
import ButtonFooterDouble from "../common/ButtonFooterDouble";
import Steps from "../common/Steps";
import colors from "../../config/colors";
import routes from "../../config/routes";
// import { ImageSelector } from "../../componentsBak/ImageSelector";
import { useSave } from "../../libs/hooks/useSave";
import Example from "../common/Example";
import { CameraComposed } from "../common/CameraComposed";

export default PickerHOC()(() => {
  const {
    query: { name: nameQuery },
  } = Page.use();
  const [myData, saveMyData] = useSave();

  const [name, setName] = useState("");
  const [imageUrl, setImageUrl] = useState();
  const [disabled, setDisabled] = useState(true);
  const [tab, setTab] = useState("custom");

  const { useBackground, useFooter } = UiWrapper.use();
  // eslint-disable-next-line no-unused-vars
  const { navigateTo, useBackRoute } = NavigationWrapper.use();

  useBackground(colors.white);

  // useBackRoute(routes.createPocketIntro);

  const getbase64Img = async (imageUrl) => {
    const response = await fetch(imageUrl);
    return response;
  };

  const createId = () => {
    var number = Math.random(); // 0.9394456857981651
    number.toString(36); // '0.xtis06h6'
    var id = number.toString(36).substr(2, 9); // 'xtis06h6'
    return id;
  };

  useFooter(
    <Footer>
      <ButtonFooterDouble
        icon1
        disabled2={!name}
        action2={async () => {
          if (imageUrl) {
            const response = await getbase64Img(imageUrl);
            const blob = await response.blob();
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = async function () {
              var base64data = reader.result;
              console.log({ base64data });
              await saveMyData({
                ...myData,
                creation: {
                  ...myData.creation,
                  name,
                  img: base64data,
                  id: createId(),
                  type: tab === "auto" ? "auto" : "custom",
                },
              });
              // Pasar datos
              tab === "auto"
                ? navigateTo(routes.createPocketFinishedAuto)
                : navigateTo(routes.createPocketExpenses, {
                    queryParams: { name },
                  });
            };
          } else {
            await saveMyData({
              ...myData,
              creation: {
                ...myData.creation,
                name,
                id: createId(),
                type: tab === "auto" ? "auto" : "custom",
              },
            });

            // Pasar datos
            tab === "auto"
              ? navigateTo(routes.createPocketFinishedAuto)
              : navigateTo(routes.createPocketExpenses, {
                  queryParams: { name },
                });
          }
        }}
        // action2={async () =>
        //   // Pasar datos
        //   {
        //     await saveMyData({
        //       creation: {
        //         ...myData.creation,
        //         name: name,
        //       },
        //     });
        //     navigateTo(routes.createPocketExpenses, {
        //       queryParams: { name: name, image: imageUrl },
        //     });
        //   }
        // }
      />
    </Footer>,
    [disabled, name, imageUrl, tab]
  );
  return (
    <Layout.Container>
      <View horizontal24St topSt>
        <Steps step={2} total={4} />
        <View topSt style={{ margin: "0px 0px 23px" }}>
          <Text font28St style={{ margin: "10px 0px" }}>
            Ponle <Enfatize blackLightSt>nombre</Enfatize>
          </Text>
          <Text font16St>
            Usa un nombre que te ayude a identificarlo rápidamente. Evita
            nombres muy genéricos.
          </Text>
        </View>
        <View wideWidthPeriodicSt>
          <TextField
            style={{
              labelComponent: {
                transform: "translate(12px, 23px) scale(1)",
              },
            }}
            squareWithBorderSt
            type="text"
            label="Nombre del pocket"
            disableUnderline={true}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setDisabled(e.target.value === "" ? true : false);
            }}
          />
        </View>
      </View>
      <Text greenSt font16St regularSt centerSt style={{ marginBottom: 10 }}>
        ¿Quieres añadir <Enfatize greenSt>una imagen?</Enfatize>
      </Text>
      {/* <ImageSelector
        defaultSearch={name}
        value={imageUrl}
        label="¡Elige una foto!"
        onChange={(v) => {
          setImageUrl(v);
        }}
        SearchFieldProps={{
          disableUnderline: false,
        }}
        SearchButtonProps={{
          pillSt: true,
        }}
        circleSt
        marginVerticalSt
        style={{
          cameraComponent: {
            color: imageUrl ? "transparent" : colors.green,
          },
        }}
      /> */}
      <ImageSelector
        captions={{
          // Valor por defecto que aparece en la barra de busqueda
          defaultSearch: name,
          noResultsText: " ",
          defaultImageSuggestionText: (
            <>
              <View
                style={{
                  padding: "0px 15px",
                  // paddingTop: 60,
                  textAlign: "center",
                  fontSize: 15,
                  color: "#444444",
                  paddingTop: 70,
                }}
              >
                No hemos encontrado ninguna imagen para tu objetivo.
                <br />
                Añade una imagen de las que te sugerimos.
              </View>
            </>
          ),
        }}
        defaultImages={defaultImages}
        value={imageUrl}
        onChange={(v) => {
          console.log({ v });
          // Al seleccionar la imagen
          setImageUrl(v);
        }}
        // fallBackImages={[]}
        openerComponent={({ open }) => (
          // Este componente esta customizado para santander
          //  Es el de la camarita con el +
          <CameraComposed value={imageUrl} onClick={open} />
        )}
        SearchFieldProps={{
          // Estilos de TExtField que tenia creados en cents
          //  Al tener el lookAndFeel de santander splits y pockets tendran los correspondientes
          mediumSt: true,
          inputImgModalBackgroundSt: true,
          paddingTopSt: true,
        }}
      />

      <View fullWidthSt style={{ padding: "0 16px", marginTop: 24 }}>
        <View fullWidthSt horizontalSt tabWhiteSt>
          <Text
            font14St
            boldSt
            notActivebuttonSt
            activebuttonSt={tab === "custom"}
            onClick={() => setTab("custom")}
          >
            Personalizado
          </Text>
          <Text
            font14St
            boldSt
            notActivebuttonSt
            activebuttonSt={tab === "auto"}
            onClick={() => setTab("auto")}
          >
            Automático
          </Text>
        </View>
      </View>
      <View>
        <Example style={{ marginTop: 0 }}>
          <Text textHeaderSt leftSt style={{ paddingBottom: 0, fontSize: 14 }}>
            {tab === "auto"
              ? "Configuramos tu bolsillo de gasto de forma automática; ¡rápido y sencillo! ¡Siempre puedes modificarlo más tarde y ajustar tus preferencias!"
              : "Podrás configurar tu bolsillo de gasto a tu gusto: seleccionando los conceptos que quieras y los movimientos a incluir en cada uno de ellos."}
          </Text>
        </Example>
      </View>
    </Layout.Container>
  );
});
