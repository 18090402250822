import React from 'react';
import IconButton from '@material-ui/core/IconButton';

import AddWhite from './svgs/AddWhite';
import colors from '../../config/colors';

export const AddButton = ({ color, onClick, action, hidden, ...props }) => {
  return (
    <IconButton
      id='backNavigationButton'
      color={color || 'primary'}
      onClick={action || onClick}
      style={{
        color: color || colors.white,
        backgroundColor: colors.green,
        position: 'absolute',
        right: 16,
        bottom: 16,
        width: 64,
        height: 64,
        visibility: hidden ? 'hidden' : 'visible',
      }}
    >
      <AddWhite style={{ width: 28, height: 28 }} />
    </IconButton>
  );
};
