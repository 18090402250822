import React from "react";

export default function MetaIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...props.style }}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.5735 3H11.6407C10.9041 3 10.5307 3.8739 11.0188 4.40737L13.3018 7.21161C13.361 7.27534 13.361 7.30697 13.3409 7.32735L11.0155 10.027C10.5096 10.5349 10.9032 11.3988 11.6407 11.3988H20.3996L20.4 16.2591C19.2249 16.4979 18.2988 17.424 18.0601 18.599H14.9399C14.6618 17.2301 13.4507 16.199 12 16.199C10.5493 16.199 9.33824 17.2301 9.06008 18.599H5.93992C5.66176 17.2301 4.45071 16.199 3 16.199C1.34383 16.199 0 17.5429 0 19.199C0 20.8552 1.34383 22.199 3 22.199C4.45071 22.199 5.66176 21.168 5.93992 19.799H9.06008C9.33824 21.168 10.5493 22.199 12 22.199C13.4507 22.199 14.6618 21.168 14.9399 19.799H18.0601C18.3382 21.168 19.5493 22.199 21 22.199C22.6562 22.199 24 20.8552 24 19.199C24 17.7483 22.9689 16.5373 21.6 16.2591L21.5995 10.7988V10.7988V4.0284C21.5995 3.46032 21.141 3 20.5735 3ZM14.1924 6.40954L12.3966 4.2H20.3995V10.1988H12.4494L14.2219 8.14098C14.6772 7.68343 14.6772 6.89666 14.1924 6.40954ZM21 20.999C20.0066 20.999 19.2 20.1925 19.2 19.199C19.2 18.2056 20.0066 17.399 21 17.399C21.9934 17.399 22.8 18.2056 22.8 19.199C22.8 20.1925 21.9934 20.999 21 20.999ZM10.2 19.1981V19.199C10.2 20.1925 11.0066 20.999 12 20.999C12.9934 20.999 13.8 20.1925 13.8 19.199C13.8 18.2056 12.9934 17.399 12 17.399C11.0069 17.399 10.2005 18.2051 10.2 19.1981ZM3 17.399C3.99343 17.399 4.8 18.2056 4.8 19.199C4.8 20.1925 3.99343 20.999 3 20.999C2.00657 20.999 1.2 20.1925 1.2 19.199C1.2 18.2056 2.00657 17.399 3 17.399Z"
        fill="#137E84"
      />
    </svg>
  );
}
