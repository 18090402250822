import React from 'react'

export default function MoreIcon(props){
    return(
        <svg 
            width="12" 
            height="11" 
            viewBox="0 0 12 11" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            style={{...props.styleSVG}}
        >
           <path d="M11.2107 5.032C11.2107 5.38667 11.1641 5.76933 11.0707 6.18H6.56273V10.8H5.41473V6.18H0.794734C0.794734 5.82533 0.841401 5.44267 0.934734 5.032H5.41473V0.411999H6.56273V5.032H11.2107Z" fill="#137E84"/>
        </svg>
    )
}