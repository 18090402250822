import HouseEuroIcon from '../../common/svgs/SVGHouseIconEuro';

import HouseIcon from '../../common/svgs/HouseIcon';

import BuildingIcon from '../../common/svgs/SVGBuildingIcon';

import BillIcon from '../../common/svgs/BillIcon';

import TVIcon from '../../common/svgs/TVIcon';

import BagIcon from '../../common/svgs/SVGBagIcon';

import FruitIcon from '../../common/svgs/FruitIcon';

import EuroIcon from '../../common/svgs/EuroIcon';

import GasolineIcon from '../../common/svgs/SVGGasIcon';

import KeyIcon from '../../common/svgs/SVGKeyIcon';

import ToolIcon from '../../common/svgs/SVGToolIcon';

import BookIcon from '../../common/svgs/SVGBookIcon';

import GroceryIcon from '../../common/svgs/SVGGroceryIcon';

import BusIcon from '../../common/svgs/SVGBusIcon';

import HouseWithoutEuroIcon from '../../common/svgs/HouseIcon';

import SportIcon from '../../common/svgs/SVGSportIcon';

import LotteryIcon from '../../common/svgs/SVGLotteryIcon';

import RestaurantIcon from '../../common/svgs/SVGRestaurantIcon';
import RecibosEImpuestosIcon from '../../common/svgs/RecibosEImpuestos';

import FilmIcon from '../../common/svgs/SVGFilmIcon';
import ComunidadDeVecinosIcon from '../../common/svgs/ComunidadDeVecinos';

import SeguroIcon from '../../common/svgs/SVGSeguroIcon';
import ImpuestoIcon from '../../common/svgs/SVGImpuestoIcon';
import OtrosGastosIcon from '../../common/svgs/OtrosGastos';
import HipotecasYPrestamos from '../../common/svgs/HipotecasYPrestamos';

import PNGHouseEuroIcon from '../../../assets/images/HouseIconEuro.png';
import PNGHouseIcon from '../../../assets/images/HouseIcon.png';
import PNGBuildingIcon from '../../../assets/images/ApartmentIcon.png';
import PNGBillIcon from '../../../assets/images/BillIcon.png';
import PNGTVIcon from '../../../assets/images/TVIcon.png';
import PNGTVIconOtrosRecibos from '../../../assets/images/TVIconOtrosRecibos.png';
import PNGBagIcon from '../../../assets/images/BagIcon.png';
import PNGFruitIcon from '../../../assets/images/FoodIcon.png';
import PNGOtrosGastos from '../../../assets/images/Otros_gastos.png';

import PNGEuroIcon from '../../../assets/images/EuroIcon.png';
import PNGGasolineIcon from '../../../assets/images/GasolineIcon.png';
import PNGKeyIcon from '../../../assets/images/KeyIcon.png';
import PNGToolIcon from '../../../assets/images/ToolIcon.png';
// import PNGBookIcon from "../../../assets/images/BookIcon.png";
import PNGGroceryIcon from '../../../assets/images/GroceryIcon.png';
import PNGBusIcon from '../../../assets/images/BusIcon.png';
import PNGHouseWithoutEuroIcon from '../../../assets/images/HouseIcon.png';
import PNGSportIcon from '../../../assets/images/SportIcon.png';
import PNGLotteryIcon from '../../../assets/images/LotteryIcon.png';
import PNGRestaurantIcon from '../../../assets/images/RestaurantIcon.png';
import PNGFilmIcon from '../../../assets/images/FilmIcon.png';
import PNGComunidadDeVecinosIcon from '../../../assets/images/ComunidadDeVecinosIcon.png';
import PNGHipotecasYPrestamosIcon from '../../../assets/images/HipotecasYPrestamos.png';
import PNGRecibosEImpuestos from '../../../assets/images/RecibosEImpuestos.png';
// import PNGSeguroIcon from "../../../assets/images/SeguroIcon.png";
// import PNGImpuestoIcon from "../../../assets/images/ImpuestoIcon.png";

// images for each pocket
import CasaPlaya from '../../../assets/images/casa_playa.png';
import Transport from '../../../assets/images/transport.jpg';
import Study from '../../../assets/images/study.jpg';
import Sport from '../../../assets/images/sport.jpg';

// new images
import CasaImg from '../../../assets/images/casa.png';
import VehiculoImg from '../../../assets/images/vehiculo.png';
import EstudiosImg from '../../../assets/images/estudiosImg.png';

import routes from '../../../config/routes';

// test

export const miCasa = {
  title: 'Mi casa',
  img: CasaPlaya,
  imgColor: '#bad8d5',
  categories: [
    {
      name: 'Hipotecas y préstamos',
      info: 'Las cuotas mensuales',
      icon: HipotecasYPrestamos,
      owner: true,
      rental: false,
      route: routes.expenseMortgage,
    },
    {
      name: 'Alquiler',
      info: 'Las cuotas mensuales',
      icon: HouseEuroIcon,
      owner: false,
      rental: true,
      route: routes.expenseMortgage,
    },
    {
      name: 'Comunidad de vecinos',
      info: 'Las cuotas y las derramas',
      icon: ComunidadDeVecinosIcon,
      owner: true,
      rental: false,
      route: routes.expenseCommunity,
    },
    {
      name: 'Recibos e impuestos',
      info: 'La luz, el agua, la alarma, el seguro…',
      icon: RecibosEImpuestosIcon,
      owner: true,
      rental: true,
      route: routes.expenseTaxes,
      subcategories: [
        { name: 'Seguros', icon: SeguroIcon },
        { name: 'Suministros', icon: ImpuestoIcon },
        { name: 'Impuestos', icon: ImpuestoIcon },
      ],
    },
    {
      name: 'Otros gastos',
      info: 'Incluye lo que necesites',
      icon: OtrosGastosIcon,
      owner: true,
      rental: true,
      route: routes.expenseOtherExpense,
    },
    {
      name: 'Comida',
      info: 'Las compras del súper y el mercado',
      icon: FruitIcon,
      owner: true,
      rental: true,
      route: routes.expenseFood,
      // subcategories: [
      //   { name: "Supermercados", icon: GroceryIcon },

      //   { name: "Restaurantes", icon: RestaurantIcon },
      // ],
    },
    {
      name: 'Otros recibos',
      info: '¿La tv de pago? Es aqui',
      icon: TVIcon,
      owner: true,
      rental: true,
      route: routes.expenseSubscription,
    },
  ],
};

export const transporte = {
  title: 'Transporte',
  categories: [
    {
      name: 'Gasolina',
      info: 'Recibos de gasolineras',
      icon: GasolineIcon,
    },
    {
      name: 'Financiación',
      info: 'Las cuotas mensuales',
      icon: HouseIcon,
    },

    {
      name: 'Mantenimiento',
      info: 'Reparaciones, revisiones, cambios…',
      icon: ToolIcon,
    },
    {
      name: 'Garaje',
      info: 'Las cuotas mensuales',
      icon: KeyIcon,
    },
    {
      name: 'Otros gastos',
      info: 'Multas de tráfico, aparcamientos…',
      icon: OtrosGastosIcon,
    },
  ],
};

export const estudios = {
  title: 'Estudios',
  categories: [
    {
      name: 'Financiación',
      info: 'Cuotas del préstamo de estudios',
      icon: BookIcon,
    },
    {
      name: 'Recibos',
      info: 'Las cuotas mensuales',
      icon: BillIcon,
    },
    {
      name: 'Alimentación',
      info: 'Supermercados y grandes almacenes',
      icon: GroceryIcon,
      // subcategories: [
      //   { name: "Supermercados", icon: GroceryIcon },

      //   { name: "Restaurantes", icon: RestaurantIcon },
      // ],
    },

    {
      name: 'Transporte',
      info: 'Desplazamiento y transporte público',
      icon: BusIcon,
    },
    {
      name: 'Vivienda',
      info: 'Alquiler de la vivienda, residencia…',
      icon: HouseWithoutEuroIcon,
    },
    {
      name: 'Otros gastos',
      info: 'Libros, material académico…',
      icon: OtrosGastosIcon,
    },
  ],
};
export const ocio = {
  title: 'Ocio',
  categories: [
    {
      name: 'Deporte',
      info: 'Gimnasio, club, material deportivo…',
      icon: SportIcon,
    },
    {
      name: 'Loterías y apuestas',
      info: 'Loterías, sorteos y apuestas deportivas',
      icon: LotteryIcon,
    },
    {
      name: 'Restauración',
      info: 'Restaurantes, cafeterías , catering…',
      icon: RestaurantIcon,
    },

    {
      name: 'Plataformas audiovisuales',
      info: 'Netflix, HBO, Disney+, Filmin…',
      icon: TVIcon,
    },
    {
      name: 'Espectáculos y cultura',
      info: 'Cine, teatro, magia, exposiciones…',
      icon: FilmIcon,
    },
    {
      name: 'Otros gastos',
      info: 'Fiestas, juegos de mesa…',
      icon: OtrosGastosIcon,
    },
  ],
};

export const getCategory = (expression) => {
  switch (expression) {
    case 'miCasa': {
      return miCasa;
    }

    case 'transporte': {
      return transporte;
    }
    case 'estudios': {
      return estudios;
    }
    case 'ocio': {
      return ocio;
    }

    default: {
      return null;
    }
  }
};
export const getCategoryIcon = (expression) => {
  let addColorLight = '30';

  switch (expression) {
    case 'Hipotecas y préstamos': {
      return {
        svg: HipotecasYPrestamos,
        png: PNGHipotecasYPrestamosIcon,
        color: '#cc4400',
        colorLight: '#cc4400' + addColorLight,
      }; // DONE
    }
    case 'Alquiler': {
      return {
        svg: HouseEuroIcon,
        png: PNGHouseEuroIcon,
        color: '',
        colorLight: '' + addColorLight,
      };
    }

    case 'Comunidad de vecinos': {
      return {
        svg: ComunidadDeVecinosIcon,
        png: PNGComunidadDeVecinosIcon,
        color: '#cd3b69',
        colorLight: '#cd3b69' + addColorLight,
      }; // DONE
    }
    case 'Recibos e impuestos': {
      return {
        svg: RecibosEImpuestosIcon,
        png: PNGRecibosEImpuestos,
        color: '#cc0000',
        colorLight: '#cc0000' + addColorLight,
      }; // DONE
    }
    case 'Otros gastos': {
      return {
        svg: OtrosGastosIcon,
        png: PNGOtrosGastos,
        color: '#727272',
        colorLight: '#727272' + addColorLight,
      }; // DONE
    }
    case 'Comida': {
      return {
        svg: FruitIcon,
        png: PNGFruitIcon,
        color: '#7B6795',
        colorLight: '#7B6795' + addColorLight,
      }; // DONE
    }
    case 'Otros recibos': {
      return {
        svg: TVIcon,
        png: PNGTVIconOtrosRecibos,
        color: '#c900e8',
        colorLight: '#c900e8' + addColorLight,
      }; // DONE
    }
    case 'Gasolina': {
      return {
        svg: GasolineIcon,
        png: PNGGasolineIcon,
        color: '#027560',
        colorLight: '#027560' + addColorLight,
      }; // DONE
    }
    case 'Financiación': {
      return {
        svg: HouseIcon,
        png: PNGHouseIcon,
        color: '#3366ff',
        colorLight: '#3366ff' + addColorLight,
      };
    }
    case 'Mantenimiento': {
      return {
        svg: ToolIcon,
        png: PNGToolIcon,
        color: '#732645',
        colorLight: '#732645' + addColorLight,
      }; // DONE
    }
    case 'Garaje': {
      return {
        svg: KeyIcon,
        png: PNGKeyIcon,
        color: '#257fa4',
        colorLight: '#257fa4' + addColorLight,
      }; // DONE
    }
    case 'Recibos': {
      return {
        svg: BillIcon,
        png: PNGBillIcon,
        color: '#cc0000',
        colorLight: '#cc0000' + addColorLight,
      }; // DONE
    }
    case 'Alimentación': {
      return {
        svg: GroceryIcon,
        png: PNGGroceryIcon,
        color: '#027560',
        colorLight: '#027560' + addColorLight,
      };
    }
    case 'Transporte': {
      return {
        svg: BusIcon,
        png: PNGBusIcon,
        color: '#257fa4',
        colorLight: '#257fa4' + addColorLight,
      }; // DONE
    }
    case 'Vivienda': {
      return {
        svg: HouseWithoutEuroIcon,
        png: PNGHouseWithoutEuroIcon,
        color: '#25303b',
        colorLight: '#25303b' + addColorLight,
      }; // DONE
    }
    case 'Deporte': {
      return {
        svg: SportIcon,
        png: PNGSportIcon,
        color: '#946f00',
        colorLight: '#946f00' + addColorLight,
      }; // DONE
    }
    case 'Loterías y apuestas': {
      return {
        svg: LotteryIcon,
        png: PNGLotteryIcon,
        color: '#cd3b69',
        colorLight: '#cd3b69' + addColorLight,
      }; // DONE
    }
    case 'Restauración': {
      return {
        svg: RestaurantIcon,
        png: PNGRestaurantIcon,
        color: '#7b6795',
        colorLight: '#7b6795' + addColorLight,
      }; //DONE
    }
    case 'Plataformas audiovisuales': {
      return {
        svg: TVIcon,
        png: PNGTVIcon,
        color: '#732645',
        colorLight: '#732645' + addColorLight,
      }; // DONE
    }
    case 'Espectáculos y cultura': {
      return {
        svg: FilmIcon,
        png: PNGFilmIcon,
        color: '#3366ff',
        colorLight: '#3366ff' + addColorLight,
      }; // DONE
    }

    default: {
      return { svg: EuroIcon, png: PNGEuroIcon };
    }
  }
};

export const mockDataCasa2 = {
  img: CasaPlaya,
  imgColor: '#bad8d5',
  name: 'Mi Casa',
  id: 1,
  categories: [
    {
      name: 'Alquiler',

      movements: [],
    },
    {
      name: 'Otros recibos',

      movements: [],
    },
    {
      name: 'Recibos e impuestos',

      movements: [
        {
          name:
            'Recibo Linea Directa Aseguradora S.a. Nº Recibo 0049 1770 755 Bbqhqps Ref. Mandato 18111128001',
          value: -363.72,
          last: '2021-01-19T23:00:00.000Z',
          subCategory: 'Seguros',
        },
        {
          name:
            'Transferencia A Favor De Romero Catalina Diego Concepto Seguro Cubas',
          value: -287.65,
          last: '2020-04-19T22:00:00.000Z',
          subCategory: 'Seguros',
        },
        {
          name:
            'Recibo Caja De Seguros Reunidos, S.a. Caser Nº Recibo 0049 1770 755 Bbnkgnn Ref. Mandato 29gb8000002',
          value: -377.84,
          last: '2020-02-23T23:00:00.000Z',
          subCategory: 'Seguros',
        },
        {
          name:
            'Recibo Almudena Seguros Nº Recibo 0049 1770 755 Bbqfmwb Ref. Mandato 218177598',
          value: -24.49,
          last: '2021-01-03T23:00:00.000Z',
          subCategory: 'Seguros',
        },
        {
          name:
            'Recibo Caja De Seguros Reunidos, S.a. Caser Nº Recibo 0049 1770 755 Bbqmtlz Ref. Mandato 29gb8000002',
          value: -383.17,
          last: '2021-02-22T23:00:00.000Z',
          subCategory: 'Seguros',
        },
        {
          name:
            'Recibo Telefonica De Espana Sau, Fijoxxxxxx058.mar, Nº Recibo 0049 1770 755 Bbqqghg Ref. Mandato X00',
          value: -38,
          last: '2021-03-03T23:00:00.000Z',
          subCategory: 'Suministros',
        },
        {
          name:
            'Recibo Endesa Energia Sau Nº Recibo 0049 1770 755 Bbnbzfb Ref. Mandato E00020716106272000070001',
          value: -7.72,
          last: '2020-01-01T23:00:00.000Z',
          subCategory: 'Suministros',
        },
        {
          name:
            'Recibo Iberdrola Clientes, S.a.u Nº Recibo 0049 1770 755 Bbqmclv Ref. Mandato 294212970000',
          value: -633.64,
          last: '2021-02-17T23:00:00.000Z',
          subCategory: 'Suministros',
        },
        {
          name: 'Compra Internet En Pago Tributos, Barcelona, Tarj. :*593924',
          value: -100,
          last: '2021-01-07T23:00:00.000Z',
          subCategory: 'Impuestos',
        },
        {
          name:
            'Transferencia A Favor De Ayuntamiento Cubas De La Sagra Concepto Urbana 2019 Romero Catalina Diego',
          value: -682.47,
          last: '2020-10-26T23:00:00.000Z',
          subCategory: 'Impuestos',
        },
        {
          name:
            'Pago Recibo De Ay. Torrejon De Ardoz (281487), Referencia 000025044350',
          value: -8.84,
          last: '2020-10-04T22:00:00.000Z',
          subCategory: 'Impuestos',
        },
        // new
        {
          last: '2021-08-02T22:00:00.000Z',
          name:
            'Recibo Prosegur Alar Espana, Nif:b87222006 Cto.502194909 Fra.e632104100316 Dest.nif:53444824t Fecha',
          value: -47.14,
          subCategory: 'Seguros',
        },
        {
          last: '2021-04-20T22:00:00.000Z',
          name:
            'Recibo Iberdrola Clientes, S.a.u, Iberdrola Gas 1023620690 Boadilla Del Monte, Cl Cabo De Gata 8,ba,',
          value: -350.71,
          subCategory: 'Suministros',
        },
        {
          last: '2021-03-31T22:00:00.000Z',
          name:
            'Recibo Almudena Seguros, Pol.2181775 Decesosperiodo:01/04/2021 A 30/06/2021 Asistencia 24h.901242526',
          value: -24.49,
          subCategory: 'Seguros',
        },
        {
          last: '2021-08-03T22:00:00.000Z',
          name:
            'Recibo Telefonica De Espana Sau, Fijoxxxxxx058.may, Nº Recibo 0049 1770 755 Bbqywld Ref. Mandato X00',
          value: -38,
          subCategory: 'Suministros',
        },
        {
          last: '2021-10-04T22:00:00.000Z',
          name:
            'Recibo Telefonica De Espana Sau, Fijoxxxxxx058.jul, Nº Recibo 0049 1770 755 Bbrkgpr Ref. Mandato X00',
          value: -38,
          subCategory: 'Suministros',
        },
        {
          last: '2021-09-29T22:00:00.000Z',
          name:
            'Recibo Ayuntamiento De Boadilla Del Monte, Ayto Boadilla Importe Plazo 1/2 Ibi 511,36 Romero Catalin',
          value: -511.36,
          subCategory: 'Impuestos',
        },
        {
          last: '2021-09-29T22:00:00.000Z',
          name:
            'Recibo Ayuntamiento De Boadilla Del Monte, Ayto Boadilla Importe Plazo 1/2 Ibi 511,36 Romero Catalin',
          value: -511.36,
          subCategory: 'Impuestos',
        },
        {
          last: '2022-03-03T22:00:00.000Z',
          name:
            'Recibo Telefonica De Espana Sau Fijoxxxxxx058.mar Nº Recibo 0049 1770 755 Bbsxqqj Ref. Mandato X0060',
          value: -38,
          subCategory: 'Suministros',
        },
        {
          last: '2022-02-28T22:00:00.000Z',
          name: 'Transferencia De Linea Directa Aseguradora S.a., .',
          value: -166.94,
          subCategory: 'Seguros',
        },
        {
          last: '2022-02-04T22:00:00.000Z',
          name:
            'Recibo Telefonica De Espana Sau Fijoxxxxxx058.feb Nº Recibo 0049 1770 755 Bbssrlb Ref. Mandato X0060',
          value: -38,
          subCategory: 'Suministros',
        },
        {
          last: '2022-01-20T22:00:00.000Z',
          name:
            'Recibo Linea Directa Aseguradora S.a. Nº Recibo 0049 1770 755 Bbspwzw Ref. Mandato 18111128001, De',
          value: -363.81,
          subCategory: 'Seguros',
        },
        {
          last: '2022-01-04T22:00:00.000Z',
          name:
            'Recibo Telefonica De Espana Sau Fijoxxxxxx058.ene Nº Recibo 0049 1770 755 Bbsmyyr Ref. Mandato X0060',
          value: -38,
          subCategory: 'Suministros',
        },
        {
          last: '2022-04-04T22:00:00.000Z',
          name:
            'Recibo Telefonica De Espana Sau Fijoxxxxxx058.ene Nº Recibo 0049 1770 755 Bbsmyyr Ref. Mandato X0060',
          value: -38,
          subCategory: 'Suministros',
        },
        {
          last: '2022-04-01T22:00:00.000Z',
          name:
            'Recibo Almudena Seguros Pol.2181775 Decesosperiodo:01/04/2022 A 30/06/2022 Asistencia 24h.901242526a',
          value: -24.95,
          subCategory: 'Seguros',
        },
      ],
    },

    {
      name: 'Hipotecas y préstamos',
      movements: [
        {
          name: 'Liquidacion Periodica Prestamo 0049 1770 103 0008214',
          value: -1036.37,
          last: '2021-03-07T23:00:00.000Z',
        },
        {
          name: 'Liquidacion Periodica Prestamo 0049 1770 103 0003293',
          value: -412.28,
          last: '2021-03-27T23:00:00.000Z',
        },
        {
          name: 'Liquidacion Periodica Prestamo 0049 5770 103 0004606',
          value: -466.25,
          last: '2021-02-25T23:00:00.000Z',
        },
        {
          last: '2021-04-29T22:00:00.000Z',
          name: 'Liquidacion Periodica Prestamo 0049 1770 103 0003293',
          value: -412.28,
        },
        {
          last: '2021-04-25T22:00:00.000Z',
          name: 'Liquidacion Periodica Prestamo 0049 5770 103 0004606',
          value: -466.25,
        },
        {
          last: '2022-03-08T22:00:00.000Z',
          name: 'Liquidacion Periodica Prestamo 0049 5770 103 0023980',
          value: -1036.37,
        },
        {
          last: '2022-03-07T22:00:00.000Z',
          name:
            'Recibo Banco Cetelem, S.a. Recibo Emitido Por Banco Cetelem S.a. Cetelem Marzo Nº Recibo 0049 1770 7',
          value: -116.67,
        },
        {
          last: '2022-02-28T22:00:00.000Z',
          name: 'Liquidacion Periodica Prestamo 0049 5770 103 0004606',
          value: -466.25,
        },
        {
          last: '2022-02-11T22:00:00.000Z',
          name: 'Cuota Impagada Prestamo 0049 5770 103 0023980',
          value: -171.02,
        },
        {
          last: '2022-01-26T22:00:00.000Z',
          name: 'Liquidacion Periodica Prestamo 0049 5770 103 0004606',
          value: -466.25,
        },
        {
          last: '2022-04-08T22:00:00.000Z',
          name: 'Liquidacion Periodica Prestamo 0049 5770 103 0023980',
          value: -1036.37,
        },
        {
          last: '2022-04-05T22:00:00.000Z',
          name:
            'Recibo Banco Cetelem, S.a. Recibo Emitido Por Banco Cetelem S.a. Cetelem Abril Nº Recibo 0049 1770 7',
          value: -116.67,
        },
      ],
    },
    {
      name: 'Comunidad de vecinos',
      movements: [
        {
          name:
            'Recibo C P Fisterra I Nº Recibo 0049 1770 755 Bbqpbrw Ref. Mandato 087000300000',
          value: -54,
          last: '2021-02-28T23:00:00.000Z',
        },
        {
          last: '2021-08-02T22:00:00.000Z',
          name:
            'Recibo C P Fisterra I Nº Recibo 0049 1770 755 Bbqyfkm Ref. Mandato 087000300000',
          value: -54,
        },
        {
          last: '2021-09-30T22:00:00.000Z',
          name:
            'Recibo C P Fisterra I Nº Recibo 0049 1770 755 Bbrjhrw Ref. Mandato 087000300000',
          value: -54,
        },
        {
          last: '2022-03-01T22:00:00.000Z',
          name:
            'Recibo C P Fisterra I Nº Recibo 0049 1770 755 Bbswqvd Ref. Mandato 00870000030000000003661, De',
          value: -54,
        },
        {
          last: '2022-02-01T22:00:00.000Z',
          name:
            'Recibo C P Fisterra I Nº Recibo 0049 1770 755 Bbsrsjh Ref. Mandato 00870000030000000003661, De',
          value: -54,
        },
        {
          last: '2022-01-03T22:00:00.000Z',
          name:
            'Recibo C P Fisterra I Nº Recibo 0049 1770 755 Bbsrsjh Ref. Mandato 00870000030000000003661, De',
          value: -54,
        },
        {
          last: '2022-04-01T22:00:00.000Z',
          name:
            'Recibo C P Fisterra I Nº Recibo 0049 1770 755 Bbtbzrp Ref. Mandato 00870000030000000003661, De',
          value: -54,
        },
      ],
    },
    {
      name: 'Suscripciones y otros recibos',
      movements: [
        {
          name:
            'Recibo Casvi Boadilla Nº Recibo 0049 1770 755 Bbqnsmk Ref. Mandato Ca0010002316',
          value: -58,
          last: '2021-02-28T23:00:00.000Z',
        },
        {
          name:
            'Recibo Prosegur Alar Espana, Nif:b87222006 Cto.502194909 Fra.e632102091177 Dest.nif:53444824t Fecha',
          value: -47.14,
          last: '2021-02-28T23:00:00.000Z',
        },
        // new
        {
          last: '2021-08-03T22:00:00.000Z',
          name: 'Devolucion Transferencia De Mario Romero, Concepto Spotify',
          value: 5,
        },

        {
          last: '2021-08-02T22:00:00.000Z',
          name: 'Transferencia A Favor De Mario Romero Concepto: Spotify',
          value: -5,
        },
        {
          last: '2021-03-31T22:00:00.000Z',
          name: 'Transferencia A Favor De Mario Romero Concepto: Spotify',
          value: -5,
        },
        {
          last: '2021-03-01T22:00:00.000Z',
          name: 'Transferencia A Favor De Mario Romero Concepto: Spotify',
          value: -5,
        },
        {
          last: '2022-03-01T22:00:00.000Z',
          name: 'Transferencia A Favor De Mario Romero Concepto: Spotify',
          value: -5,
        },
        {
          last: '2022-02-251T22:00:00.000Z',
          name:
            'Compra Apple.com/bill, 900812703, Tarjeta 5489017844593924 , Comision 0,00',
          value: -15.99,
        },
        {
          last: '2022-01-251T22:00:00.000Z',
          name:
            'Compra Apple.com/bill, 900812703, Tarjeta 5489017844593924 , Comision 0,00',
          value: -15.99,
        },
        {
          last: '2022-04-01T22:00:00.000Z',
          name: 'Transferencia A Favor De Mario Romero Concepto: Spotify',
          value: -5,
        },
      ],
    },
    {
      name: 'Otros gastos',
      movements: [
        {
          name:
            'Bizum A Favor De Laura Garcia Sanchez Barb Concepto Sin Concepto',
          value: -100,
          last: '2021-02-13T23:00:00.000Z',
        },
        {
          name:
            'Transferencia A Favor De Montajes Caldera Calderas Sl Concepto Pago 20-0000000630',
          value: -1983.35,
          last: '2021-01-07T23:00:00.000Z',
        },
        {
          name:
            'Compra Pago Móvil En Bricomart Alcor, Alcorcon, Tarj. :*107003',
          value: -8.71,
          last: '2021-02-19T23:00:00.000Z',
        },
        {
          name:
            'Compra Pago Móvil En Ikea Alcorcon R, Alcorcon, Tarj. :*107003',
          value: -32,
          last: '2021-02-19T23:00:00.000Z',
        },
        {
          name:
            'Devolucion Compra En Ikea Alcorcon R, Alcorcon, Tarj. :*107003',
          value: 98,
          last: '2021-02-19T23:00:00.000Z',
        },
        {
          last: '2021-03-31T22:00:00.000Z',
          name:
            'Compra Pago Móvil En Ikea Alcorcon R, Alcorcon, Tarj. :*383705',
          value: -94,
        },
        {
          last: '2021-03-31T22:00:00.000Z',
          name:
            'Compra Pago Móvil En Bricolaje Brico, Majadahonda, Tarj. :*107003',
          value: -4.05,
        },
        {
          last: '2022-02-28T22:00:00.000Z',
          name:
            'Recibo Financiera El Corte Ingles E.f.c., S.a. Ecitc0154755177 Nº Recibo 0049 1770 755 Bbsvwwr Ref.',
          value: -34.95,
        },
        {
          last: '2022-01-31T22:00:00.000Z',
          name:
            'Recibo Financiera El Corte Ingles E.f.c., S.a. Ecitc0154755177 Nº Recibo 0049 1770 755 Bbsqysn Ref.',
          value: -313.12,
        },
        {
          last: '2022-04-08T22:00:00.000Z',
          name: 'Transferencia A Favor De José Luis Romero Concepto: Deuda',
          value: -950,
        },
      ],
    },
    {
      name: 'Comida',

      movements: [
        {
          name:
            'Compra Pago Móvil En Mercadona Avda, Boadilla Del, Tarj. :*107003',
          value: -14.93,
          last: '2021-01-04T23:00:00.000Z',
          subCategory: 'Supermercados',
        },
        {
          name:
            'Compra Pago Móvil En Supercor Boadil, Boadilla Del, Tarj. :*107003',
          value: -43.63,
          last: '2021-01-12T23:00:00.000Z',
          subCategory: 'Supermercados',
        },
        {
          name:
            'Compra Carrefour Majad, Majadahonda, Tarjeta 5489019197383705 , Comision 0,00',
          value: -18.36,
          last: '2021-02-20T23:00:00.000Z',
          subCategory: 'Supermercados',
        },
        {
          name:
            'Compra Pago Móvil En Denominacion De, Las Rozas, Tarj. :*107003',
          value: -87,
          last: '2021-02-13T23:00:00.000Z',
          subCategory: 'Supermercados',
        },
        {
          name:
            'Compra Pago Móvil En Mercadona Avda, Boadilla Del, Tarj. :*383705',
          value: -91.02,
          last: '2021-01-28T23:00:00.000Z',
          subCategory: 'Supermercados',
        },
        {
          name: 'Compra Pago Móvil En La Plaza, Boadilla Del, Tarj. :*107003',
          value: -40.28,
          last: '2021-01-22T23:00:00.000Z',
          subCategory: 'Supermercados',
        },
        {
          name:
            'Compra Market Boadilla, Boadilla Del, Tarjeta 5489019197383705 , Comision 0,00',
          value: -40.59,
          last: '2021-01-03T23:00:00.000Z',
          subCategory: 'Supermercados',
        },
        {
          name:
            'Compra Carrefour Majad, Majadahonda, Tarjeta 5489017844593924 , Comision 0,00',
          value: -13.02,
          last: '2021-01-21T23:00:00.000Z',
          subCategory: 'Supermercados',
        },
        {
          name:
            'Compra Pago Móvil En Supercor Boadil, Boadilla Del, Tarj. :*593924',
          value: -57.37,
          last: '2021-01-07T23:00:00.000Z',
          subCategory: 'Supermercados',
        },
        {
          name:
            'Compra Pago Móvil En Mercadona El Bo, Villaviciosa, Tarj. :*383705',
          value: -144.26,
          last: '2021-01-03T23:00:00.000Z',
          subCategory: 'Supermercados',
        },
        {
          name:
            'Compra Deliveroo, Madrid, Tarjeta 5489019306107003 , Comision 0,00',
          value: -39.4,
          last: '2021-02-25T23:00:00.000Z',
          subCategory: 'Restaurantes',
        },
        {
          name:
            'Compra Pago Móvil En Aqua E Farina, Boadlla Del M, Tarj. :*107003',
          value: -77,
          last: '2021-02-27T23:00:00.000Z',
          subCategory: 'Restaurantes',
        },
        {
          name: 'Compra En Chacabuco Deibe, Torrejon De A, Tarj. :*107003',
          value: -57.5,
          last: '2021-02-12T23:00:00.000Z',
          subCategory: 'Restaurantes',
        },
        {
          name:
            'Compra Pago Móvil En Volapie Boadill, Residencia Nt, Tarj. :*107003',
          value: -66,
          last: '2021-01-29T23:00:00.000Z',
          subCategory: 'Restaurantes',
        },
        {
          name:
            'Compra Burguer King Boadilla, Boadilla Del, Tarjeta 5489017844593924 , Comision 0,00',
          value: -17.05,
          last: '2021-03-05T23:00:00.000Z',
          subCategory: 'Restaurantes',
        },
        {
          name:
            'Compra Pago Móvil En Restaurante La, Majadahonda, Tarj. :*593924',
          value: -107,
          last: '2021-02-26T23:00:00.000Z',
          subCategory: 'Restaurantes',
        },
        {
          name: 'Compra Pago Móvil En Txistu, Madrid, Tarj. :*593924',
          value: -240,
          last: '2021-02-17T23:00:00.000Z',
          subCategory: 'Restaurantes',
        },
        {
          name:
            'Compra Pago Móvil En Bar Donde Dani, Boadilla Mont, Tarj. :*593924',
          value: -11,
          last: '2021-01-22T23:00:00.000Z',
          subCategory: 'Restaurantes',
        },
        {
          name:
            'Compra Pago Móvil En Bar Donde Dani, Boadilla Del, Tarj. :*593924',
          value: -18,
          last: '2021-01-07T23:00:00.000Z',
          subCategory: 'Restaurantes',
        },
        {
          name:
            'Compra Pago Móvil En Volapie Boadill, Residencia Nt, Tarj. :*593924',
          value: -68,
          last: '2021-01-06T23:00:00.000Z',
          subCategory: 'Restaurantes',
        },
        {
          name:
            'Compra Pago Móvil En Denominacion De, Las Rozas, Tarj. :*107003',
          value: -80,
          last: '2021-01-04T23:00:00.000Z',
          subCategory: 'Restaurantes',
        },
        {
          name:
            'Compra Pago Móvil En Mercadona El Bo, Villaviciosa, Tarj. :*107003',
          value: -75.48,
          last: '2021-01-21T23:00:00.000Z',
          subCategory: 'Restaurantes',
        },
        {
          last: '2021-04-15T22:00:00.000Z',
          name:
            'Compra Burguer King Boadilla, Boadilla Del, Tarjeta 5489017844593924 , Comision 0,00',
          value: -14.27,
          subCategory: 'Restaurantes',
        },
        {
          last: '2021-04-30T22:00:00.000Z',
          name:
            'Compra Pago Móvil En El Obrador De L, Boadilla Del, Tarj. :*383705',
          value: -14.1,
          subCategory: 'Supermercados',
        },
        {
          last: '2021-04-29T22:00:00.000Z',
          name:
            'Compra Pago Móvil En Mercadona Avda, Boadilla Del, Tarj. :*383705',
          value: -115.03,
          subCategory: 'Supermercados',
        },
        {
          last: '2021-04-09T22:00:00.000Z',
          name:
            'Compra Pago Móvil En Mercadona Avda, Boadilla Del, Tarj. :*383705',
          value: -75.88,
          subCategory: 'Supermercados',
        },
        {
          last: '2021-10-01T22:00:00.000Z',
          name:
            'Compra Pago Móvil En Supercor Boadil, Boadilla Del, Tarj. :*383705',
          value: -22.43,
          subCategory: 'Supermercados',
        },
        {
          last: '2021-10-01T22:00:00.000Z',
          name: 'Compra Pago Móvil En El Corte Ingles, Pozuelo, Tarj. :*107003',
          value: -6.99,
          subCategory: 'Supermercados',
        },
        {
          last: '2021-10-01T22:00:00.000Z',
          name:
            'Compra Pago Móvil En Pintxaki Boadil, Boadilla Del, Tarj. :*107003',
          value: -33,

          subCategory: 'Restaurantes',
        },
        {
          last: '2021-09-29T22:00:00.000Z',
          name: 'Compra Pago Móvil En Lobito Madrid, Madrid, Tarj. :*107003',
          value: -136,
          subCategory: 'Restaurantes',
        },
        {
          last: '2021-09-27T22:00:00.000Z',
          name:
            'Compra Pago Móvil En Mercadona Avda, Boadilla Del, Tarj. :*383705',
          value: -58.63,
          subCategory: 'Supermercados',
        },
        {
          last: '2022-02-25T22:00:00.000Z',
          name: 'Pago Movil En Mercadona Avda, Boadilla Del, Tarj. :*107003',
          value: -114,
          subCategory: 'Supermercados',
        },
        {
          last: '2022-01-28T22:00:00.000Z',
          name:
            'Compra Pago Móvil En Mercadona Avda, Boadilla Del, Tarj. :*383705',
          value: -90.63,
          subCategory: 'Supermercados',
        },
        {
          last: '2022-04-11T22:00:00.000Z',
          name: 'Pago Movil En Mercadona Avda, Boadilla Del, Tarj. :*383705',
          value: -89.47,
          subCategory: 'Supermercados',
        },
        {
          last: '2021-04-04T22:00:00.000Z',
          name:
            'Transaccion Contactless En Bar Donde Dani, Boadilla Mont, Tarj. :*107003',
          value: -17.5,
          subCategory: 'Restaurantes',
        },
      ],
    },
  ],
};
export const mockDataCasa = {
  name: 'Mi Casa',
  id: 1,
  categories: [
    {
      name: 'Alquiler',

      movements: [],
    },
    {
      name: 'Otros recibos',

      movements: [],
    },
    {
      name: 'Recibos e impuestos',
      subcategories: [
        {
          name: 'Seguros',
          movements: [
            {
              name:
                'Recibo Linea Directa Aseguradora S.a. Nº Recibo 0049 1770 755 Bbqhqps Ref. Mandato 18111128001',
              value: -363.72,
              last: '2021-01-19T23:00:00.000Z',
              subCategory: 'Seguros',
            },
            {
              name:
                'Transferencia A Favor De Romero Catalina Diego Concepto Seguro Cubas',
              value: -287.65,
              last: '2020-04-19T22:00:00.000Z',
              subCategory: 'Seguros',
            },
            {
              name:
                'Recibo Caja De Seguros Reunidos, S.a. Caser Nº Recibo 0049 1770 755 Bbnkgnn Ref. Mandato 29gb8000002',
              value: -377.84,
              last: '2020-02-23T23:00:00.000Z',
              subCategory: 'Seguros',
            },
            {
              name:
                'Recibo Almudena Seguros Nº Recibo 0049 1770 755 Bbqfmwb Ref. Mandato 218177598',
              value: -24.49,
              last: '2021-01-03T23:00:00.000Z',
              subCategory: 'Seguros',
            },
            {
              name:
                'Recibo Caja De Seguros Reunidos, S.a. Caser Nº Recibo 0049 1770 755 Bbqmtlz Ref. Mandato 29gb8000002',
              value: -383.17,
              last: '2021-02-22T23:00:00.000Z',
              subCategory: 'Seguros',
            },
            {
              last: '2021-08-02T22:00:00.000Z',
              name:
                'Recibo Prosegur Alar Espana, Nif:b87222006 Cto.502194909 Fra.e632104100316 Dest.nif:53444824t Fecha',
              value: -47.14,
              subCategory: 'Seguros',
            },

            {
              last: '2021-03-31T22:00:00.000Z',
              name:
                'Recibo Almudena Seguros, Pol.2181775 Decesosperiodo:01/04/2021 A 30/06/2021 Asistencia 24h.901242526',
              value: -24.49,
              subCategory: 'Seguros',
            },

            {
              last: '2022-02-28T22:00:00.000Z',
              name: 'Transferencia De Linea Directa Aseguradora S.a., .',
              value: -166.94,
              subCategory: 'Seguros',
            },
            {
              last: '2022-01-20T22:00:00.000Z',
              name:
                'Recibo Linea Directa Aseguradora S.a. Nº Recibo 0049 1770 755 Bbspwzw Ref. Mandato 18111128001, De',
              value: -363.81,
              subCategory: 'Seguros',
            },
            {
              last: '2022-04-01T22:00:00.000Z',
              name:
                'Recibo Almudena Seguros Pol.2181775 Decesosperiodo:01/04/2022 A 30/06/2022 Asistencia 24h.901242526a',
              value: -24.95,
              subCategory: 'Seguros',
            },
          ],
        },
        {
          name: 'Suministros',
          movements: [
            {
              name:
                'Recibo Telefonica De Espana Sau, Fijoxxxxxx058.mar, Nº Recibo 0049 1770 755 Bbqqghg Ref. Mandato X00',
              value: -38,
              last: '2021-03-03T23:00:00.000Z',
              subCategory: 'Suministros',
            },
            {
              name:
                'Recibo Endesa Energia Sau Nº Recibo 0049 1770 755 Bbnbzfb Ref. Mandato E00020716106272000070001',
              value: -7.72,
              last: '2020-01-01T23:00:00.000Z',
              subCategory: 'Suministros',
            },
            {
              name:
                'Recibo Iberdrola Clientes, S.a.u Nº Recibo 0049 1770 755 Bbqmclv Ref. Mandato 294212970000',
              value: -633.64,
              last: '2021-02-17T23:00:00.000Z',
              subCategory: 'Suministros',
            },
            {
              last: '2021-04-20T22:00:00.000Z',
              name:
                'Recibo Iberdrola Clientes, S.a.u, Iberdrola Gas 1023620690 Boadilla Del Monte, Cl Cabo De Gata 8,ba,',
              value: -350.71,
              subCategory: 'Suministros',
            },
            {
              last: '2021-08-03T22:00:00.000Z',
              name:
                'Recibo Telefonica De Espana Sau, Fijoxxxxxx058.may, Nº Recibo 0049 1770 755 Bbqywld Ref. Mandato X00',
              value: -38,
              subCategory: 'Suministros',
            },
            {
              last: '2021-10-04T22:00:00.000Z',
              name:
                'Recibo Telefonica De Espana Sau, Fijoxxxxxx058.jul, Nº Recibo 0049 1770 755 Bbrkgpr Ref. Mandato X00',
              value: -38,
              subCategory: 'Suministros',
            },
            {
              last: '2022-03-03T22:00:00.000Z',
              name:
                'Recibo Telefonica De Espana Sau Fijoxxxxxx058.mar Nº Recibo 0049 1770 755 Bbsxqqj Ref. Mandato X0060',
              value: -38,
              subCategory: 'Suministros',
            },
            {
              last: '2022-02-04T22:00:00.000Z',
              name:
                'Recibo Telefonica De Espana Sau Fijoxxxxxx058.feb Nº Recibo 0049 1770 755 Bbssrlb Ref. Mandato X0060',
              value: -38,
              subCategory: 'Suministros',
            },
            {
              last: '2022-01-04T22:00:00.000Z',
              name:
                'Recibo Telefonica De Espana Sau Fijoxxxxxx058.ene Nº Recibo 0049 1770 755 Bbsmyyr Ref. Mandato X0060',
              value: -38,
              subCategory: 'Suministros',
            },
            {
              last: '2022-04-04T22:00:00.000Z',
              name:
                'Recibo Telefonica De Espana Sau Fijoxxxxxx058.abr Nº Recibo 0049 1770 755 Bbtcjhq Ref. Mandato X0060',
              value: -38,
              subCategory: 'Suministros',
            },
          ],
        },
        {
          name: 'Impuestos',
          movements: [
            {
              name:
                'Compra Internet En Pago Tributos, Barcelona, Tarj. :*593924',
              value: -100,
              last: '2021-01-07T23:00:00.000Z',
              subCategory: 'Impuestos',
            },
            {
              name:
                'Transferencia A Favor De Ayuntamiento Cubas De La Sagra Concepto Urbana 2019 Romero Catalina Diego',
              value: -682.47,
              last: '2020-10-26T23:00:00.000Z',
              subCategory: 'Impuestos',
            },
            {
              name:
                'Pago Recibo De Ay. Torrejon De Ardoz (281487), Referencia 000025044350',
              value: -8.84,
              last: '2020-10-04T22:00:00.000Z',
              subCategory: 'Impuestos',
            },
            {
              last: '2021-09-29T22:00:00.000Z',
              name:
                'Recibo Ayuntamiento De Boadilla Del Monte, Ayto Boadilla Importe Plazo 1/2 Ibi 511,36 Romero Catalin',
              value: -511.36,
              subCategory: 'Impuestos',
            },
          ],
        },
      ],
    },
    {
      name: 'Hipotecas y préstamos',
      movements: [
        {
          name: 'Liquidacion Periodica Prestamo 0049 1770 103 0008214',
          value: -1036.37,
          last: '2021-03-07T23:00:00.000Z',
        },
        {
          name: 'Liquidacion Periodica Prestamo 0049 1770 103 0003293',
          value: -412.28,
          last: '2021-03-27T23:00:00.000Z',
        },
        {
          name: 'Liquidacion Periodica Prestamo 0049 5770 103 0004606',
          value: -466.25,
          last: '2021-02-25T23:00:00.000Z',
        },
        {
          last: '2021-04-29T22:00:00.000Z',
          name: 'Liquidacion Periodica Prestamo 0049 1770 103 0003293',
          value: -412.28,
        },
        {
          last: '2021-04-25T22:00:00.000Z',
          name: 'Liquidacion Periodica Prestamo 0049 5770 103 0004606',
          value: -466.25,
        },
        {
          last: '2021-09-25T22:00:00.000Z',
          name: 'Liquidacion Periodica Prestamo 0049 5770 103 0004606',
          value: -466.25,
        },
        {
          last: '2022-03-08T22:00:00.000Z',
          name: 'Liquidacion Periodica Prestamo 0049 5770 103 0023980',
          value: -1036.37,
        },
        {
          last: '2022-03-07T22:00:00.000Z',
          name:
            'Recibo Banco Cetelem, S.a. Recibo Emitido Por Banco Cetelem S.a. Cetelem Marzo Nº Recibo 0049 1770 7',
          value: -116.67,
        },
        {
          last: '2022-02-28T22:00:00.000Z',
          name: 'Liquidacion Periodica Prestamo 0049 5770 103 0004606',
          value: -466.25,
        },
        {
          last: '2022-02-11T22:00:00.000Z',
          name: 'Cuota Impagada Prestamo 0049 5770 103 0023980',
          value: -171.02,
        },
        {
          last: '2022-01-26T22:00:00.000Z',
          name: 'Liquidacion Periodica Prestamo 0049 5770 103 0004606',
          value: -466.25,
        },
        {
          last: '2022-04-08T22:00:00.000Z',
          name: 'Liquidacion Periodica Prestamo 0049 5770 103 0023980',
          value: -1036.37,
        },
        {
          last: '2022-04-05T22:00:00.000Z',
          name:
            'Recibo Banco Cetelem, S.a. Recibo Emitido Por Banco Cetelem S.a. Cetelem Abril Nº Recibo 0049 1770 7',
          value: -116.67,
        },
      ],
    },
    {
      name: 'Comunidad de vecinos',
      movements: [
        {
          name:
            'Recibo C P Fisterra I Nº Recibo 0049 1770 755 Bbqpbrw Ref. Mandato 087000300000',
          value: -54,
          last: '2021-02-28T23:00:00.000Z',
        },
        {
          last: '2021-08-02T22:00:00.000Z',
          name:
            'Recibo C P Fisterra I Nº Recibo 0049 1770 755 Bbqyfkm Ref. Mandato 087000300000',
          value: -54,
        },
        {
          last: '2021-09-30T22:00:00.000Z',
          name:
            'Recibo C P Fisterra I Nº Recibo 0049 1770 755 Bbrjhrw Ref. Mandato 087000300000',
          value: -54,
        },
        {
          last: '2022-03-01T22:00:00.000Z',
          name:
            'Recibo C P Fisterra I Nº Recibo 0049 1770 755 Bbswqvd Ref. Mandato 00870000030000000003661, De',
          value: -54,
        },
        {
          last: '2022-02-01T22:00:00.000Z',
          name:
            'Recibo C P Fisterra I Nº Recibo 0049 1770 755 Bbsrsjh Ref. Mandato 00870000030000000003661, De',
          value: -54,
        },
        {
          last: '2022-01-03T22:00:00.000Z',
          name:
            'Recibo C P Fisterra I Nº Recibo 0049 1770 755 Bbsrsjh Ref. Mandato 00870000030000000003661, De',
          value: -54,
        },
        {
          last: '2022-04-01T22:00:00.000Z',
          name:
            'Recibo C P Fisterra I Nº Recibo 0049 1770 755 Bbtbzrp Ref. Mandato 00870000030000000003661, De',
          value: -54,
        },
      ],
    },
    {
      name: 'Suscripciones y otros recibos',
      movements: [
        {
          name:
            'Recibo Casvi Boadilla Nº Recibo 0049 1770 755 Bbqnsmk Ref. Mandato Ca0010002316',
          value: -58,
          last: '2021-02-28T23:00:00.000Z',
        },
        {
          name:
            'Recibo Prosegur Alar Espana, Nif:b87222006 Cto.502194909 Fra.e632102091177 Dest.nif:53444824t Fecha',
          value: -47.14,
          last: '2021-02-28T23:00:00.000Z',
        },
        {
          last: '2021-08-03T22:00:00.000Z',
          name: 'Devolucion Transferencia De Mario Romero, Concepto Spotify',
          value: 5,
        },

        {
          last: '2021-08-02T22:00:00.000Z',
          name: 'Transferencia A Favor De Mario Romero Concepto: Spotify',
          value: -5,
        },
        {
          last: '2021-03-31T22:00:00.000Z',
          name: 'Transferencia A Favor De Mario Romero Concepto: Spotify',
          value: -5,
        },
        {
          last: '2022-03-01T22:00:00.000Z',
          name: 'Transferencia A Favor De Mario Romero Concepto: Spotify',
          value: -5,
        },
        {
          last: '2022-02-251T22:00:00.000Z',
          name:
            'Compra Apple.com/bill, 900812703, Tarjeta 5489017844593924 , Comision 0,00',
          value: -15.99,
        },
        {
          last: '2022-01-251T22:00:00.000Z',
          name:
            'Compra Apple.com/bill, 900812703, Tarjeta 5489017844593924 , Comision 0,00',
          value: -15.99,
        },
        {
          last: '2022-04-01T22:00:00.000Z',
          name: 'Transferencia A Favor De Mario Romero Concepto: Spotify',
          value: -5,
        },
      ],
    },
    {
      name: 'Otros gastos',
      movements: [
        {
          name:
            'Bizum A Favor De Laura Garcia Sanchez Barb Concepto Sin Concepto',
          value: -100,
          last: '2021-02-13T23:00:00.000Z',
        },
        {
          name:
            'Transferencia A Favor De Montajes Caldera Calderas Sl Concepto Pago 20-0000000630',
          value: -1983.35,
          last: '2021-01-07T23:00:00.000Z',
        },
        {
          name:
            'Compra Pago Móvil En Bricomart Alcor, Alcorcon, Tarj. :*107003',
          value: -8.71,
          last: '2021-02-19T23:00:00.000Z',
        },
        {
          name:
            'Compra Pago Móvil En Ikea Alcorcon R, Alcorcon, Tarj. :*107003',
          value: -32,
          last: '2021-02-19T23:00:00.000Z',
        },
        {
          name:
            'Devolucion Compra En Ikea Alcorcon R, Alcorcon, Tarj. :*107003',
          value: 98,
          last: '2021-02-19T23:00:00.000Z',
        },
        {
          last: '2021-03-31T22:00:00.000Z',
          name:
            'Compra Pago Móvil En Ikea Alcorcon R, Alcorcon, Tarj. :*383705',
          value: -94,
        },
        {
          last: '2021-03-31T22:00:00.000Z',
          name:
            'Compra Pago Móvil En Bricolaje Brico, Majadahonda, Tarj. :*107003',
          value: -4.05,
        },
        {
          last: '2022-02-28T22:00:00.000Z',
          name:
            'Recibo Financiera El Corte Ingles E.f.c., S.a. Ecitc0154755177 Nº Recibo 0049 1770 755 Bbsvwwr Ref.',
          value: -34.95,
        },
        {
          last: '2022-01-31T22:00:00.000Z',
          name:
            'Recibo Financiera El Corte Ingles E.f.c., S.a. Ecitc0154755177 Nº Recibo 0049 1770 755 Bbsqysn Ref.',
          value: -313.12,
        },
        {
          last: '2022-04-08T22:00:00.000Z',
          name: 'Transferencia A Favor De José Luis Romero Concepto: Deuda',
          value: -950,
        },
      ],
    },
    {
      name: 'Comida',
      movements: [],
      subcategories: [
        {
          name: 'Supermercados',
          movements: [
            {
              name:
                'Compra Pago Móvil En Mercadona Avda, Boadilla Del, Tarj. :*107003',
              value: -14.93,
              last: '2021-01-04T23:00:00.000Z',
              subCategory: 'Supermercados',
            },
            {
              name:
                'Compra Pago Móvil En Supercor Boadil, Boadilla Del, Tarj. :*107003',
              value: -43.63,
              last: '2021-01-12T23:00:00.000Z',
              subCategory: 'Supermercados',
            },
            {
              name:
                'Compra Carrefour Majad, Majadahonda, Tarjeta 5489019197383705 , Comision 0,00',
              value: -18.36,
              last: '2021-02-20T23:00:00.000Z',
              subCategory: 'Supermercados',
            },
            {
              name:
                'Compra Pago Móvil En Denominacion De, Las Rozas, Tarj. :*107003',
              value: -87,
              last: '2021-02-13T23:00:00.000Z',
              subCategory: 'Supermercados',
            },
            {
              name:
                'Compra Pago Móvil En Mercadona Avda, Boadilla Del, Tarj. :*383705',
              value: -91.02,
              last: '2021-01-28T23:00:00.000Z',
              subCategory: 'Supermercados',
            },
            {
              name:
                'Compra Pago Móvil En La Plaza, Boadilla Del, Tarj. :*107003',
              value: -40.28,
              last: '2021-01-22T23:00:00.000Z',
              subCategory: 'Supermercados',
            },
            {
              name:
                'Compra Market Boadilla, Boadilla Del, Tarjeta 5489019197383705 , Comision 0,00',
              value: -40.59,
              last: '2021-01-03T23:00:00.000Z',
              subCategory: 'Supermercados',
            },
            {
              name:
                'Compra Carrefour Majad, Majadahonda, Tarjeta 5489017844593924 , Comision 0,00',
              value: -13.02,
              last: '2021-01-21T23:00:00.000Z',
              subCategory: 'Supermercados',
            },
            {
              name:
                'Compra Pago Móvil En Supercor Boadil, Boadilla Del, Tarj. :*593924',
              value: -57.37,
              last: '2021-01-07T23:00:00.000Z',
              subCategory: 'Supermercados',
            },
            {
              name:
                'Compra Pago Móvil En Mercadona El Bo, Villaviciosa, Tarj. :*383705',
              value: -144.26,
              last: '2021-01-03T23:00:00.000Z',
              subCategory: 'Supermercados',
            },
            {
              last: '2021-04-30T22:00:00.000Z',
              name:
                'Compra Pago Móvil En El Obrador De L, Boadilla Del, Tarj. :*383705',
              value: -14.1,
              subCategory: 'Supermercados',
            },
            {
              last: '2021-04-29T22:00:00.000Z',
              name:
                'Compra Pago Móvil En Mercadona Avda, Boadilla Del, Tarj. :*383705',
              value: -115.03,
              subCategory: 'Supermercados',
            },
            {
              last: '2021-04-09T22:00:00.000Z',
              name:
                'Compra Pago Móvil En Mercadona Avda, Boadilla Del, Tarj. :*383705',
              value: -75.88,
              subCategory: 'Supermercados',
            },
            {
              last: '2021-10-01T22:00:00.000Z',
              name:
                'Compra Pago Móvil En Supercor Boadil, Boadilla Del, Tarj. :*383705',
              value: -22.43,
              subCategory: 'Supermercados',
            },
            {
              last: '2021-10-01T22:00:00.000Z',
              name:
                'Compra Pago Móvil En El Corte Ingles, Pozuelo, Tarj. :*107003',
              value: -6.99,
              subCategory: 'Supermercados',
            },
            {
              last: '2021-09-27T22:00:00.000Z',
              name:
                'Compra Pago Móvil En Mercadona Avda, Boadilla Del, Tarj. :*383705',
              value: -58.63,
              subCategory: 'Supermercados',
            },
            {
              last: '2022-02-25T22:00:00.000Z',
              name:
                'Pago Movil En Mercadona Avda, Boadilla Del, Tarj. :*107003',
              value: -114,
              subCategory: 'Supermercados',
            },
            {
              last: '2022-01-28T22:00:00.000Z',
              name:
                'Compra Pago Móvil En Mercadona Avda, Boadilla Del, Tarj. :*383705',
              value: -90.63,
              subCategory: 'Supermercados',
            },
            {
              last: '2022-04-11T22:00:00.000Z',
              name:
                'Pago Movil En Mercadona Avda, Boadilla Del, Tarj. :*383705',
              value: -89.47,
              subCategory: 'Supermercados',
            },
          ],
        },
        {
          name: 'Restaurantes',
          movements: [
            {
              name:
                'Compra Deliveroo, Madrid, Tarjeta 5489019306107003 , Comision 0,00',
              value: -39.4,
              last: '2021-02-25T23:00:00.000Z',
              subCategory: 'Restaurantes',
            },
            {
              name:
                'Compra Pago Móvil En Aqua E Farina, Boadlla Del M, Tarj. :*107003',
              value: -77,
              last: '2021-02-27T23:00:00.000Z',
              subCategory: 'Restaurantes',
            },
            {
              name: 'Compra En Chacabuco Deibe, Torrejon De A, Tarj. :*107003',
              value: -57.5,
              last: '2021-02-12T23:00:00.000Z',
              subCategory: 'Restaurantes',
            },
            {
              name:
                'Compra Pago Móvil En Volapie Boadill, Residencia Nt, Tarj. :*107003',
              value: -66,
              last: '2021-01-29T23:00:00.000Z',
              subCategory: 'Restaurantes',
            },
            {
              name:
                'Compra Burguer King Boadilla, Boadilla Del, Tarjeta 5489017844593924 , Comision 0,00',
              value: -17.05,
              last: '2021-03-05T23:00:00.000Z',
              subCategory: 'Restaurantes',
            },
            {
              name:
                'Compra Pago Móvil En Restaurante La, Majadahonda, Tarj. :*593924',
              value: -107,
              last: '2021-02-26T23:00:00.000Z',
              subCategory: 'Restaurantes',
            },
            {
              name: 'Compra Pago Móvil En Txistu, Madrid, Tarj. :*593924',
              value: -240,
              last: '2021-02-17T23:00:00.000Z',
              subCategory: 'Restaurantes',
            },
            {
              name:
                'Compra Pago Móvil En Bar Donde Dani, Boadilla Mont, Tarj. :*593924',
              value: -11,
              last: '2021-01-22T23:00:00.000Z',
              subCategory: 'Restaurantes',
            },
            {
              name:
                'Compra Pago Móvil En Bar Donde Dani, Boadilla Del, Tarj. :*593924',
              value: -18,
              last: '2021-01-07T23:00:00.000Z',
              subCategory: 'Restaurantes',
            },
            {
              name:
                'Compra Pago Móvil En Volapie Boadill, Residencia Nt, Tarj. :*593924',
              value: -68,
              last: '2021-01-06T23:00:00.000Z',
              subCategory: 'Restaurantes',
            },
            {
              name:
                'Compra Pago Móvil En Denominacion De, Las Rozas, Tarj. :*107003',
              value: -80,
              last: '2021-01-04T23:00:00.000Z',
              subCategory: 'Restaurantes',
            },
            {
              name:
                'Compra Pago Móvil En Mercadona El Bo, Villaviciosa, Tarj. :*107003',
              value: -75.48,
              last: '2021-01-21T23:00:00.000Z',
              subCategory: 'Restaurantes',
            },
            {
              last: '2021-04-15T22:00:00.000Z',
              name:
                'Compra Burguer King Boadilla, Boadilla Del, Tarjeta 5489017844593924 , Comision 0,00',
              value: -14.27,
              subCategory: 'Restaurantes',
            },
            {
              last: '2021-10-01T22:00:00.000Z',
              name:
                'Compra Pago Móvil En Pintxaki Boadil, Boadilla Del, Tarj. :*107003',
              value: -33,
              subCategory: 'Restaurantes',
            },
            {
              last: '2021-09-29T22:00:00.000Z',
              name:
                'Compra Pago Móvil En Lobito Madrid, Madrid, Tarj. :*107003',
              value: -136,
              subCategory: 'Restaurantes',
            },
            {
              last: '2021-04-04T22:00:00.000Z',
              name:
                'Transaccion Contactless En Bar Donde Dani, Boadilla Mont, Tarj. :*107003',
              value: -17.5,
              subCategory: 'Restaurantes',
            },
          ],
        },
      ],
    },
  ],
};

export const mockDataTransporte = {
  img: Transport,
  imgColor: '#eed3c3',
  name: 'Transporte',
  id: 2,
  categories: [
    {
      name: 'Gasolina',

      movements: [
        {
          name:
            'Compra Pago Móvil En Multiservicios, Boadilla Del, Tarj. :*107003',
          value: -30,
          last: '2021-03-05T23:00:00.000Z',
        },
        {
          name:
            'Compra Pago Móvil En E0281.boadilla-, Residencia Nt, Tarj. :*107003',
          value: -30,
          last: '2021-01-26T23:00:00.000Z',
        },
        {
          name:
            'Compra Internet En E.s. Gestion In, Majadahonda, Tarj. :*107003',
          value: -51.58,
          last: '2021-02-08T23:00:00.000Z',
        },
        {
          name: 'Compra Pago Móvil En Pozuelo H., Pozuelo, Tarj. :*107003',
          value: -43.68,
          last: '2021-02-27T23:00:00.000Z',
        },
        {
          name:
            'Compra Pago Móvil En E0281.boadilla-, Residencia Nt, Tarj. :*383705',
          value: -29.6,
          last: '2021-02-04T23:00:00.000Z',
        },
        {
          name:
            'Compra Pago Móvil En E0149 Boadilla, Residencia Nt, Tarj. :*107003',
          value: -20,
          last: '2021-01-16T23:00:00.000Z',
        },
        {
          name:
            'Compra Internet En E.s. La Nueva Y, La Guardia, Tarj. :*107003',
          value: -43.68,
          last: '2021-01-02T23:00:00.000Z',
        },
        {
          last: '2021-04-27T22:00:00.000Z',
          name:
            'Compra Pago Móvil En E0281.boadilla-, Residencia Nt, Tarj. :*107003',
          value: -30,
        },
        // invented for may
        {
          last: '2021-08-09T22:00:00.000Z',
          name:
            'Compra Pago Móvil En E0281.boadilla-, Residencia Nt, Tarj. :*107003',
          value: -30,
        },
        {
          last: '2021-10-04T22:00:00.000Z',
          name:
            'Compra Pago Móvil En E0281.boadilla-, Residencia Nt, Tarj. :*107003',
          value: -25.37,
        },
        {
          last: '2021-10-02T22:00:00.000Z',
          name:
            'Compra Pago Móvil En E3039 Torrejon, Torrejon De A, Tarj. :*107003',
          value: -59.86,
        },
        {
          last: '2021-09-24T22:00:00.000Z',
          name: 'Compra Internet En E.s.inv.sanrofe, Valdemoro, Tarj. :*107003',
          value: -35.14,
        },
        {
          last: '2021-09-23T22:00:00.000Z',
          name:
            'Compra Pago Móvil En E0281.boadilla-, Residencia Nt, Tarj. :*107003',
          value: -40.42,
        },
        {
          last: '2022-02-21T22:00:00.000Z',
          name:
            'Compra Internet En Dp Infante Virt, Valdecaba/as, Tarj. :*107003',
          value: -22.34,
        },
        {
          last: '2022-03-15T22:00:00.000Z',
          name:
            'Compra Internet En E.s. Gestion In, Majadahonda, Tarj. :*593924',
          value: -78.21,
        },
        {
          last: '2022-04-01T22:00:00.000Z',
          name: 'Pago Movil En E0281.boadilla-, Residencia Nt, Tarj. :*593924',
          value: -50,
        },
      ],
    },
    {
      name: 'Financiación',

      movements: [],
    },
    {
      name: 'Seguros e impuestos',

      movements: [],
    },
    {
      name: 'Otros gastos',

      movements: [
        {
          name: 'Compra Telefonica En E-park, Barcelona, Tarj. :*593924',
          value: -1,
          last: '2021-01-26T23:00:00.000Z',
        },
        {
          name: 'Compra Pago Móvil En Pkcojaen, Jaen, Tarj. :*107003',
          value: -2.45,
          last: '2022-02-07T23:00:00.000Z',
        },
      ],
    },

    {
      name: 'Mantenimiento',

      movements: [],
    },
    {
      name: 'Garaje',

      movements: [
        {
          name: 'Compra En Cesigral Aparca, Madrid, Tarj. :*107003',
          value: -2.95,
          last: '2022-01-25T23:00:00.000Z',
        },
        {
          name: 'Compra En Cesigral Aparca, Madrid, Tarj. :*107003',
          value: -8.25,
          last: '2022-02-02T23:00:00.000Z',
        },
        {
          name: 'Transaccion Contactless En Parking, Madrid, Tarj. :*593924',
          value: -15.1,
          last: '2022-03-10T23:00:00.000Z',
        },
        {
          name: 'Compra En Cesigral Aparca, Madrid, Tarj. :*107003',
          value: -18.65,
          last: '2022-04-01T23:00:00.000Z',
        },
      ],
    },
  ],
};
export const mockDataEstudios = {
  img: Study,
  imgColor: '#c7d7ee',
  name: 'Estudios',
  id: 3,
  categories: [
    {
      name: 'Financiación',

      movements: [],
    },
    {
      name: 'Recibos',

      movements: [],
    },
    {
      name: 'Alimentación',

      movements: [],
      // subcategories: [
      //   {
      //     name: "Supermercados",
      //     movements: [
      //       {
      //         name:
      //           "Compra Pago Móvil En Mercadona Avda, Boadilla Del, Tarj. :*107003",
      //         value: -14.93,
      //         last: "2021-01-04T23:00:00.000Z",
      //       },
      //       {
      //         name:
      //           "Compra Pago Móvil En Supercor Boadil, Boadilla Del, Tarj. :*107003",
      //         value: -43.63,
      //         last: "2021-01-12T23:00:00.000Z",
      //       },
      //       {
      //         name:
      //           "Compra Carrefour Majad, Majadahonda, Tarjeta 5489019197383705 , Comision 0,00",
      //         value: -18.36,
      //         last: "2021-02-20T23:00:00.000Z",
      //       },
      //       {
      //         name:
      //           "Compra Pago Móvil En Denominacion De, Las Rozas, Tarj. :*107003",
      //         value: -87,
      //         last: "2021-02-13T23:00:00.000Z",
      //       },
      //       {
      //         name:
      //           "Compra Pago Móvil En Mercadona Avda, Boadilla Del, Tarj. :*383705",
      //         value: -91.02,
      //         last: "2021-01-28T23:00:00.000Z",
      //       },
      //       {
      //         name:
      //           "Compra Pago Móvil En La Plaza, Boadilla Del, Tarj. :*107003",
      //         value: -40.28,
      //         last: "2021-01-22T23:00:00.000Z",
      //       },
      //       {
      //         name:
      //           "Compra Market Boadilla, Boadilla Del, Tarjeta 5489019197383705 , Comision 0,00",
      //         value: -40.59,
      //         last: "2021-01-03T23:00:00.000Z",
      //       },
      //       {
      //         name:
      //           "Compra Carrefour Majad, Majadahonda, Tarjeta 5489017844593924 , Comision 0,00",
      //         value: -13.02,
      //         last: "2021-01-21T23:00:00.000Z",
      //       },
      //       {
      //         name:
      //           "Compra Pago Móvil En Supercor Boadil, Boadilla Del, Tarj. :*593924",
      //         value: -57.37,
      //         last: "2021-01-07T23:00:00.000Z",
      //       },
      //       {
      //         name:
      //           "Compra Pago Móvil En Mercadona El Bo, Villaviciosa, Tarj. :*383705",
      //         value: -144.26,
      //         last: "2021-01-03T23:00:00.000Z",
      //       },
      //     ],
      //   },
      //   {
      //     name: "Restaurantes",
      //     movements: [
      //       {
      //         name:
      //           "Compra Deliveroo, Madrid, Tarjeta 5489019306107003 , Comision 0,00",
      //         value: -39.4,
      //         last: "2021-02-25T23:00:00.000Z",
      //       },
      //       {
      //         name:
      //           "Compra Pago Móvil En Aqua E Farina, Boadlla Del M, Tarj. :*107003",
      //         value: -77,
      //         last: "2021-02-27T23:00:00.000Z",
      //       },
      //       {
      //         name: "Compra En Chacabuco Deibe, Torrejon De A, Tarj. :*107003",
      //         value: -57.5,
      //         last: "2021-02-12T23:00:00.000Z",
      //       },
      //       {
      //         name:
      //           "Compra Pago Móvil En Volapie Boadill, Residencia Nt, Tarj. :*107003",
      //         value: -66,
      //         last: "2021-01-29T23:00:00.000Z",
      //       },
      //       {
      //         name:
      //           "Compra Burguer King Boadilla, Boadilla Del, Tarjeta 5489017844593924 , Comision 0,00",
      //         value: -17.05,
      //         last: "2021-03-05T23:00:00.000Z",
      //       },
      //       {
      //         name:
      //           "Compra Pago Móvil En Restaurante La, Majadahonda, Tarj. :*593924",
      //         value: -107,
      //         last: "2021-02-26T23:00:00.000Z",
      //       },
      //       {
      //         name: "Compra Pago Móvil En Txistu, Madrid, Tarj. :*593924",
      //         value: -240,
      //         last: "2021-02-17T23:00:00.000Z",
      //       },
      //       {
      //         name:
      //           "Compra Pago Móvil En Bar Donde Dani, Boadilla Mont, Tarj. :*593924",
      //         value: -11,
      //         last: "2021-01-22T23:00:00.000Z",
      //       },
      //       {
      //         name:
      //           "Compra Pago Móvil En Bar Donde Dani, Boadilla Del, Tarj. :*593924",
      //         value: -18,
      //         last: "2021-01-07T23:00:00.000Z",
      //       },
      //       {
      //         name:
      //           "Compra Pago Móvil En Volapie Boadill, Residencia Nt, Tarj. :*593924",
      //         value: -68,
      //         last: "2021-01-06T23:00:00.000Z",
      //       },
      //       {
      //         name:
      //           "Compra Pago Móvil En Denominacion De, Las Rozas, Tarj. :*107003",
      //         value: -80,
      //         last: "2021-01-04T23:00:00.000Z",
      //       },
      //       {
      //         name:
      //           "Compra Pago Móvil En Mercadona El Bo, Villaviciosa, Tarj. :*107003",
      //         value: -75.48,
      //         last: "2021-01-21T23:00:00.000Z",
      //       },
      //     ],
      //   },
      // ],
    },
    {
      name: 'Transporte',

      movements: [],
    },
    {
      name: 'Vivienda',

      movements: [],
    },
    {
      name: 'Otros gastos',

      movements: [],
    },
  ],
};
export const mockDataEstudios2 = {
  img: Study,
  imgColor: '#c7d7ee',
  name: 'Estudios',
  id: 3,
  categories: [
    {
      name: 'Financiación',

      movements: [],
    },
    {
      name: 'Recibos',

      movements: [
        {
          last: '2021-03-31T22:00:00.000Z',
          name:
            'Recibo Casvi Boadilla Nº Recibo 0049 1770 755 Bbqsslj Ref. Mandato Ca0010002316',
          value: -18.17,
        },
      ],
    },
    {
      name: 'Alimentación',

      movements: [
        {
          name:
            'Compra Pago Móvil En Mercadona Avda, Boadilla Del, Tarj. :*107003',
          value: -14.93,
          last: '2021-01-04T23:00:00.000Z',
          subCategory: 'Supermercados',
        },
        {
          name:
            'Compra Pago Móvil En Supercor Boadil, Boadilla Del, Tarj. :*107003',
          value: -43.63,
          last: '2021-01-12T23:00:00.000Z',
          subCategory: 'Supermercados',
        },
        {
          name:
            'Compra Carrefour Majad, Majadahonda, Tarjeta 5489019197383705 , Comision 0,00',
          value: -18.36,
          last: '2021-02-20T23:00:00.000Z',
          subCategory: 'Supermercados',
        },
        {
          name:
            'Compra Pago Móvil En Denominacion De, Las Rozas, Tarj. :*107003',
          value: -87,
          last: '2021-02-13T23:00:00.000Z',
          subCategory: 'Supermercados',
        },
        {
          name:
            'Compra Pago Móvil En Mercadona Avda, Boadilla Del, Tarj. :*383705',
          value: -91.02,
          last: '2021-01-28T23:00:00.000Z',
          subCategory: 'Supermercados',
        },
        {
          name: 'Compra Pago Móvil En La Plaza, Boadilla Del, Tarj. :*107003',
          value: -40.28,
          last: '2021-01-22T23:00:00.000Z',
          subCategory: 'Supermercados',
        },
        {
          name:
            'Compra Market Boadilla, Boadilla Del, Tarjeta 5489019197383705 , Comision 0,00',
          value: -40.59,
          last: '2021-01-03T23:00:00.000Z',
          subCategory: 'Supermercados',
        },
        {
          name:
            'Compra Carrefour Majad, Majadahonda, Tarjeta 5489017844593924 , Comision 0,00',
          value: -13.02,
          last: '2021-01-21T23:00:00.000Z',
          subCategory: 'Supermercados',
        },
        {
          name:
            'Compra Pago Móvil En Supercor Boadil, Boadilla Del, Tarj. :*593924',
          value: -57.37,
          last: '2021-01-07T23:00:00.000Z',
          subCategory: 'Supermercados',
        },
        {
          name:
            'Compra Pago Móvil En Mercadona El Bo, Villaviciosa, Tarj. :*383705',
          value: -144.26,
          last: '2021-01-03T23:00:00.000Z',
          subCategory: 'Supermercados',
        },

        {
          name:
            'Compra Deliveroo, Madrid, Tarjeta 5489019306107003 , Comision 0,00',
          value: -39.4,
          last: '2021-02-25T23:00:00.000Z',
          subCategory: 'Restaurantes',
        },
        {
          name:
            'Compra Pago Móvil En Aqua E Farina, Boadlla Del M, Tarj. :*107003',
          value: -77,
          last: '2021-02-27T23:00:00.000Z',
          subCategory: 'Restaurantes',
        },
        {
          name: 'Compra En Chacabuco Deibe, Torrejon De A, Tarj. :*107003',
          value: -57.5,
          last: '2021-02-12T23:00:00.000Z',
          subCategory: 'Restaurantes',
        },
        {
          name:
            'Compra Pago Móvil En Volapie Boadill, Residencia Nt, Tarj. :*107003',
          value: -66,
          last: '2021-01-29T23:00:00.000Z',
          subCategory: 'Restaurantes',
        },
        {
          name:
            'Compra Burguer King Boadilla, Boadilla Del, Tarjeta 5489017844593924 , Comision 0,00',
          value: -17.05,
          last: '2021-03-05T23:00:00.000Z',
          subCategory: 'Restaurantes',
        },
        {
          name:
            'Compra Pago Móvil En Restaurante La, Majadahonda, Tarj. :*593924',
          value: -107,
          last: '2021-02-26T23:00:00.000Z',
          subCategory: 'Restaurantes',
        },
        {
          name: 'Compra Pago Móvil En Txistu, Madrid, Tarj. :*593924',
          value: -240,
          last: '2021-02-17T23:00:00.000Z',
          subCategory: 'Restaurantes',
        },
        {
          name:
            'Compra Pago Móvil En Bar Donde Dani, Boadilla Mont, Tarj. :*593924',
          value: -11,
          last: '2021-01-22T23:00:00.000Z',
          subCategory: 'Restaurantes',
        },
        {
          name:
            'Compra Pago Móvil En Bar Donde Dani, Boadilla Del, Tarj. :*593924',
          value: -18,
          last: '2021-01-07T23:00:00.000Z',
          subCategory: 'Restaurantes',
        },
        {
          name:
            'Compra Pago Móvil En Volapie Boadill, Residencia Nt, Tarj. :*593924',
          value: -68,
          last: '2021-01-06T23:00:00.000Z',
          subCategory: 'Restaurantes',
        },
        {
          name:
            'Compra Pago Móvil En Denominacion De, Las Rozas, Tarj. :*107003',
          value: -80,
          last: '2021-01-04T23:00:00.000Z',
          subCategory: 'Restaurantes',
        },
        {
          name:
            'Compra Pago Móvil En Mercadona El Bo, Villaviciosa, Tarj. :*107003',
          value: -75.48,
          last: '2021-01-21T23:00:00.000Z',
          subCategory: 'Restaurantes',
        },
      ],
    },
    {
      name: 'Transporte',

      movements: [],
    },
    {
      name: 'Vivienda',

      movements: [],
    },
    {
      name: 'Otros gastos',

      movements: [],
    },
  ],
};
export const mockDataOcio = {
  img: Sport,
  imgColor: null,
  name: 'Ocio',
  id: 4,
  categories: [
    {
      name: 'Deporte',

      movements: [
        {
          name:
            'Transferencia A Favor De Club Deportivo Elemental Aúpa Concepto Valentina Romero Díaz Febrero Y Marz',
          value: -140,
          last: '2021-03-08T23:00:00.000Z',
        },
        {
          name:
            'Recibo Mentema Sport, S.l., Cuota Marzo Futbol, Nº Recibo 0049 1770 755 Bbqnswh Ref. Mandato 591',
          value: -45,
          last: '2021-02-28T23:00:00.000Z',
        },
        {
          name:
            'Compra Pago Móvil En Las Rejas Golf, Majadahonda, Tarj. :*593924',
          value: -7.5,
          last: '2021-03-05T23:00:00.000Z',
        },
        {
          name:
            'Compra Pago Móvil En Golf Santander, Boadilla Del, Tarj. :*593924',
          value: -72,
          last: '2021-01-03T23:00:00.000Z',
        },
        {
          name:
            'Recibo Real Fed Espanola De Golf Nº Recibo 0049 1770 755 Bbqdcly Ref. Mandato Cm15965526',
          value: -78.1,
          last: '2021-01-03T23:00:00.000Z',
        },
        {
          name:
            'Recibo Real Fed Espanola De Golf Nº Recibo 0049 1770 755 Bbnfldb Ref. Mandato Cm15965526',
          value: -78.1,
          last: '2020-01-26T23:00:00.000Z',
        },
        // new
        {
          last: '2021-08-06T22:00:00.000Z',
          name:
            'Compra Pago Móvil En Golf Santander, Boadilla Del, Tarj. :*593924',
          value: -45,
        },
        {
          last: '2021-04-29T22:00:00.000Z',
          name:
            'Compra Pago Móvil En Golf Santander, Boadilla Del, Tarj. :*593924',
          value: -45,
        },
        {
          last: '2021-04-22T22:00:00.000Z',
          name:
            'Compra Pago Móvil En Golf Santander, Boadilla Del, Tarj. :*593924',
          value: -90,
        },
        {
          last: '2021-04-15T22:00:00.000Z',
          name: 'Compra Internet En Tulotero, Madrid, Tarj. :*593924',
          value: -5,
        },
        {
          last: '2021-04-15T22:00:00.000Z',
          name:
            'Compra Pago Móvil En Golf Santander, Boadilla Del, Tarj. :*593924',
          value: -45,
        },
        {
          last: '2021-09-30T22:00:00.000Z',
          name: 'Compra Pago Móvil En Desprosa (golf, Madrid, Tarj. :*593924',
          value: -21,
        },
        {
          last: '2021-09-17T22:00:00.000Z',
          name:
            'Compra Pago Móvil En Golf Santander, Boadilla Del, Tarj. :*593924',
          value: -45,
        },
        {
          last: '2022-02-10T22:00:00.000Z',
          name:
            'Transferencia A Favor De Club Deportivo Elemental Aúpa Concepto: Valentina Romero Díaz Febrero 2 Cla',
          value: -45,
        },
        {
          last: '2022-01-24T22:00:00.000Z',
          name:
            'Transferencia A Favor De Club Deportivo Elemental Aúpa Concepto: Valentina Romero Díaz Enero',
          value: -90,
        },
        {
          last: '2022-03-21T22:00:00.000Z',
          name:
            'Transferencia A Favor De Club Deportivo Elemental Aúpa Concepto: Valentina Romero Diaz Marzo',
          value: -90,
        },
        {
          last: '2022-03-04T22:00:00.000Z',
          name:
            'Bizum A Favor De Jesus Maria Carmona Gonza Concepto: Golf Encin',
          value: -35,
        },
        {
          last: '2022-04-18T22:00:00.000Z',
          name:
            'Transferencia A Favor De Club Deportivo Elemental Aúpa Concepto: Valentina Romero Díaz 2 Clases Abri',
          value: -50,
        },
        {
          last: '2022-04-04T22:00:00.000Z',
          name: 'Pago Movil En Campo De Golf, Boadilla Del, Tarj. :*593924',
          value: -103,
        },
      ],
    },
    {
      name: 'Loterías y apuestas',

      movements: [
        {
          name: 'Compra Internet En Tulotero, Madrid, Tarj. :*593924',
          value: -5,
          last: '2021-02-28T23:00:00.000Z',
        },
        {
          last: '2021-04-29T22:00:00.000Z',
          name: 'Compra Internet En Tulotero, Madrid, Tarj. :*593924',
          value: -5,
        },
        {
          last: '2021-04-22T22:00:00.000Z',
          name: 'Compra Internet En Tulotero, Madrid, Tarj. :*593924',
          value: -5,
        },
        {
          last: '2021-04-08T22:00:00.000Z',
          name: 'Compra Internet En Tulotero, Madrid, Tarj. :*593924',
          value: -5,
        },
        {
          last: '2021-10-01T22:00:00.000Z',
          name: 'Compra Internet En Tulotero, Madrid, Tarj. :*593924',
          value: -5,
        },
        {
          last: '2021-09-24T22:00:00.000Z',
          name: 'Compra Internet En Tulotero, Madrid, Tarj. :*593924',
          value: -5,
        },
        {
          last: '2021-09-17T22:00:00.000Z',
          name: 'Compra Internet En Tulotero, Madrid, Tarj. :*593924',
          value: -5,
        },
        {
          last: '2022-02-14T22:00:00.000Z',
          name: 'Compra Internet En Tulotero, Madrid, Tarj. :*593924',
          value: -5,
        },
        {
          last: '2022-01-31T22:00:00.000Z',
          name: 'Compra Internet En Tulotero, Madrid, Tarj. :*593924',
          value: -5,
        },
        {
          last: '2022-03-23T22:00:00.000Z',
          name: 'Compra Internet En Tulotero, Madrid, Tarj. :*593924',
          value: -5,
        },
        {
          last: '2022-04-13T22:00:00.000Z',
          name: 'Compra Internet En Tulotero, Madrid, Tarj. :*593924',
          value: -5,
        },
      ],
    },
    {
      name: 'Restauración',

      movements: [
        {
          name:
            'Compra Deliveroo, Madrid, Tarjeta 5489019306107003 , Comision 0,00',
          value: -39.4,
          last: '2021-02-25T23:00:00.000Z',
        },
        {
          name:
            'Compra Pago Móvil En Aqua E Farina, Boadlla Del M, Tarj. :*107003',
          value: -77,
          last: '2021-02-27T23:00:00.000Z',
        },
        {
          name: 'Compra En Chacabuco Deibe, Torrejon De A, Tarj. :*107003',
          value: -57.5,
          last: '2021-02-12T23:00:00.000Z',
        },
        {
          name:
            'Compra Pago Móvil En Volapie Boadill, Residencia Nt, Tarj. :*107003',
          value: -66,
          last: '2021-01-29T23:00:00.000Z',
        },
        {
          name:
            'Compra Burguer King Boadilla, Boadilla Del, Tarjeta 5489017844593924 , Comision 0,00',
          value: -17.05,
          last: '2021-03-05T23:00:00.000Z',
        },
        {
          name:
            'Compra Pago Móvil En Restaurante La, Majadahonda, Tarj. :*593924',
          value: -107,
          last: '2021-02-26T23:00:00.000Z',
        },
        {
          name: 'Compra Pago Móvil En Txistu, Madrid, Tarj. :*593924',
          value: -240,
          last: '2021-02-17T23:00:00.000Z',
        },
        {
          name:
            'Compra Pago Móvil En Bar Donde Dani, Boadilla Mont, Tarj. :*593924',
          value: -11,
          last: '2021-01-22T23:00:00.000Z',
        },
        {
          name:
            'Compra Pago Móvil En Bar Donde Dani, Boadilla Del, Tarj. :*593924',
          value: -18,
          last: '2021-01-07T23:00:00.000Z',
        },
        {
          name:
            'Compra Pago Móvil En Volapie Boadill, Residencia Nt, Tarj. :*593924',
          value: -68,
          last: '2021-01-06T23:00:00.000Z',
        },
        {
          name:
            'Compra Pago Móvil En Denominacion De, Las Rozas, Tarj. :*107003',
          value: -80,
          last: '2021-01-04T23:00:00.000Z',
        },
        {
          name:
            'Compra Pago Móvil En Mercadona El Bo, Villaviciosa, Tarj. :*107003',
          value: -75.48,
          last: '2021-01-21T23:00:00.000Z',
        },
        {
          last: '2021-04-02T22:00:00.000Z',
          name:
            'Compra Pago Móvil En Denominacion De, Las Rozas, Tarj. :*593924',
          value: -119.15,
        },
        {
          last: '2022-02-28T22:00:00.000Z',
          name: 'Pago Movil En La Cañada Resta, Boadilla Mont, Tarj. :*107003',
          value: -127,
        },
        {
          last: '2022-01-24T22:00:00.000Z',
          name: 'Compra Pago Móvil En La Tacita, Madrid, Tarj. :*383705',
          value: -8,
        },
        {
          last: '2022-03-07T22:00:00.000Z',
          name: 'Pago Movil En Volapie Boadill, Residencia Nt, Tarj. :*107003',
          value: -64,
        },
        {
          last: '2022-04-11T22:00:00.000Z',
          name: 'Pago Movil En Denominacion De, Boadilla Mont, Tarj. :*107003',
          value: -66,
        },
      ],
    },
    {
      name: 'Plataformas audiovisuales',

      movements: [],
    },
    {
      name: 'Espectáculos y cultura',

      movements: [],
    },
    {
      name: 'Otros gastos',

      movements: [],
    },
  ],
};

export const pockets = [
  mockDataCasa,
  mockDataTransporte,
  mockDataEstudios,
  mockDataOcio,
];
export const pocketMovements = [
  mockDataCasa2,
  mockDataTransporte,
  mockDataEstudios2,
  mockDataOcio,
];
