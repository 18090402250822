import React, { useEffect, useState } from "react";

import {
  UiWrapper,
  NavigationWrapper,
  View,
  Text,
  PickerHOC,
  Layout,
} from "coinscrap-webapp-core";

import Footer from "../common/Footer";
import ButtonFooterDouble from "../common/ButtonFooterDouble";
import Steps from "../common/Steps";
import colors from "../../config/colors";
import routes from "../../config/routes";
import TVIcon from "../common/svgs/TVIcon.js";
import CreateExpense from "./components/CreateExpense";
import { ExpensesCheck } from "../common/ExpensesCheck";

export default PickerHOC()(() => {
  const [expenses, setExpenses] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);

  const { useBackground, useFooter } = UiWrapper.use();
  const { navigateTo, useBackRoute, query } = NavigationWrapper.use();

  const taxesList = [
    {
      name: "Spotify Limited",
      last: "1 Jul 2020",
      value: -14.99,
    },
    {
      name: "Prosegur Alar Espana Nº Recibo 0049…",
      last: "1 Jul 2020",
      value: -47.14,
    },
    {
      name: "Casvi Boadilla Nº Recibo 0049 1770…",
      last: "1 Jul 2020",
      value: -47.14,
    },
  ];

  useEffect(() => {
    setExpenses(query);
  }, [query]);

  useBackRoute(routes.expenseCommunity);

  useBackground(colors.white);

  useFooter(
    <Footer>
      <ButtonFooterDouble
        icon1
        // Pasar datos y añadir los expenses
        action2={() => navigateTo(routes.expenseFood)}
      />
    </Footer>,
    [expenses]
  );
  return (
    <Layout.Container>
      <View horizontal24St topSt>
        <Steps step={4} total={4} />
        <View width95St topSt style={{ marginBottom: 10 }}>
          <TVIcon />
          <Text font28St style={{ margin: "10px 0px" }}>
            Suscripciones y otros recibos
          </Text>
          <Text font16St>
            Hemos encontrado estos productos. Si lo necesitas puedes buscarlos
            manualmente o añadir los que quieras.
          </Text>
        </View>
      </View>
      <View
        flexSt
        startSt
        fullWidthSt
        fullHeightSt
        grayBc2St
        borderTop2St
        style={{ padding: "15px 16px 19px" }}
      >
        {taxesList.map((item, index) => {
          return (
            <ExpensesCheck
              item={item}
              index={index}
              data={expenses}
              setData={setExpenses}
            />
          );
        })}

        <CreateExpense
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
        />
      </View>
    </Layout.Container>
  );
});
