import React from "react";
import IconButton from "@material-ui/core/IconButton";

// import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import Clear from "@material-ui/icons/Clear";

const Icon = Clear;
export const CloseButton = ({ color, onClick, action, ...props }) => {
  return (
    <IconButton
      color={color || "primary"}
      onClick={action || onClick}
      style={{ color: color, margin: 0, padding: "12px 0" }}
    >
      <Icon />
    </IconButton>
  );
};
