import React, { useState } from "react";

import {
  UiWrapper,
  View,
  Text,
  Enfatize,
  PickerHOC,
  Layout,
  NavigationWrapper,
} from "coinscrap-webapp-core";

import routes from "../../config/routes";
import colors from "../../config/colors";

import ButtonFooter from "../common/ButtonFooter";
import Footer from "../common/Footer";
import Steps from "../common/Steps";
import Check from "../common/svgs/Check";

import CasaPlaya from "../../assets/images/casa_playa.png";
import Example from "../common/Example";
import { useSave } from "../../libs/hooks/useSave";

export default PickerHOC()(() => {
  // eslint-disable-next-line no-unused-vars
  const [imageUrl, setImageUrl] = useState(CasaPlaya);
  const [myData, saveMyData] = useSave();

  const { useFooter, useBackground } = UiWrapper.use();
  const { navigateTo } = NavigationWrapper.use();

  useBackground(colors.white);

  useFooter(
    <Footer noBorder whiteBc2St>
      <ButtonFooter
        fill
        text="Aceptar"
        action={async () => {
          await saveMyData({
            ...myData,
            pockets: [...(myData?.pockets || []), { ...myData.creation }],
            creation: null,
          });
          navigateTo(routes.home);
        }}
      />
    </Footer>
  );

  return (
    <Layout.Container>
      <View flexSt fullWidthSt whiteBc2St startSt>
        {/* <View horizontal24St selfStartSt>
          <Steps step={4} total={4} />
        </View> */}

        <View
          topImgSt
          style={{
            backgroundImage: `url(${myData?.creation?.img || CasaPlaya})`,
          }}
        ></View>

        <View startSt>
          <View topCircleSt></View>
          <View width80St style={{ marginTop: -85 }}>
            <View
              imgCircleSt
              style={{
                backgroundImage: `url(${myData?.creation?.img || CasaPlaya})`,
              }}
            >
              <Check
                style={{
                  borderRadius: "50%",
                  height: 50,
                  width: 50,
                  backgroundColor: "#63ba68",
                  padding: 10,
                  border: "3px solid white",
                  position: "absolute",
                  top: -9,
                  right: -20,
                }}
              />
            </View>

            <Text
              centerSt
              font28St
              boldSt
              style={{ lineHeight: "28px", marginTop: 31 }}
            >
              Pocket
            </Text>
            <Text centerSt font28St style={{ lineHeight: "28px" }}>
              creado con éxito
            </Text>
            <Text
              centerSt
              font16St
              regularSt
              style={{
                lineHeight: "24px",
                marginTop: 16,
                color: colors.grey2,
              }}
            >
              Ya puedes empezar a llevar el seguimiento de tus gastos.
            </Text>
          </View>
        </View>
      </View>
    </Layout.Container>
  );
});
