import React from "react";

export default function FlameGreyIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...props.style }}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.0377 0.811823C12.8228 0.508664 12.3861 0.491563 12.1462 0.760523C12.1283 0.780345 12.1121 0.801222 12.0975 0.822968C12.0345 0.912007 11.9272 1.06533 11.779 1.2801C11.5229 1.6514 11.2378 2.07134 10.931 2.53168C10.0553 3.8457 9.17988 5.22215 8.36236 6.59562C5.98581 10.5884 4.57153 13.7261 4.57153 15.6188C4.57153 19.9354 8.15627 23.428 12.5715 23.428C16.9868 23.428 20.5715 19.9354 20.5715 15.6188C20.5715 13.7256 19.1566 10.5869 16.779 6.59282C15.9613 5.21919 15.0859 3.84271 14.2102 2.52887C13.9036 2.06877 13.6187 1.64915 13.3627 1.27825C13.2085 1.05477 13.0987 0.897843 13.0377 0.811823ZM9.34442 7.18016C10.1511 5.82489 11.0164 4.46438 11.882 3.16546C12.1272 2.79745 12.3584 2.45572 12.5715 2.14466C12.7841 2.45488 13.0146 2.79567 13.2592 3.16268C14.1248 4.46143 14.9901 5.82196 15.797 7.1774C18.0598 10.9786 19.4287 14.0153 19.4287 15.6188C19.4287 19.2975 16.3622 22.2851 12.5715 22.2851C8.78089 22.2851 5.71439 19.2975 5.71439 15.6188C5.71439 14.0157 7.08262 10.9801 9.34442 7.18016Z"
        fill="#999999"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.5715 23.3967C15.2519 23.3967 17.4286 21.2584 17.4286 18.615C17.4286 17.7496 16.9768 16.6438 16.1734 15.3112C15.882 14.8278 15.5485 14.3231 15.1808 13.8036C14.7041 13.1301 14.1945 12.4653 13.6848 11.8374C13.3787 11.4602 13.1397 11.1782 13.0013 11.0202C12.7737 10.7603 12.3693 10.7603 12.1417 11.0202C12.0033 11.1782 11.7643 11.4602 11.4582 11.8374C10.9485 12.4653 10.4389 13.1301 9.96221 13.8036C9.59449 14.3231 9.26098 14.8278 8.96958 15.3112C8.16618 16.6438 7.71436 17.7496 7.71436 18.615C7.71436 21.2584 9.89113 23.3967 12.5715 23.3967ZM12.3455 12.5576C12.426 12.4585 12.5015 12.3663 12.5715 12.2816C12.6415 12.3663 12.717 12.4585 12.7975 12.5576C13.2923 13.1673 13.7871 13.8127 14.2479 14.4638C14.6004 14.9618 14.9187 15.4435 15.1947 15.9012C15.8973 17.0667 16.2858 18.0175 16.2858 18.615C16.2858 20.6226 14.6252 22.2538 12.5715 22.2538C10.5178 22.2538 8.85721 20.6226 8.85721 18.615C8.85721 18.0175 9.24571 17.0667 9.94834 15.9012C10.2243 15.4435 10.5426 14.9618 10.8951 14.4638C11.3559 13.8127 11.8507 13.1673 12.3455 12.5576Z"
        fill="#999999"
      />
    </svg>
  );
}
