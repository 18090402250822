import { UiWrapper } from "coinscrap-webapp-core";
import colors from "../colors";

UiWrapper.defineComponent("inputCheck", {
  styles: {
    default: {
      containerComponent: {
        width: "auto",
        flexDirection: "row",
      },
      labelComponent: {
        flexDirection: "row",
      },
      checkComponent: {
        color: "#CCCCCC",
        backgroundColor: colors.white,
        padding: 4,
      },
      checkedMode: {
        checkComponent: {
          color: colors.green,
          backgroundColor: colors.white,
          padding: 4,
        },
      },
    },
    
    noPaddingSt: {
      checkComponent: {
        padding: 0,
      },
      checkedMode: {
        checkComponent: {
          padding: 0,
        },
      },
    },
  },
});
