import React, { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import moment from "moment";
import {
  UiWrapper,
  NavigationWrapper,
  View,
  Text,
  PickerHOC,
  Layout,
  Enfatize,
  Button,
  Var,
  ImageSelector,
} from "coinscrap-webapp-core";
import { defaultImages } from "../common/defaultImages";

// import { ImageSelector } from "../../componentsBak/ImageSelector";
import { getCurrency } from "../../libs/Currency";

import { AddButton } from "../common/AddButton";
import SettingsIcon from "../common/svgs/SettingsIcon";
import MetaIcon from "../common/svgs/MetaIcon";

import colors from "../../config/colors";
import routes from "../../config/routes";

import MonthGraphic from "./components/MonthGraphic";
import CreateExpense from "../CreatePocket/components/CreateExpense";
import EditIcon from "../common/svgs/EditIcon";
import ProgressBar from "../common/ProgressBar";

import {
  pocketMovements,
  getCategoryIcon,
} from "../data/categories/categories";
import TooltipSimple from "../common/TooltipSimple";
import { useSave } from "../../libs/hooks/useSave";
import { CameraComposed } from "../common/CameraComposed";

// const colorListGraph = [
//   "#9EDDE2",
//   "#76D3BD",
//   "#69A9BB",
//   "#F49C45",
//   "#DEE3E6",
//   "#76BD93",
// ];
// const colorListGraphClick = [
//   "#9EDDE230",
//   "#76D3BD30",
//   "#69A9BB30",
//   "#F49C4530",
//   "#DEE3E630",
//   "#76BD9330",
// ];

export default PickerHOC()(({ noData }) => {
  // eslint-disable-next-line no-unused-vars
  const [localStorage, setLocalStorage] = useState(
    JSON.parse(global.localStorage.getItem("pocketData"))
  );
  const [myData, saveMyData] = useSave();

  const { selectedPocket } = localStorage;

  const [tab, setTab] = useState(null);
  // const [date, setDate] = useState(new Date(expensesList[2].date));
  const [imageUrl, setImageUrl] = useState("");
  // const [image] = useState(pocketDetails.image);
  const [visibleModal, setVisibleModal] = useState(false);
  const [name, setName] = useState(null);
  const [doughnutName, setDoughnutName] = useState(null);
  const [doughnutValue, setDoughnutValue] = useState(null);
  const [doughnutIndex, setDoughnutIndex] = useState(null);

  const [colorListGraph, setColorListGraph] = useState([]);
  const [colorListGraphClick, setColorListGraphClick] = useState([]);
  const [colorList, setColorList] = useState([]);
  const [pocketDetails, setPocketDetails] = useState(null);
  const [expensesList, setExpensesList] = useState(null);

  const { useBackground, windowDimensions } = UiWrapper.use();
  const { navigateTo, useBackRoute, query } = NavigationWrapper.use();

  const findPocket = (myData?.pockets || []).find(
    (pocket) => pocket.id === selectedPocket.id
  );

  useBackground(colors.white);
  useBackRoute(routes.home);

  const widthContainer = windowDimensions.width - 48;

  useEffect(() => {
    if (tab?.expenses?.length > 0) {
      const listColors = tab?.expenses.map((item) => {
        return getCategoryIcon(item.name)?.color;
      });
      const lightListColors = tab?.expenses.map((item) => {
        return getCategoryIcon(item.name)?.colorLight;
      });
      setColorListGraph(listColors);
      setColorListGraphClick(lightListColors);
      setColorList(listColors);
    }
  }, [tab]);

  useEffect(() => {
    const array = [];
    [...Array(3)].reduce((acc, curr) => {
      const object = {
        year: Number(moment().subtract(acc, "months").format("YYYY")),
        month: Number(moment().subtract(acc, "months").format("M")),
      };
      array.push(object);
      return acc + 1;
    }, 0);

    const monthsArray = array.reverse();

    let price = 0;
    findPocket.categories.forEach((item) => {
      let movementValue = 0;
      (item.movements || []).forEach((item2) => {
        movementValue = movementValue + item2.value;
      });

      price = price + movementValue;
    });

    const formatLast3Months = monthsArray.map((item) => {
      let price = 0;
      let expenseArray = [];
      findPocket.categories.forEach((category) => {
        let movementValue = 0;
        (category.movements || [])
          .filter((filterItem) => {
            return (
              moment(filterItem.last).format("YYYY M") ===
              `${item.year} ${item.month}`
            );
          })
          .forEach((item2) => {
            movementValue = movementValue + item2.value;
          });

        price = price + movementValue;
        expenseArray.push(category);
      });

      const expensesArray = findPocket.categories.map((category) => {
        const categoryExpense = category.movements
          .filter((filterItem) => {
            return (
              moment(filterItem.last).format("YYYY M") ===
              `${item.year} ${item.month}`
            );
          })
          .reduce((acc, curr) => {
            return acc + curr.value;
          }, 0);
        return {
          icon: getCategoryIcon(category.name),
          name: category.name,
          value: Math.abs(categoryExpense),
          movements: category.movements.filter((filterItem) => {
            return (
              moment(filterItem.last).format("YYYY M") ===
              `${item.year} ${item.month}`
            );
          }).length,
          percentage: Math.abs(categoryExpense) / Math.abs(price),
        };
      });
      console.log({ expensesArray });
      return {
        date: moment(item.month, "M"),
        value: Math.abs(price),
        // expenses: expensesArray.filter(
        //   (expenseItem) => expenseItem.value !== 0
        // ),
        expenses: expensesArray.filter(
          (expenseItem) => expenseItem.movements !== 0
        ),
      };
    });
    setPocketDetails({
      image: findPocket.img,
      name: findPocket.name,
      target: {
        budget: 2500,
        percentage: 68,
      },
      totalExpenditure: Math.abs(price),
    });
    setExpensesList(formatLast3Months);
    setTab(formatLast3Months[2]);
  }, [findPocket]);

  useEffect(() => {
    query && query.name && setName(query.name);
  }, [query]);

  console.log(tab?.expenses);

  const optionsGraph = {
    events: ["click"],
    onClick: (event, array) => {
      event.stopPropagation();

      if (!array || array.length === 0) {
        setDoughnutName(null);
        setDoughnutValue(null);
        setDoughnutIndex(null);
        setColorList(colorListGraph);
      } else {
        const index = array[0]._index;
        const value = array[0]._chart.config.data.datasets[0].data[index];
        setDoughnutName(array[0]._view.label);
        setDoughnutValue(value);
        setDoughnutIndex(index);
        const newColors = [...colorListGraphClick];
        newColors[index] = colorListGraph[index];

        setColorList(newColors);
      }
    },
    rotation: 20,
    cutoutPercentage: 70,
    legend: {
      display: false,
      position: "left",
    },

    tooltips: {
      enabled: false,
      // position: "nearest",
      // callbacks: {
      //   label: function (tooltipItem, data) {
      //     const labelIndex = tooltipItem.index;
      //     const label = data.labels[labelIndex];
      //     var value = getCurrency(
      //       Number(data.datasets[0].data[labelIndex]).toFixed(2),
      //       2,
      //       false
      //     );
      //     return `${label}: ${value}`;
      //   },
      // },
    },
    animation: {
      animateRotate: true,
      animateScale: false,
    },
    plugins: {
      labels: {
        render: "image",
        position: "outside",
        images: (tab?.expenses || [])
          // .filter((item) => item.percentage > 0.05)
          .map((item) => {
            const { icon } = item;
            console.log(icon);
            return {
              src: icon.png,
              width: 20,
              height: 20,
            };
          }),
        // images: [
        //   { src: House, width: 20, height: 20 },
        //   { src: Bill, width: 19, height: 20 },
        //   { src: Building, width: 20, height: 20 },
        //   { src: TV, width: 20, height: 20 },
        //   { src: Basket, width: 20, height: 20 },
        //   { src: Fruit, width: 20, height: 20 },
        // ],
        textMargin: 8,
      },
    },
  };

  const dataGraph = {
    labels: (tab?.expenses || [])
      // .filter((item) => item.percentage > 0.05)
      .map((item) => {
        return item.name;
      }),
    datasets: [
      {
        data: (tab?.expenses || [])
          // .filter((item) => item.percentage > 0.05)
          .map((item) => {
            return item.value;
          }),
        // data: [500, 544, 5477, 5888],
        // backgroundColor: colorList,
        backgroundColor: colorList,
        borderWidth: 0,
        hoverBorderWidth: 0,
        weight: 20,
      },
    ],
  };

  return !pocketDetails ? null : (
    <Layout.Container>
      <View whiteBcSt fullWidthSt fullHeightSt startSt topSt>
        {pocketDetails.image || imageUrl ? (
          <View
            style={{
              height: 145,
              width: "100%",
              backgroundImage: `url(${pocketDetails.image || imageUrl})`,
            }}
            imgBgSt
          />
        ) : (
          <View fullWidthSt style={{ height: 145, backgroundColor: "#FAFBFC" }}>
            <View fullWidthSt spaceBetweenSt paddingSt>
              <Text greenTextSt centerSt>
                ¿Quieres añadir{" "}
                <Enfatize font16St greenSt>
                  una imagen?
                </Enfatize>
              </Text>
              {/* <ImageSelector
                defaultSearch={pocketDetails.name}
                value={imageUrl}
                onChange={(v) => {
                  setImageUrl(v);
                }}
                SearchFieldProps={{
                  disableUnderline: false,
                }}
                SearchButtonProps={{
                  pillSt: true,
                }}
                circleSt
                style={{
                  cameraComponent: {
                    color: imageUrl ? "transparent" : colors.green,
                  },
                }}
              /> */}
              <ImageSelector
                captions={{
                  // Valor por defecto que aparece en la barra de busqueda
                  defaultSearch: name,
                  noResultsText: " ",
                  defaultImageSuggestionText: (
                    <>
                      <View
                        style={{
                          padding: "0px 15px",
                          // paddingTop: 60,
                          textAlign: "center",
                          fontSize: 15,
                          color: "#444444",
                          paddingTop: 70,
                        }}
                      >
                        No hemos encontrado ninguna imagen para tu objetivo.
                        <br />
                        Añade una imagen de las que te sugerimos.
                      </View>
                    </>
                  ),
                }}
                defaultImages={defaultImages}
                value={imageUrl}
                onChange={(v) => {
                  console.log({ v });
                  // Al seleccionar la imagen
                  setImageUrl(v);
                }}
                // fallBackImages={[]}
                openerComponent={({ open }) => (
                  // Este componente esta customizado para santander
                  //  Es el de la camarita con el +
                  <CameraComposed value={imageUrl} onClick={open} />
                )}
                SearchFieldProps={{
                  // Estilos de TExtField que tenia creados en cents
                  //  Al tener el lookAndFeel de santander splits y pockets tendran los correspondientes
                  mediumSt: true,
                  inputImgModalBackgroundSt: true,
                  paddingTopSt: true,
                }}
              />
            </View>
          </View>
        )}

        <View fullWidthSt startSt alignCenterSt>
          <View
            horizontalSt
            // spaceBetweenSt
            style={{ width: widthContainer, marginTop: 15 }}
          >
            <Text font24St boldSt>
              {pocketDetails.name}
            </Text>
            <Button
              autoWidthSt
              icon={<SettingsIcon />}
              onClick={() => navigateTo(routes.settings)}
            />
          </View>
          <MonthGraphic list={expensesList} tab={tab} setTab={setTab} />

          <View fullWidthSt relativeSt>
            <View topSt style={{ width: widthContainer, marginTop: 15 }}>
              <TooltipSimple>
                Puedes interactuar con el gráfico pulsando sobre cada categoría
                para ver el detalle de gastos. Pulsa fuera de la gráfica para
                volver al estado inicial.
              </TooltipSimple>
            </View>
            <View autoWidthSt style={{ width: "320px", height: "auto" }}>
              <Doughnut data={dataGraph} options={optionsGraph} height={265} />
              {/* <MyChartJS
                key="key"
                type={"doughnut"}
                data={dataGraph}
                options={optionsGraph}
                style={{ height: 265, margin: 10, zIndex: 10 }}
              /> */}
            </View>
            <View style={{ position: "absolute", zIndex: 8 }}>
              <Text
                boldSt
                style={{
                  fontSize: 20,
                  width: 120,
                  textAlign: "center",
                  color:
                    doughnutIndex !== null
                      ? colorList[doughnutIndex]
                      : colors.blackLight,
                }}
              >
                {doughnutName ? doughnutName : "Total gastado"}
              </Text>
              {/* <Text boldSt capitalizeSt>
                {date.toLocaleString("default", { month: "long" })}
              </Text> */}
              <Text
                titleHeaderSt
                boldSt
                noMarginSt
                style={{
                  fontSize: 24,
                }}
              >
                <Var>
                  {doughnutValue
                    ? getCurrency(doughnutValue, 0, false)
                    : getCurrency(tab.value || 0, 0, false)}
                </Var>
              </Text>
            </View>
          </View>

          <View>
            {pocketDetails.target && name !== "Comunión Beatriz" ? (
              <View style={{ width: widthContainer, marginBottom: 16 }} topSt>
                <View horizontalSt>
                  <Text font16St>
                    Presupuesto{" "}
                    <Enfatize>
                      <Var>
                        {getCurrency(pocketDetails.target.budget, 2, false)}
                      </Var>
                    </Enfatize>
                  </Text>
                  <View
                    autoWidthSt
                    onClick={() =>
                      navigateTo(routes.createTarget, {
                        queryParams: { amount: 2500, mode: "edit" },
                      })
                    }
                  >
                    <EditIcon styleSVG={{ width: 20, height: 20 }} />
                  </View>
                </View>
                <ProgressBar value={pocketDetails.target.percentage} />
                <Text font13St regularSt>
                  Llevas el{" "}
                  <Enfatize>
                    <Var>{pocketDetails.target.percentage}%</Var>
                  </Enfatize>{" "}
                  de tu objetivo de gasto
                </Text>
              </View>
            ) : (
              <View
                fullWidthSt
                centerSt
                horizontalSt
                style={{ marginBottom: 24 }}
                onClick={() => navigateTo(routes.createTarget)}
              >
                <MetaIcon style={{ marginRight: 8 }} />
                <Text font16St regularSt greenSt>
                  Crear objetivo de gasto
                </Text>
              </View>
            )}
          </View>
        </View>

        <View
          flexSt
          startSt
          fullWidthSt
          grayBc2St
          borderTop2St
          style={{ padding: "20px 16px 0px", paddingBottom: 100 }}
        >
          {tab.expenses.map((item, index) => {
            console.log({ item });
            return (
              <BottomCard
                colorListGraph={colorListGraph}
                item={item}
                index={index}
                tab={tab}
                onPress={() => {
                  const data = {
                    selectedCategoryExpense: {
                      pocketId: findPocket.id,
                      category: item.name,
                      date: tab.date,
                    },
                  };
                  const mergedData = { ...localStorage, ...data };
                  global.localStorage.setItem(
                    "pocketData",
                    JSON.stringify(mergedData)
                  );
                  navigateTo(routes.expenseDetails);
                }}
                pocketDetails={pocketDetails}
              />
            );
          })}
        </View>
      </View>

      <CreateExpense
        noText
        visibleModal={visibleModal}
        setVisibleModal={setVisibleModal}
      />
      <AddButton onClick={() => setVisibleModal(true)} />
    </Layout.Container>
  );
});

export const BottomCard = ({
  item,
  index,
  onPress,
  pocketDetails,
  tab,
  colorListGraph,
}) => {
  console.log({ colorListGraph });
  console.log({ item, color: colorListGraph[index] });
  return (
    <View fullWidthSt spaceEvenlySt checkOptionSt onClick={onPress}>
      <View horizontalSt spaceBetweenSt fullWidthSt>
        <View flexSt topSt horizontalSt fullWidthSt spaceBetweenSt>
          {item.icon && (
            <View selfCenterSt style={{ marginRight: 15 }}>
              {/* TODO: get images by svg */}
              <item.icon.svg
                color={colorListGraph[index]}
                style={{ width: 28, height: "auto" }}
              />
            </View>
          )}
          <View flexSt selfCenterSt topSt>
            <View autoWidthSt horizontalSt topSt>
              <Text font16St leftSt boldSt ellipsisSt>
                {item.name}
              </Text>
            </View>
            <Text font13St leftSt>
              <Var>{item.movements.toString()}</Var>
              {item.movements === 1 ? " movimiento" : " movimientos"}
            </Text>
          </View>
          <View flexSt bottomSt>
            <Text font16St boldSt>
              <Var>{getCurrency(item.value, 2, false)}</Var>
            </Text>
            <Text font13St style={{ marginTop: 3 }}>
              {" "}
              <Var>{((item.value / tab.value) * 100).toFixed(1)} %</Var>
              {/* {item.percentage}% */}{" "}
            </Text>
            {/* <Text font13St>{item.percentage}%</Text> */}
          </View>
        </View>
        {/* <ChevronRightIcon style={{ color: "#444444" }} /> */}
      </View>
    </View>
  );
};
