import React from "react";

export default function BackButton(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...props.style }}
    >
      <g id="Group">
        <rect
          id="Rectangle"
          width="24"
          height="24"
          fill="white"
          fill-opacity="0.01"
        />
        <g id="Group 6">
          <path
            id="Stroke 133"
            d="M10.9712 20L3.2022 12.5926C2.9326 12.3351 2.9326 11.6932 3.2022 11.4352L11 4"
            stroke={props.color ? props.color : "#EC0000"}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            id="Stroke 135"
            d="M21 12L4.00002 12"
            stroke={props.color ? props.color : "#EC0000"}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </g>
    </svg>
  );
}
