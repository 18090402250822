import React from "react";

export default function RightIcon(props) {
  return (
    <svg
      width="8"
      height="15"
      viewBox="0 0 8 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...props.style }}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.00434 14.0041C0.738924 14.0056 0.483816 13.9013 0.29537 13.7141C0.106332 13.5263 0 13.2707 0 13.0041C0 12.7375 0.106332 12.4819 0.29537 12.2941L5.5877 7.00409L0.29537 1.71409C-0.0961844 1.32197 -0.0961844 0.686214 0.29537 0.294092C0.686925 -0.0980305 1.32176 -0.0980305 1.71331 0.294092L7.70463 6.29409C7.89367 6.48186 8 6.73745 8 7.00409C8 7.27073 7.89367 7.52633 7.70463 7.71409L1.71331 13.7141C1.52487 13.9013 1.26976 14.0056 1.00434 14.0041Z"
        fill="#9B9B9B"
      />
    </svg>
  );
}
