import React, { useState } from "react";

import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";

import {
  UiWrapper,
  NavigationWrapper,
  View,
  Text,
  PickerHOC,
  Layout,
  InputCheck,
} from "coinscrap-webapp-core";
import moment from "moment";
import { getCurrency } from "../../libs/Currency";
import {
  pocketMovements,
  getCategoryIcon,
} from "../data/categories/categories";
import EditIcon from "../common/svgs/EditIcon";
import colors from "../../config/colors";
import routes from "../../config/routes";
import Footer from "../common/Footer";
import ButtonFooter from "../common/ButtonFooter";
import { useSave } from "../../libs/hooks/useSave";

export default PickerHOC()(() => {
  // eslint-disable-next-line no-unused-vars
  const [localStorage, setLocalStorage] = useState(
    JSON.parse(global.localStorage.getItem("pocketData"))
  );
  const [myData, saveMyData] = useSave();

  const [checked, setChecked] = useState(false);
  const { movementDetails } = localStorage;
  const { useBackground, useFooter } = UiWrapper.use();
  const { navigateTo, useBackRoute } = NavigationWrapper.use();

  useBackground(colors.greyBc2);

  useBackRoute(routes.expenseDetails);

  useFooter(
    <Footer style={{ backgroundColor: colors.white }}>
      <ButtonFooter
        text="Aceptar"
        action={() => navigateTo(routes.expenseDetails)}
      />
    </Footer>
  );

  const findPocket = (myData?.pockets || []).find(
    (pocket) => pocket.id === movementDetails.pocketId
  );

  const findCategory = findPocket?.categories.find(
    (category) => category.name === movementDetails.category
  );

  let CategoryIcon = getCategoryIcon(findCategory.name);
  console.log(CategoryIcon);
  return (
    <Layout.Container>
      <View
        grayBc2St
        fullWidthSt
        fullHeightSt
        startSt
        style={{ padding: "15px 0" }}
      >
        <View width80St whiteBcSt boxSt topSt billSt>
          <View fullWidthSt horizontalSt spaceBetweenSt>
            <Text font18St boldSt noT>
              {movementDetails.name}
            </Text>
            <View
              autoWidthSt
              // onClick={() => }
            >
              <EditIcon styleSVG={{ width: 20, height: 20 }} />
              <Text font10St regularSt greenSt>
                Editar
              </Text>
            </View>
          </View>
          <Text h1St boldSt noT>
            {getCurrency(movementDetails.value * -1, 2, false)}
          </Text>

          <View borderDashedSt />

          <View
            horizontalSt
            fullWidthSt
            spaceBetweenSt
            borderBottomDashedSt
            style={{ marginBottom: 5 }}
          >
            <View flexSt topSt>
              <Text smallerBillSt>Fecha operación</Text>
              <View horizontalSt>
                <Text normalSt noT>
                  {moment(movementDetails.last).format("DD MMM YYYY")}
                </Text>
                {/* <Text normalSt noT font10St>
                  {moment(movementDetails.last).format("HH:mm")}
                </Text> */}
              </View>
            </View>

            <View flexSt topSt>
              <Text smallerBillSt>Grupo</Text>
              <View horizontalSt>
                <CategoryIcon.svg
                  style={{ width: 14, height: 14, marginRight: 6 }}
                />
                <Text normalSt>{findCategory.name}</Text>
              </View>
            </View>
          </View>

          <View horizontalSt topSt fullWidthSt>
            <InputCheck
              value={checked}
              checkedIcon={<CheckBoxOutlinedIcon />}
              onChange={(e) => setChecked(!checked)}
            />
            <Text normalSt style={{ marginLeft: 5, marginTop: 5 }}>
              Añadir todos los pagos como este en este pocket
            </Text>
          </View>
        </View>
      </View>
    </Layout.Container>
  );
});
