import React, { useEffect } from "react";

import Modal from "@material-ui/core/Modal";

import { View, Layout, Image, Enfatize, Text } from "coinscrap-webapp-core";
import colors from "../../../config/colors";

// import Bateria from "../../common/svgs/Bateria";
// import Buzo from "../../common/svgs/Buzo";

import santanderLoaderShapes from "../../../assets/images/santanderLoaderShapes.gif";
import TarjetaVoladora from "../../common/svgs/TarjetaVoladora";

export default ({ visibleModal, setVisibleModal, action }) => {
  useEffect(() => {
    if (visibleModal)
      setTimeout(() => {
        setVisibleModal(false);
        action();
      }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  return (
    <Layout.Container>
      <Modal
        disablePortal
        open={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <View
          flexSt
          textCenterSt
          fullHeightSt
          // whiteOpacityBcSt
          style={{ padding: "0px 35px", background: colors.white }}
        >
          <Text font28St regularSt>
            Danos un segundito
          </Text>
          <Text font18St>
            Estamos buscando en los movimientos de los últimos 90 días.
          </Text>
          <Image
            style={{ marginTop: 15 }}
            //style='-webkit-user-select: none;max-width: 100%;margin: auto;'
            height={85}
            width={85}
            src={santanderLoaderShapes}
          />
          {/* <Loading style={{ marginTop: 15 }} /> */}
          <Text font12St regularSt>
            Recopilando movimientos
          </Text>
          <TarjetaVoladora style={{ marginTop: 30 }} />
          <Text font16St boldSt redSt>
            ¿SABÍAS QUE...?
          </Text>
          <Text font24St regularSt>
            Puedes apagar tus tarjetas{" "}
            <Enfatize>cuando no las encuentras</Enfatize>
          </Text>
        </View>
      </Modal>
    </Layout.Container>
  );
};
