import colors from "../colors";

import {
  UiWrapper,
  ImageSelector,
  LocalGalleryResolver,
} from "coinscrap-webapp-core";

//Array de imagenes
import { gallery } from "../gallery/data";

ImageSelector.setResolver(new LocalGalleryResolver(gallery));

// Definicion estilos
UiWrapper.defineComponent("imageSelector", {
  styles: {
    default: {
      closeIconComponent: {
        color: "red",
      },
      modalContentComponent: {
        backgroundColor: colors.greyLight,
      },
      searchContainerComponent: {
        padding: "0 15px 15px 15px",
        width: "100%",
      },
      searchFieldComponent: {
        width: "auto",
      },
      headerTextComponent: {
        color: "#444444",
        fontWeight: "bold",
      },
      headerTextContainerComponent: {
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        width: "100%",
      },
      resultsContainerParentComponent: {
        backgroundColor: colors.white,
        justifyContent: "flex-start",
        color: "#444444",
      },
      resultsContainerComponent: {
        color: "#444444",
        backgroundColor: colors.white,
        padding: 15,
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gridAutoRows: "max-content",
        gap: "10px",
        alignItems: "flex-start",

        paddingTop: 0,
      },
      suggestionsContainerComponent: {
        color: "#444444",
        backgroundColor: colors.white,
        padding: 15,
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: "10px",
        alignItems: "flex-start",
        height: "100%",
        paddingTop: 0,
      },
      resultItemImageComponent: {
        border: 0,
        minWidth: 0,
        maxWidth: 150,
        maxHeight: 120,
      },
      emptyMode: {
        resultsContainerComponent: {
          backgroundColor: colors.white,
          display: "flex",
          alignItems: "center",
        },
      },
    },
  },
});
