import React from "react";
import classes from "./Loader.module.css";
const Loader = () => {
  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.loader} />
      </div>
      <div className={classes.overlay} />
    </>
  );
};

export default Loader;
