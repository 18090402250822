import React from "react";

import { View } from "coinscrap-webapp-core";
export default ({ children, noBorder, whiteBc2St, style }) => {
  return (
    <View fullWidthSt footerSt noBorderSt={noBorder} whiteBc2St={whiteBc2St} style={style}>
      {children}
    </View>
  );
};
