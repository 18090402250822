import React from "react";

export default function SVGFilmIcon(props) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...props.style }}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.9261 4.04183C25.0339 4.41078 24.8206 4.79679 24.4494 4.90413L2.47096 11.2605L2.49519 11.3004H9.01835H9.02038H16.0184H16.0204H23.0184C23.019 11.3004 23.0197 11.3004 23.0204 11.3004H27.3C27.6866 11.3004 28 11.6119 28 11.9962V16.1738L28 16.1754L28 16.1771V25.2255C28 26.7582 26.7497 28.0005 25.207 28.0005H4.19301C2.6503 28.0005 1.40001 26.7582 1.40001 25.2255V16.1754V12.1899L0.802058 11.2048C0.76503 11.1438 0.738524 11.0794 0.722022 11.0137L0.106475 8.91268C-0.305209 7.50393 0.509086 6.02966 1.92575 5.62057L6.6481 4.25493C6.667 4.24859 6.68618 4.24304 6.70556 4.23831L13.3736 2.31C13.3896 2.30474 13.4058 2.30006 13.4222 2.29595L20.9926 0.106685C22.4094 -0.302433 23.8909 0.506453 24.3045 1.91535L24.9261 4.04183ZM21.3298 12.6921H16.3094L13.5103 15.4754H18.5308L21.3298 12.6921ZM20.5103 15.4754L23.3094 12.6921H26.6V15.4754H20.5103ZM9.30938 12.6921H14.3298L11.5308 15.4754H6.51033L9.30938 12.6921ZM7.32982 12.6921H2.80001V15.4754H4.53076L7.32982 12.6921ZM14.0669 3.55887L18.8879 2.16472L16.9816 5.6145L12.1606 7.00878L14.0669 3.55887ZM21.3834 1.44304L20.7889 1.61495L18.8827 5.06469L23.3864 3.76218L22.9603 2.30445C22.7633 1.63338 22.0579 1.24825 21.3834 1.44304ZM12.1659 4.10863L10.2595 7.55859L5.43996 8.95246L7.34574 5.50255L12.1659 4.10863ZM2.31656 6.95692L5.44488 6.05225L3.53907 9.50221L1.87809 9.98258L1.45077 8.52401C1.25481 7.85346 1.64248 7.15158 2.31656 6.95692ZM2.80001 25.2255V16.8754H26.6V25.2255C26.6 25.9895 25.9767 26.6088 25.207 26.6088H4.19301C3.42335 26.6088 2.80001 25.9895 2.80001 25.2255Z"
        fill={props.color || "#DD5858"}
      />
    </svg>
  );
}
