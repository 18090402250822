import React from "react";

export default function SettingsIcon(props) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...props.style }}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.315 9.07692H18.3723C17.365 9.07692 16.8604 7.85885 17.5727 7.14654L18.9465 5.77308C19.2142 5.50538 19.2142 5.07192 18.9465 4.80423L17.1954 3.05346C16.9281 2.78577 16.4946 2.78577 16.2269 3.05346L14.8531 4.42692C14.1408 5.13923 12.9231 4.635 12.9231 3.62769V1.68462C12.9231 1.30654 12.6165 1 12.2381 1H9.76192C9.38346 1 9.07692 1.30654 9.07692 1.68462V3.62769C9.07692 4.635 7.85923 5.13923 7.14692 4.42692L5.77308 3.05346C5.50538 2.78577 5.07192 2.78577 4.80462 3.05346L3.05346 4.80423C2.78577 5.07192 2.78577 5.50538 3.05346 5.77308L4.42731 7.14654C5.13962 7.85885 4.635 9.07692 3.62769 9.07692H1.685C1.30654 9.07692 1 9.38346 1 9.76154V12.2381C1 12.6165 1.30654 12.9231 1.685 12.9231H3.62769C4.635 12.9231 5.13962 14.1408 4.42731 14.8531L3.05346 16.2269C2.78577 16.4942 2.78577 16.9281 3.05346 17.1954L4.80462 18.9465C5.07192 19.2142 5.50538 19.2142 5.77308 18.9465L7.14692 17.5727C7.85923 16.8604 9.07692 17.365 9.07692 18.3723V20.315C9.07692 20.6935 9.38346 21 9.76192 21H12.2381C12.6165 21 12.9231 20.6935 12.9231 20.315V18.3723C12.9231 17.365 14.1408 16.8604 14.8531 17.5727L16.2269 18.9465C16.4946 19.2142 16.9281 19.2142 17.1954 18.9465L18.9465 17.1954C19.2142 16.9281 19.2142 16.4942 18.9465 16.2269L17.5727 14.8531C16.8604 14.1408 17.365 12.9231 18.3723 12.9231H20.315C20.6935 12.9231 21 12.6165 21 12.2381V9.76154C21 9.38346 20.6935 9.07692 20.315 9.07692V9.07692Z"
        stroke="#137E84"
        stroke-width="0.923077"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14 11C14 12.6567 12.6567 14 11 14C9.34325 14 8 12.6567 8 11C8 9.34325 9.34325 8 11 8C12.6567 8 14 9.34325 14 11V11Z"
        stroke="#137E84"
        stroke-width="0.923077"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
