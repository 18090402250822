import { UiWrapper } from "coinscrap-webapp-core";

UiWrapper.defineComponent("inputSuggest", {
  styles: {
    default: {
      containerComponent: {
        width: "80%",
        // border: "1px solid #f60",
      },
      textFieldComponent: {
        containerComponent: {
          width: "100%",
        },
      },
      suggestionsContainerComponent: {
        padding: 5,
      },
      suggestionSelectedTextComponent: {
        color: "orange",
      },
      sectionTitleTextComponent: {
        fontWeight: "bold",
        fontStyle: "italic",
        borderBottom: "solid 1px",
        marginBottom: 5,
      },
    },
  },
});
