import React from "react";
import { View, NavigationWrapper, Button } from "coinscrap-webapp-core";

import BackButton from "./svgs/BackButton";
import colors from "../../config/colors";

export default ({
  text1,
  text2,
  icon1,
  action1,
  action2,
  disabled1,
  disabled2,
}) => {
  const { goBack } = NavigationWrapper.use();
  // console.log(" BUTTON FOOTER DOUBLE");
  return (
    <View fullWidthSt horizontalSt spaceBetweenSt>
      <Button
        pillSt
        backButtonSt
        disabled={disabled1}
        onClick={() => {
          if (action1) action1();
          else goBack();
        }}
      >
        {icon1 && <BackButton color={colors.green} style={{ marginRight: 5 }} />}
        {text1 ? text1 : "Anterior"}
      </Button>
      <Button
        pillFillSt
        nextButtonSt
        disabledSt={disabled2}
        disabled={disabled2}
        onClick={!disabled2 && action2}
      >
        {text2 ? text2 : "Continuar"}
      </Button>
    </View>
  );
};
