import React from "react";

import { Enfatize, Text, Var, View } from "coinscrap-webapp-core";
export default ({ children, step, total }) => {
  return (
    <View selfStartSt autoWidthSt style={{ marginTop: 23, marginBottom: 15 }}>
      <Text font16St>
        Paso{" "}
        <Enfatize>
          <Var>{step}</Var>
        </Enfatize>{" "}
        de <Var>{total}</Var>
      </Text>
    </View>
  );
};
