import React from 'react';

import { Redirect } from 'react-router-dom';

const Home = () => {
  return (
    <React.Fragment>
      <Redirect to='/home' />
    </React.Fragment>
  );
};

export default Home;
