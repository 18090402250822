import React, { useState } from "react";
import { Text, View, Tooltip, Image } from "coinscrap-webapp-core";
import TooltipInfo from "../common/svgs/TooltipInfo.svg";
export default function TooltipSimple({ ...props }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      autoWidthSt
      onClick={() => setIsOpen((prevState) => !prevState)}
      onBlur={() => setIsOpen(false)}
      tabIndex="0"
    >
      <Tooltip
        open={isOpen}
        withArrow
        placement="bottom"
        style={{
          popperContentComponent: {
            backgroundColor: "#ffffff",
            borderRadius: 0,
            padding: 18,
            boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
            margin: "13px 0",
            border: 0,
          },
          arrowComponent: {
            color: "#ffffff",
          },
        }}
        content={
          <View fullWidthSt>
            <Text exampleNormalSt style={{ color: "#6f7779" }}>
              {props.children}
            </Text>
          </View>
        }
      >
        <Image src={TooltipInfo} style={{ width: 16, height: "auto" }} />
      </Tooltip>
    </div>
  );
}
