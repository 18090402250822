import React, { useState, useEffect } from "react";
import { Layout } from "coinscrap-webapp-core";
import Loader from "./loader/Loader";
import { useLoader } from "./loader/LoaderWrapperContext";
import { getPockets } from "../../actions";
import { useSave } from "../../libs/hooks/useSave";

export default ({ children, ...props }) => {
  const { loading, setLoading } = useLoader();
  const [myData, saveMyData] = useSave();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        // setSavedData({ ...savedData, loading: true });
        const res = await getPockets();
        console.log({ res });
        await saveMyData(res);
        // setSavedData({ ...res, loading: false });
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      {" "}
      {children}
      {loading ? <Loader /> : null}
    </Layout>
  );
};

/* Comentar con jesus
export default (props) => {
  return (
    <Layout>
      <Component {...props}>{props.children}</Component>
    </Layout>
  );
};
*/
