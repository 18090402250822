export const defaultImages = [
  '/default/arquitectura1.png',
  '/default/arquitectura2.png',
  '/default/arquitectura3.png',
  '/default/arquitectura4.png',
  '/default/arquitectura5.png',
  '/default/deporte1.png',
  '/default/deporte2.png',
  '/default/deporte3.png',
  '/default/deporte4.png',
  '/default/deporte5.png',
  '/default/deporte6.png',
  '/default/escaparate.png',
  '/default/huchas1.png',
  '/default/huchas2.png',
  '/default/huchas3.png',
  '/default/joyas1.png',
  '/default/joyas2.png',
  '/default/joyas3.png',
  '/default/moda1.png',
  '/default/moda2.png',
  '/default/moda3.png',
  '/default/moda4.png',
  '/default/moda5.png',
  '/default/moda6.png',
  '/default/naturaleza1.png',
  '/default/naturaleza2.png',
  '/default/naturaleza3.png',
  '/default/naturaleza4.png',
  '/default/paracaidas1.png',
  '/default/paracaidas2.png',
  '/default/paracaidas3.png',
  '/default/paracaidas4.png',
  '/default/paracaidas5.png',
  '/default/reloj1.png',
  '/default/reloj2.png',
  '/default/reloj3.png',
  '/default/viajes1.png',
  '/default/viajes2.png',
  '/default/viajes3.png',
];
