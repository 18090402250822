import React from 'react';

export default function IdeaEuroIcon(props) {
  return (
    <svg width='26px' height='33px' viewBox='0 0 26 33' style={{...props.styleSVG}}>
      <g id='V2' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='30' transform='translate(-39.000000, -784.000000)'>
          <g id='Group-8' transform='translate(0.000000, 765.000000)'>
            <rect
              id='bg-btn-copy-7'
              fill='#FFF9E9'
              x='0'
              y='0'
              width='375'
              height='96'
              rx='4'
            ></rect>
            <g id='Group-3' transform='translate(39.000000, 19.000000)'>
              <g
                id='cash-idea'
                stroke='#000000'
                strokeLinecap='round'
                strokeLinejoin='round'
              >
                <line x1='9' y1='28.5' x2='16' y2='28.5' id='Stroke-1'></line>
                <g id='Group-14'>
                  <line
                    x1='9.57894737'
                    y1='30.9375'
                    x2='16.4210526'
                    y2='30.9375'
                    id='Stroke-2'
                  ></line>
                  <line
                    x1='13'
                    y1='30.9375'
                    x2='13'
                    y2='32.3125'
                    id='Stroke-4'
                  ></line>
                  <path
                    d='M25.3157895,12.375 C25.3157895,5.922125 19.8024211,0.6875 13,0.6875 C6.19757895,0.6875 0.684210526,5.922125 0.684210526,12.375 C0.684210526,17.70175 4.44326316,22.188375 9.57894737,23.596375 L9.57894737,25.4375 L16.4210526,25.4375 L16.4210526,23.596375 C21.5567368,22.188375 25.3157895,17.70175 25.3157895,12.375 Z'
                    id='Stroke-6'
                  ></path>
                </g>
              </g>
              <g
                id='Group-2'
                transform='translate(8.000000, 8.000000)'
                fill='#424242'
                fillRule='nonzero'
              >
                <path
                  d='M0.45125,3.96260675 L5.49442,3.96260675 C5.74363849,3.96260675 5.94567,3.76057524 5.94567,3.51135675 C5.94567,3.26213826 5.74363849,3.06010675 5.49442,3.06010675 L0.45125,3.06010675 C0.202031507,3.06010675 -1.60316205e-14,3.26213826 -1.60316205e-14,3.51135675 C-1.60316205e-14,3.76057524 0.202031507,3.96260675 0.45125,3.96260675 Z'
                  id='Stroke-13'
                ></path>
                <path
                  d='M0.45125,5.979965 L5.49442,5.979965 C5.74363849,5.979965 5.94567,5.77793349 5.94567,5.528715 C5.94567,5.27949651 5.74363849,5.077465 5.49442,5.077465 L0.45125,5.077465 C0.202031507,5.077465 -1.60316205e-14,5.27949651 -1.60316205e-14,5.528715 C-1.60316205e-14,5.77793349 0.202031507,5.979965 0.45125,5.979965 Z'
                  id='Stroke-15'
                ></path>
                <path
                  d='M8.75879356,1.01522478 C7.91405925,0.364481705 6.830451,2.24442687e-13 5.68565975,2.24442687e-13 C3.62106438,2.24442687e-13 1.81026457,1.18934188 1.22676942,2.91297623 C1.14685726,3.14903534 1.27343937,3.40518067 1.50949848,3.48509283 C1.74555759,3.56500499 2.00170292,3.43842288 2.08161508,3.20236377 C2.53599618,1.86013003 3.99400947,0.9025 5.68565975,0.9025 C6.63236225,0.9025 7.52209081,1.20176847 8.20802594,1.73018022 C8.40545543,1.8822705 8.68879719,1.8455158 8.84088747,1.64808631 C8.99297775,1.45065682 8.95622305,1.16731506 8.75879356,1.01522478 Z'
                  id='Stroke-17'
                ></path>
                <path
                  d='M1.21387076,6.05445488 C1.78292504,7.79673178 3.6050967,9.00541575 5.68511825,9.00541575 C6.87183659,9.00541575 7.99225827,8.61400887 8.84753976,7.9199269 C9.04105407,7.76288527 9.07062102,7.47870355 8.9135794,7.28518924 C8.75653777,7.09167493 8.47235605,7.06210798 8.27884174,7.2191496 C7.5855651,7.78176066 6.66624266,8.10291575 5.68511825,8.10291575 C3.98128899,8.10291575 2.51458588,7.13002138 2.07177074,5.77425162 C1.99439475,5.53734908 1.73962166,5.40802726 1.50271912,5.48540326 C1.26581658,5.56277925 1.13649476,5.81755234 1.21387076,6.05445488 Z'
                  id='Stroke-19'
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
