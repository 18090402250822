import React from "react";
import { Button } from "coinscrap-webapp-core";

export default ({ text, action, disabled, addStyle, fill, loading }) => {
  return fill ? (
    <Button
      pillFillSt
      disabled={disabled}
      onClick={action}
      loading={loading}
      style={addStyle}
    >
      {text}
    </Button>
  ) : (
    <Button
      pillSt
      disabled={disabled}
      onClick={action}
      loading={loading}
      style={addStyle}
    >
      {text}
    </Button>
  );
};
