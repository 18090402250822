import React, { createContext, useContext, useEffect, useState } from "react";

const LoadingContext = createContext();

export const LoaderProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoader = () => {
  const context = useContext(LoadingContext);
  if (context === undefined) {
    throw new Error("useLoader must be used within a LoadingProvider");
  }
  return context;
};
