import React from "react";

export default function SVGToolIcon(props) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...props.style }}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.46153 26.0597C2.10955 26.7078 2.96878 27.0646 3.88241 27.0646C4.79603 27.0646 5.65526 26.7086 6.30328 26.0605L16.7372 15.6266C19.5373 16.585 22.6254 15.8802 24.7487 13.7561C26.9984 11.5057 27.6496 8.16075 26.4047 5.23265C26.2991 4.98224 26.0751 4.80144 25.8079 4.74943C25.5343 4.69343 25.2639 4.78144 25.0719 4.97424L19.8701 10.1768L17.3452 7.65033L22.547 2.44856C22.739 2.25575 22.8238 1.98054 22.7726 1.71334C22.7198 1.44613 22.539 1.22212 22.2878 1.11492C19.3485 -0.131525 15.998 0.514096 13.7531 2.76137C11.6315 4.88224 10.9306 7.97834 11.8939 10.784L1.46153 21.2172C0.127082 22.5532 0.127082 24.7253 1.46153 26.0597ZM2.61996 22.3756L13.4283 11.5665C13.6651 11.3305 13.7339 10.9744 13.6027 10.6672C12.6187 8.34795 13.1323 5.69907 14.9116 3.91981C16.0844 2.74697 17.6381 2.12775 19.2269 2.12775C19.6285 2.12775 20.0317 2.16695 20.4317 2.24695L15.6076 7.07111C15.2876 7.39112 15.2876 7.90954 15.6076 8.22955L19.2909 11.9145C19.4445 12.0681 19.6525 12.1545 19.8701 12.1545C20.0877 12.1545 20.2957 12.0681 20.4493 11.9145L25.2719 7.09111C25.6639 9.06718 25.0591 11.128 23.5902 12.5977C21.8062 14.3809 19.1629 14.8978 16.854 13.9169C16.5516 13.7905 16.1932 13.8545 15.9548 14.0921L5.14485 24.9021C4.80724 25.2405 4.35922 25.4261 3.88241 25.4261C3.40719 25.4261 2.95918 25.2397 2.61996 24.9013C1.92394 24.2053 1.92394 23.0724 2.61996 22.3756Z"
        fill={props.color || "#DD5858"}
      />
    </svg>
  );
}
