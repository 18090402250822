import { UiWrapper } from "coinscrap-webapp-core";
import colors from "../colors";
import fonts from "../fonts";

import commonStyles from "./common";

UiWrapper.defineComponent("enfatize", {
  styles: {
    default: {
      color: colors.blackLight,
      fontFamily: fonts.SantanderTextRegular,
      fontWeight: 800,
    },
    regularSt: {
      fontFamily: fonts.SantanderTextRegular,
    },
    boldSt: {
      fontFamily: fonts.SantanderTextRegular,
      fontWeight: 700,
    },
    italicSt: {
      fontFamily: fonts.SantanderTextItalic,
      fontWeight: 100,
    },
    headlineRegularSt: {
      fontFamily: fonts.SantanderHeadlineRegular,
    },
    headlineBoldSt: {
      fontFamily: fonts.SantanderHeadlineBold,
    },

    whiteSt: {
      color: colors.white,
    },
    blackSt: {
      color: colors.black,
    },
    black2St: {
      color: colors.black2,
    },
    blackLightSt: {
      color: colors.blackLight,
    },
    greenSt: {
      color: colors.green,
    },

    ...commonStyles.styles,
  },
});
