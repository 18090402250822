import React from "react";

import colors from "../../config/colors";

import { View } from "coinscrap-webapp-core";
import IdeaEuroIcon from "./svgs/IdeaEuroIcon";

//import { Text } from '../../components/Text';

export default function Example({ children, style }) {
  //console.log('Header', img);

  return (
    <View
      vAlign="top"
      fullWidthSt
      style={{
        padding: "15px 25px 15px 40px",
        borderRadius: 4,
        backgroundColor: colors.yellowLight,
        marginBottom: 0,
        marginTop: 15,
        //flex: 'none', // Safari: Sino el div no ocupa el height bien
        flex: "0 0 auto",
        ...style,
      }}
    >
      <View
        horizontalSt
        startSt
        style={{ maxWidth: 400, alignItems: "flex-start" }}
      >
        <View style={{ width: "auto", marginRight: 14 }}>
          <IdeaEuroIcon styleSVG={{ marginTop: 4 }} />
        </View>
        <View hAlign="left" style={{ width: "calc(100% - 35px)" }}>
          {children}
        </View>
      </View>
    </View>
  );
}
