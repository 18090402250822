import React from "react";

export default function SVGSportIcon(props) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...props.style }}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.90568 26.0949C2.57929 26.7671 3.67863 26.7671 4.35223 26.0949L9.01147 21.4343L6.56491 18.9878L1.90568 23.6484C1.23067 24.322 1.23067 25.4213 1.90568 26.0949ZM20.7835 1.39293C18.6534 1.39293 16.3357 2.34521 14.5054 4.17416C11.3054 7.37552 10.7914 12.0697 13.3612 14.6395C15.931 17.2079 20.6266 16.6925 23.8252 13.494C27.0266 10.2926 27.5406 5.59839 24.9722 3.02862C23.8714 1.92789 22.38 1.39293 20.7835 1.39293ZM7.55362 17.9949L10.0044 20.447C11.1597 19.3169 12.4943 18.191 15.0221 17.2415C14.0376 16.9068 13.1273 16.3858 12.3711 15.6296C11.6191 14.879 11.0855 13.9827 10.7494 13.0052C9.80411 15.512 8.67956 16.8438 7.55362 17.9949ZM24.8153 14.4841C23.251 16.0483 21.3661 17.0482 19.4741 17.4557C19.4687 17.4575 19.4636 17.4604 19.4584 17.4632C19.4514 17.467 19.4443 17.4709 19.4363 17.4726C13.8345 18.5943 12.2129 20.2146 10.4973 21.9287L5.34234 27.085C4.73175 27.6956 3.9307 27.9995 3.12826 27.9995C2.32721 27.9995 1.52616 27.6956 0.915576 27.085C-0.305599 25.8653 -0.305599 23.8781 0.915576 22.6583L6.07056 17.502C7.78609 15.7878 9.40499 14.1676 10.5183 8.60509C10.5215 8.5893 10.5282 8.57617 10.535 8.56296C10.5402 8.55274 10.5455 8.54247 10.5491 8.53087C10.9567 6.6375 11.9496 4.75114 13.5153 3.18407C17.2628 -0.564859 22.8449 -1.07741 25.9623 2.03852C29.0768 5.15446 28.5629 10.7365 24.8153 14.4841ZM19.5984 23.7931C19.5984 25.3373 20.8542 26.5931 22.3984 26.5931C23.9426 26.5931 25.1984 25.3373 25.1984 23.7931C25.1984 22.2489 23.9426 20.9931 22.3984 20.9931C20.8542 20.9931 19.5984 22.2489 19.5984 23.7931ZM18.1984 23.7931C18.1984 21.4775 20.0828 19.5931 22.3984 19.5931C24.714 19.5931 26.5984 21.4775 26.5984 23.7931C26.5984 26.1087 24.714 27.9931 22.3984 27.9931C20.0828 27.9931 18.1984 26.1087 18.1984 23.7931Z"
        fill={props.color || "#DD5858"}
      />
    </svg>
  );
}
