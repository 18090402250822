import React, { useState, useEffect } from "react";

import {
  UiWrapper,
  NavigationWrapper,
  View,
  Text,
  PickerHOC,
  Layout,
  Enfatize,
  Button,
  InputSlider,
  TextField,
  Var,
} from "coinscrap-webapp-core";

import { getCurrency } from "../../libs/Currency";

import Footer from "../common/Footer";

import colors from "../../config/colors";
import routes from "../../config/routes";

export default PickerHOC()(() => {
  const [price, setPrice] = useState(100);
  const [mode, setMode] = useState("new");

  const { useBackground, useFooter } = UiWrapper.use();
  const { useBackRoute, goBack, query } = NavigationWrapper.use();

  useBackground(colors.white);

  useBackRoute(routes.details);

  useFooter(
    <Footer>
      <Button id="siguienteButton" pillSt onClick={() => goBack()}>
        Guardar
      </Button>
    </Footer>
  );

  useEffect(() => {
    query && query.mode && setMode(query.mode);
    query && query.amount && setPrice(query.amount);
  }, [query]);

  return (
    <Layout.Container>
      <View flexSt fullWidthSt startSt whiteGradient3St>
        <Text
          font14St
          uppercaseSt
          regularSt
          style={{ marginTop: 30, marginBottom: 4 }}
        >
          {mode === "edit" ? "Editar " : "Nuevo "}
          <Enfatize>objetivo de gasto</Enfatize>
        </Text>
        <Text h1St style={{ textAlign: "center" }}>
          ¿Cuánto quieres <Enfatize>gastar?</Enfatize>
        </Text>
        <View width92St style={{ marginTop: 30 }} bgWhiteSt>
          <TextField
            fullWidthSt
            // disableUnderline={false}
            bigSt
            lightBcSt
            borderBottomSt
            value={getCurrency(price ? price : 0)}
          />
          <InputSlider
            id="amountSlider"
            max={5000}
            min={100}
            step={25}
            value={price}
            biggerSt
            onChange={(v) => setPrice(v)}
          />
          <View horizontalSt fullWidthSt spaceBetweenSt>
            <Text noT labelSt>
              <Var>100€</Var>
            </Text>
            <Text noT labelSt>
              <Var>5.000€</Var>
            </Text>
          </View>
        </View>
      </View>
    </Layout.Container>
  );
});
