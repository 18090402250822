import React from "react";

export default function TVIcon(props) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...props.style }}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1 8.62558V20.1111C1 21.69 2.28513 22.9745 3.86403 22.9745H10.3331V26.0416C9.25765 26.1623 8.2137 26.3362 7.23706 26.5697C6.85145 26.6623 6.61377 27.0493 6.70562 27.4349C6.79746 27.8219 7.18798 28.0673 7.57078 27.9663C11.4199 27.0493 16.3718 27.0521 20.216 27.9804C20.2721 27.993 20.3288 28 20.3849 28C20.7088 28 21.0026 27.7799 21.0818 27.4503C21.1751 27.0647 20.9381 26.6777 20.5525 26.5838C19.5933 26.3522 18.568 26.1795 17.5125 26.0568V22.9745H23.9816C25.5605 22.9745 26.8456 21.69 26.8456 20.1111V8.62558C26.8456 7.03687 25.5528 5.74403 23.9641 5.74403H15.2176L18.1211 1.09849C18.3314 0.763358 18.229 0.320258 17.8932 0.109926C17.5574 -0.101809 17.1129 0.00195488 16.9039 0.338487L13.9228 5.1083L10.9417 0.338487C10.7321 0.00195503 10.289 -0.101809 9.95245 0.109926C9.61662 0.320258 9.51425 0.763358 9.72459 1.09849L12.6281 5.74403H3.88155C2.29284 5.74403 1 7.03687 1 8.62558ZM16.0766 25.9291C14.6505 25.8314 13.1963 25.8278 11.769 25.9202V22.9745H16.0766V25.9291ZM25.4098 8.62558V20.1111C25.4098 20.8978 24.769 21.5386 23.9816 21.5386H3.86403C3.07668 21.5386 2.43587 20.8978 2.43587 20.1111V8.62558C2.43587 7.82772 3.08439 7.1799 3.88155 7.1799H23.9641C24.7612 7.1799 25.4098 7.82772 25.4098 8.62558Z"
        fill={props.color || "#DD5858"}
      />
    </svg>
  );
}
