import { UiWrapper } from "coinscrap-webapp-core";
import colors from "../colors";
import fonts from "../fonts";

import commonStyles from "./common";

UiWrapper.defineComponent("text", {
  styles: {
    default: {
      color: colors.blackLight,
      fontSize: 20,
      lineHeight: 1.2,
      fontFamily: fonts.SantanderTextLight,
    },

    underLineSt: {
      textDecoration: "underline",
    },

    upperCaseSt: {
      textTransform: "uppercase",
    },

    noMarginSt: {
      margin: 0,
    },

    textCenterSt: {
      textAlign: "center",
    },

    centerSt: {
      textAlign: "center",
    },

    leftSt: {
      textAlign: "left",
    },

    rightSt: {
      textAlign: "right",
    },

    noLineHeightSt: {
      lineHeight: "100%",
    },

    left16St: { marginLeft: 16 },

    right15St: { marginRight: 15 },

    lightSt: {
      fontFamily: fonts.SantanderTextLight,
      fontWeight: 100,
    },
    regularSt: {
      fontFamily: fonts.SantanderTextRegular,
    },
    boldSt: {
      fontFamily: fonts.SantanderTextRegular,
      fontWeight: 700,
    },
    italicSt: {
      fontFamily: fonts.SantanderTextItalic,
      fontWeight: 100,
    },
    headlineRegularSt: {
      fontFamily: fonts.SantanderHeadlineRegular,
    },
    headlineBoldSt: {
      fontFamily: fonts.SantanderHeadlineBold,
    },

    capitalizeSt: {
      textTransform: "capitalize",
    },

    uppercaseSt: {
      textTransform: "uppercase",
    },

    whiteSt: {
      color: colors.white,
    },
    blackSt: {
      color: colors.black,
    },
    black2St: {
      color: colors.black2,
    },
    blackLightSt: {
      color: colors.blackLight,
    },
    grey2St: {
      color: colors.grey2,
    },
    greyTextSt: {
      color: colors.greyText,
    },
    redSt: {
      color: colors.red,
    },
    greenSt: {
      color: colors.green,
    },

    font10St: {
      fontSize: 10,
      lineHeight: "10px",
    },
    font12St: {
      fontSize: 12,
      lineHeight: "18px",
    },
    font13St: {
      fontSize: 13,
      lineHeight: "18px",
    },
    font14St: {
      fontSize: 14,
      lineHeight: "18px",
    },
    font15St: {
      fontSize: 15,
      lineHeight: "22px",
    },
    font16St: {
      fontSize: 16,
      lineHeight: "23px",
    },
    font18St: {
      fontSize: 18,
      lineHeight: "25px",
    },
    font24St: {
      fontSize: 24,
      lineHeight: "34px",
    },
    font28St: {
      fontSize: 28,
      lineHeight: "38px",
    },
    font32St: {
      fontSize: 32,
      lineHeight: "38px",
    },

    ellipsisSt: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },

    h1St: {
      fontSize: 32,
      fontFamily: fonts.regularSt,
      fontWeight: 300,
      lineHeight: "42px",
    },

    h3St: {
      fontSize: 24,
      fontFamily: fonts.SantanderTextRegular,
      fontWeight: 700,
      lineHeight: "36px",
    },

    titleHeaderSt: {
      fontSize: 28,
      fontFamily: fonts.SantanderTextLight,
      lineHeight: "40px",
      color: colors.black2,
      textAlign: "center",
      marginTop: 10,
      marginBottom: 15,
    },

    notActivebuttonSt: {
      textAlign: "center",
      width: "42%",
      fontWeight: 400,
      padding: "13px 7px",
      borderRadius: 5,
    },
    activebuttonSt: {
      color: colors.green,
      backgroundColor: "white",
      transition: "all .5s",
      boxShadow: "1px 1px 2px rgba(155, 195, 211, 0.3)",
      textAlign: "center",
      width: "42%",
      fontWeight: 800,
    },

    labelSt: {
      fontSize: 16,
      fontFamily: fonts.SantanderTextRegular,
      fontWeight: 400,
      lineHeight: "20px",
      color: colors.blackLight,
    },

    normalSt: {
      fontSize: 14,
      fontFamily: fonts.SantanderTextRegular,
      fontWeight: 400,
      lineHeight: "18px",
      color: colors.blackLight,
    },

    smallerSt: {
      fontSize: 13,
      fontFamily: fonts.SantanderTextRegular,
      fontWeight: 400,
      lineHeight: "13px",
    },

    smallerBillSt: {
      fontSize: 13,
      fontFamily: fonts.SantanderTextRegular,
      fontWeight: 400,
      lineHeight: "20px",
      color: colors.greyText2,
    },

    giveBackSt: {
      fontSize: 10,
      fontFamily: fonts.SantanderTextRegular,
      fontWeight: 700,
      lineHeight: "16px",
      textTransform: "uppercase",
      color: colors.violet,
      borderRadius: 2,
      border: "1px solid " + colors.violet,
      marginTop: 10,
      padding: "0 9px",
    },

    ...commonStyles.styles,
  },
});
