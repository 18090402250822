import { UiWrapper } from "coinscrap-webapp-core";
import colors from "../colors";
import commonStyles from "./common";

UiWrapper.defineComponent("view", {
  styles: {
    default: {
      // width: "100%",
      color: colors.white,
      boxSizing: "border-box",
    },

    horizontalSt: {
      flexDirection: "row",
    },

    startSt: {
      justifyContent: "flex-start",
    },

    endSt: {
      justifyContent: "flex-end",
    },

    centerSt: {
      justifyContent: "center",
    },

    spaceBetweenSt: {
      justifyContent: "space-between",
    },

    spaceEvenlySt: {
      justifyContent: "space-evenly",
      alignItems: "center",
    },

    topSt: {
      alignItems: "flex-start",
    },

    bottomSt: {
      alignItems: "flex-end",
    },

    selfStartSt: {
      alignSelf: "flex-start",
    },

    selfEndSt: {
      alignSelf: "flex-end",
    },

    selfCenterSt: {
      alignSelf: "center",
    },

    textCenterSt: {
      textAlign: "center",
    },

    fullHeightSt: {
      height: "100%",
    },

    fullWidthSt: {
      width: "100%",
    },

    width80St: {
      width: "80%",
    },

    width90St: {
      width: "90%",
    },

    width92St: {
      width: "92%",
    },

    width95St: {
      width: "95%",
    },

    autoWidthSt: {
      width: "auto",
    },

    interitSt: {
      width: "inherit",
    },

    containerSt: {
      height: "100%",
    },

    shrinkSt: {
      flexShrink: 1,
    },

    noBorderSt: {
      border: 0,
    },

    horizontal16St: {
      margin: "0 16px",
    },

    horizontal24St: {
      margin: "0 24px",
    },

    horizontalPadding24St: {
      padding: "0 24px",
    },

    borderRadiusTopSt: {
      borderRadius: "6px 6px 0px 0px",
    },

    gridSt: {
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
      gap: "10px",
    },

    modalSt: {
      backgroundColor: colors.white,
      width: "100%",
      height: "90%",
      justifyContent: "space-between",
      position: "absolute",
      bottom: 0,
      borderRadius: "6px 6px 0px 0px",
    },

    flex025St: { flex: 0.25 },
    flexSt: { flex: 1 },
    flex2St: { flex: 2 },
    flex3St: { flex: 3 },

    whiteBcSt: {
      backgroundColor: colors.white,
    },

    whiteBc2St: {
      backgroundColor: colors.whiteBc,
    },

    whiteOpacityBcSt: {
      backgroundColor: "rgba(255,255,255,0.92)",
    },

    grayBcSt: {
      backgroundColor: colors.greyBc,
    },

    grayBc2St: {
      backgroundColor: colors.greyBc2,
    },

    greenBcSt: {
      backgroundColor: colors.green,
    },

    footerSt: {
      borderTop: "1px solid #EAEAEA",
    },

    whiteGradientSt: {
      background:
        "linear-gradient(333.77deg, #FFFFFF 52.96%, #F4F4F4 100.79%, #E6F0F1 100.79%)",
    },

    whiteGradient2St: {
      background:
        "linear-gradient(180deg, rgba(250, 251, 252, 0.5) 0%, rgba(244, 246, 247, 0.5) 100%)",
    },

    whiteGradient3St: {
      background: "linear-gradient(333.77deg, #FFFFFF 52.96%, #F4F6F7 100.79%)",
    },

    relativeSt: {
      position: "relative",
    },

    gruposHeaderSt: {
      marginTop: 15,
      marginBottom: 12,
      padding: "0px 35px",
      alignItems: "center",
    },

    boxSt: {
      width: "90%",
      borderRadius: 5,
      backgroundColor: "#fff",
      borderColor: colors.greyLightBorders2,
      borderWidth: 1,
      borderStyle: "solid",
      margin: "5px 0px",
      padding: 0,
      boxShadow: "1px 1px 2px rgba(155, 195, 211, 0.3)",
    },

    borderTopSt: {
      borderTop: "1px solid " + colors.greyLightBorders,
      paddingTop: 3,
    },

    borderTop2St: {
      borderTop: "1px solid #EAEAEA",
    },

    borderBottomDashedSt: {
      borderBottom: "1px dashed #CCCCCC",
      marginBottom: 15,
      paddingBottom: 15,
    },

    borderDashedSt: {
      border: "1px dashed " + colors.greyLightBorders2,
      margin: "7px 0",
      height: 2,
      width: "100%",
    },

    wideWidthPeriodicSt: {
      width: "100%",
      marginBottom: 16,
    },

    tabWhiteSt: {
      padding: "5px 0px",
      justifyContent: "space-evenly",
      backgroundColor: colors.whiteBc,
      margin: "10px 0px",
      borderRadius: 5,
    },

    checkOptionSt: {
      backgroundColor: colors.white,
      border: "1px solid #DEE3E6",
      boxShadow: " 1px 2px 2px rgba(213, 213, 213, 0.35)",
      borderRadius: 5,
      marginBottom: 8,
      padding: "10px 5px 10px 15px",
    },

    imgBgSt: {
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    },

    topImgSt: {
      width: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      height: '30%',
    },

    topCircleSt: {
      width: '110%',
      height: 60,
      position: 'absolute',
      background: colors.whiteBc,
      marginTop: -20,
      borderTopRightRadius: '100%',
      borderTopLeftRadius: '100%',
    },

    imgCircleSt: {
      height: "102px",
      borderRadius: "50%",
      width: "102px",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      position: "relative",
    },

    tabGraphicSt: {
      width: 102,
      height: 132,
      zIndex: 12,
      paddingBottom: 7,
      marginTop: -80,
    },

    graphicRedSt: {
      width: 38,
      mixBlendMode: "normal",
      borderRadius: "4px 4px 0px 0px",
      opacity: 0.96,
      backgroundColor: colors.red2,
      background: "linear-gradient(0deg, #DC4F4F -160.27%, #F6A4A4 136.67%)",
      transform: "matrix(-1, 0, 0, 1, 0, 0)",
    },

    tabGraphicSelectedSt: {
      width: 102,
      height: 132,
      backgroundColor: colors.white,
      border: "1px solid " + colors.green,
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: 5,
      zIndex: 5,
      // margin: "0 8px",
      paddingBottom: 7,
    },

    progressBarSt: {
      height: 3,
      backgroundColor: colors.greyLightBorders,
      borderRadius: 2.5,
    },

    billSt: {
      borderRadius: 0,
      padding: 16,
    },

    selectPocketBoxSt: {
      border: "1px solid #DEE3E6",
      boxSizing: "border-box",
      boxShadow: "1px 2px 2px rgba(213, 213, 213, 0.35)",
      borderRadius: "5px",
      padding: "10px 26px 10px 12px",
    },
    selectPocketBoxActiveSt: {
      backgroundColor: "#137E84",
    },

    ...commonStyles.styles,
  },
});
