import React, { useEffect, useState } from "react";

import {
  UiWrapper,
  NavigationWrapper,
  View,
  Text,
  PickerHOC,
  Layout,
} from "coinscrap-webapp-core";

import Footer from "../common/Footer";
import ButtonFooterDouble from "../common/ButtonFooterDouble";
import Steps from "../common/Steps";
import colors from "../../config/colors";
import routes from "../../config/routes";
import BillIcon from "../common/svgs/BillIcon.js";
import FlameGreyIcon from "../common/svgs/FlameGreyIcon.js";
import BillGreyIcon from "../common/svgs/BillGreyIcon.js";
import UmbrellaGreyIcon from "../common/svgs/UmbrellaGreyIcon.js";
import CreateExpense from "./components/CreateExpense";
import { ExpensesCheck } from "../common/ExpensesCheck";

export default PickerHOC()(() => {
  const [expenses, setExpenses] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);

  const { useBackground, useFooter } = UiWrapper.use();
  const { navigateTo, useBackRoute } = NavigationWrapper.use();

  const suministros = [
    {
      name: "Orange Espagne S.a.u-orange Espagn…",
      last: "15 May 2020",
      value: -40.37,
    },
    {
      name: "Iberdrola Clientes, S.a.u",
      last: "3 Jul 2020",
      value: -96.3,
    },
    {
      name: "Endesa Energia Sau",
      last: "1 Ene 2020",
      value: -7.72,
    },
  ];

  const impuestos = [
    {
      name: "Ayuntamiento De Boadilla Del Monte…",
      last: "20 May 2020",
      value: -163.37,
    },
    {
      name: "Iberdrola Clientes, S.a.u",
      last: "3 Jul 2020",
      value: -96.3,
    },
  ];

  const seguros = [
    {
      name: " Caja De Seguros Reunidos, S.a. Caser",
      last: "20 May 2020",
      value: -163.37,
    },
    {
      name: "Linea Directa A, Madrid",
      last: "3 Jul 2020",
      value: -96.3,
    },
    {
      name: "Almudena Seguros",
      last: "3 Jul 2020",
      value: -96.3,
    },
  ];

  useEffect(() => {
    // console.log(taxesList.suministros.list)
  });

  useBackRoute(routes.expenseMortgage);

  useBackground(colors.white);

  useFooter(
    <Footer>
      <ButtonFooterDouble
        icon1
        // Pasar datos y añadir los expenses
        action2={() => navigateTo(routes.expenseCommunity)}
      />
    </Footer>,
    [expenses]
  );
  return (
    <Layout.Container>
      <View horizontal24St topSt>
        <Steps step={4} total={4} />
        <View width95St topSt style={{ marginBottom: 10 }}>
          <BillIcon />
          <Text font28St style={{ margin: "10px 0px" }}>
            Recibos e impuestos
          </Text>
          <Text font16St>
            Hemos encontrado estos productos. Si lo necesitas puedes buscarlos
            manualmente o añadir los que quieras.
          </Text>
        </View>
      </View>
      <View
        flexSt
        startSt
        fullWidthSt
        fullHeightSt
        grayBc2St
        borderTop2St
        style={{ padding: "0px 16px 19px" }}
      >
        {/* SUMINISTROS */}
        {suministros.length > 0 && (
          <View fullWidthSt grayBc2St>
            <View
              fullWidthSt
              topSt
              startSt
              horizontalSt
              style={{ margin: "15px 0px" }}
            >
              <FlameGreyIcon style={{ marginRight: 10 }} />
              <View topSt>
                <Text font18St boldSt>
                  Suministros
                </Text>
                <Text font16St>Luz, agua, gas, telefonía…</Text>
              </View>
            </View>
            {suministros.map((element) => {
              return (
                <ExpensesCheck
                  item={element}
                  data={expenses}
                  setData={setExpenses}
                />
              );
            })}
            <CreateExpense
              visibleModal={visibleModal}
              setVisibleModal={setVisibleModal}
            />
          </View>
        )}
      </View>
      {/* IMPUESTOS */}
      <View
        flexSt
        startSt
        fullWidthSt
        fullHeightSt
        whiteBcSt
        borderTop2St
        style={{ padding: "0px 16px 19px" }}
      >
        {impuestos.length > 0 && (
          <View fullWidthSt borderTop2St whiteBcSt>
            <View
              width90St
              topSt
              startSt
              horizontalSt
              style={{ margin: "15px 0px" }}
            >
              <BillGreyIcon style={{ marginRight: 10 }} />
              <View topSt>
                <Text font18St boldSt>
                  Impuestos
                </Text>
                <Text font16St>La tasa de basuras, el IBI…</Text>
              </View>
            </View>
            {impuestos.map((element) => {
              return (
                <ExpensesCheck
                  item={element}
                  data={expenses}
                  setData={setExpenses}
                />
              );
            })}
            <CreateExpense
              visibleModal={visibleModal}
              setVisibleModal={setVisibleModal}
            />
          </View>
        )}
      </View>
      {/* SEGUROS */}
      <View
        flexSt
        startSt
        fullWidthSt
        fullHeightSt
        grayBc2St
        borderTop2St
        style={{ padding: "0px 16px 19px" }}
      >
        {seguros.length > 0 && (
          <View fullWidthSt borderTop2St grayBc2St>
            <View
              width90St
              topSt
              startSt
              horizontalSt
              style={{ margin: "15px 0px" }}
            >
              <UmbrellaGreyIcon style={{ marginRight: 10 }} />
              <View topSt>
                <Text font18St boldSt>
                  Seguros
                </Text>
              </View>
            </View>
            {seguros.map((element) => {
              return (
                <ExpensesCheck
                  item={element}
                  data={expenses}
                  setData={setExpenses}
                />
              );
            })}
            <CreateExpense
              visibleModal={visibleModal}
              setVisibleModal={setVisibleModal}
            />
          </View>
        )}
      </View>
    </Layout.Container>
  );
});
