import React from "react";

export default function Add(props) {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...props.style }}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.00083 5.00067L5.0015 0.733204C5.00155 0.328195 5.22511 -7.13905e-05 5.50084 0C5.77657 7.15537e-05 6.00005 0.328454 6 0.733463L5.99933 5.00083L10.2668 5.0015C10.6718 5.00155 11.0001 5.22511 11 5.50084C10.9999 5.77657 10.6715 6.00005 10.2665 6L5.99917 5.99933L5.9985 10.2668C5.99845 10.6718 5.77489 11.0001 5.49916 11C5.22343 10.9999 4.99995 10.6715 5 10.2665L5.00067 5.99917L0.733204 5.9985C0.328195 5.99845 -7.14371e-05 5.77489 0 5.49916C7.15071e-05 5.22343 0.328454 4.99995 0.733463 5L5.00083 5.00067Z"
        fill="#137E84"
      />
    </svg>
  );
}
