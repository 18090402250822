import React from "react";

import { View } from "coinscrap-webapp-core";

import CameraIcon from "./svgs/CameraIcon";
import MoreIcon from "./svgs/MoreIcon";
import EditIcon from "./svgs/EditIcon";
import colors from "../../config/colors";

export const CameraComposed = ({ value, onClick }) => (
  <>
    <View
      onClick={onClick}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: "url(" + value + ")",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: 78,
        height: 78,
        minHeight: 78,
        borderRadius: 100,
        boxShadow: 0,
        borderColor: colors.greyLightBorders2,
        borderWidth: 1.4,
        borderStyle: "solid",
      }}
    >
      <CameraIcon styleSVG={{ display: value ? "none" : "flex" }} />
      {/* <Button id='photoButton' pillSt style={{ width: 180, display: 'none' }}>
    ¡Elige una Foto!
  </Button> */}
    </View>
    <View
      onClick={onClick}
      style={{
        width: 27,
        height: 27,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.white,
        borderRadius: 100,
        boxShadow: 0,
        borderColor: colors.greyLightBorders2,
        borderWidth: 1.4,
        borderStyle: "solid",
        marginTop: -27,
        marginLeft: 51,
      }}
    >
      {value ? <EditIcon /> : <MoreIcon />}
    </View>
  </>
);
