import React from "react";

export default function ArrowDown(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="15"
      height="15"
      viewBox="0 0 55 49"
      style={{ ...props.style }}
    >
      <image
        id="Capa_1"
        data-name="Capa 1"
        y="10"
        width="55"
        height="31"
        href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAADcAAAAfCAYAAABDJFUdAAABVklEQVRYhdXZzW2DQBBA4QcVuIRcuKeFdJACkBIqcFLBmgpSQhSJe9xBWuDOJSWkhGgsHyxks38zsH631WpWfALthQqgccMBeAEegCPQTX37x53UuOERcMAz8At8TX17qBo3fABvM8YIPN0D8Az7AXazrb6+ApNOA40b5gNFtQCT9vXCwxYN9MCkneCWPr0igQEwaRRc5zmrKGAgTF5YV099e7wXYARMLsOxuhh8BT495292i8bCZFFd7pQKTIExx1EgMBXGNRwFAXNg3MJRADAXxhKODYEaMHw4NgBqwQjBsSJQE0YojhWA2jBicBgCLWDE4jAAWsFIwaEItISRikMBaA0jB0cGcA0YuTgSgGvB0MARB3wHvteAoYUjHOhLDYYmjnygKgxtHOlAdRgWOOKBJjCscIQDzWBY4vADTWFY47gNNIdJ5jipcYP8fdmflwLrrWEA/6y7IqVaYyB+AAAAAElFTkSuQmCC"
      />
    </svg>
  );
}
