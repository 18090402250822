import React, { useState } from "react";

import {
  UiWrapper,
  NavigationWrapper,
  View,
  Text,
  PickerHOC,
  Layout,
  Enfatize,
} from "coinscrap-webapp-core";

import Footer from "../common/Footer";
import ButtonFooterDouble from "../common/ButtonFooterDouble";
import Steps from "../common/Steps";
import colors from "../../config/colors";
import routes from "../../config/routes";
import Hogar from "../../assets/images/hogar.png";
import Movilidad from "../../assets/images/movilidad.png";
import Estudios from "../../assets/images/estudios.png";
import Ocio from "../../assets/images/ocio.png";
import { useSave } from "../../libs/hooks/useSave";
export default PickerHOC()(() => {
  const [myData, saveMyData] = useSave();

  const [active, setActive] = useState("");

  const { useBackground, useFooter } = UiWrapper.use();
  const { navigateTo, useBackRoute } = NavigationWrapper.use();

  useBackground(colors.white);

  useBackRoute(routes.home);

  useFooter(
    <Footer>
      <ButtonFooterDouble
        icon1
        disabled2={!active}
        action2={async () =>
          // Pasar datos
          {
            await saveMyData({
              ...myData,
              creation: {
                ...myData.creation,
                category: active,
              },
            });
            navigateTo(routes.createPocketName, {
              queryParams: { category: active },
            });
          }
        }
      />
    </Footer>,
    [active]
  );
  return (
    <Layout.Container>
      <View horizontal24St topSt>
        <Steps step={1} total={4} />
        <View topSt style={{ margin: "0px 0px 23px" }}>
          <Text font28St style={{ margin: "10px 0px" }}>
            Estos son algunos <Enfatize blackLightSt>Pockets</Enfatize> que te
            pueden venir bien
          </Text>
          <Text font16St>
            Elige la categoría sobre la que quieres conocer mejor tus gastos y
            lleva las cuentas a otro nivel creando un pocket.
          </Text>
        </View>
        <View wideWidthPeriodicSt>
          <PocketBox
            image={Hogar}
            title="Mi Casa"
            text="Tu vivienda habitual, tu casa de la playa… todo los gastos de tu hogar aquí."
            active={active === "miCasa"}
            onClick={() => setActive("miCasa")}
          />
          <PocketBox
            image={Movilidad}
            title="Transporte"
            text="Gastos como gasolina, seguros, parkings, mantenimiento, reparación…"
            active={active === "transporte"}
            onClick={() => setActive("transporte")}
          />
          {/* <PocketBox
            image={EventoFamiliar}
            title="Evento"
            text="Que no se te escape nada en días importantes: catering, música, local, transporte, decoración…"
            active={active === "eventoFamiliar"}
            onClick={() => setActive("eventoFamiliar")}
          /> */}
          {/* <PocketBox
            image={Estudios}
            title="Estudios"
            text="Todos los gastos asociados a la matrícula, el material, los cursos…entre otros."
            active={active === "estudios"}
            onClick={() => setActive("estudios")}
          /> */}
          {/* <PocketBox
            image={Mascotas}
            title="Mascotas"
            text="Todo para tu mascota: veterinario, alimentación y cuidados, seguro, etc."
            active={active === "mascotas"}
            onClick={() => setActive("mascotas")}
          /> */}
          {/* <PocketBox
            image={Trabajo}
            title="Trabajo"
            text="Lorem ipsum dolor sit amet, consectetur adipisicing elit sed."
            active={active === "trabajo"}
            onClick={() => setActive("trabajo")}
          /> */}
          <PocketBox
            image={Ocio}
            title="Ocio"
            text="Restauración, entretenimiento, cultura, naturaleza... ¡Las posibilidades son infinitas!"
            active={active === "ocio"}
            onClick={() => setActive("ocio")}
          />
          {/* <PocketBox
            image={Hobby}
            title="Hobby"
            text="Lleva las cuentas de lo que más te gusta: deporte, arte, lectura…"
            active={active === "hobby"}
            onClick={() => setActive("hobby")}
          /> */}
          {/* <PocketBox
            image={Viajes}
            title="Viajes"
            text="Visualiza gastos como transporte, alojamientos y desplazamientos, manutención, tasas…"
            active={active === "viajes"}
            onClick={() => setActive("viajes")}
          /> */}
        </View>
      </View>
    </Layout.Container>
  );
});

const PocketBox = ({ image, title, text, active, onClick }) => {
  return (
    <View
      horizontalSt
      startSt
      selectPocketBoxSt
      style={{ marginBottom: 16 }}
      selectPocketBoxActiveSt={!!active}
      onClick={onClick}
    >
      <View
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: 88,
          width: 88,
          marginRight: 16,
        }}
      />
      <View autoWidthSt topSt style={{ width: 190 }}>
        <Text font16St boldSt whiteSt={!!active}>
          {title}
        </Text>
        <Text font14St whiteSt={!!active}>
          {text}
        </Text>
      </View>
    </View>
  );
};
