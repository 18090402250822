export const gallery = [
  {
    key: 'Abono fútbol',
    weight: '2',
    images: ['abonofutbol1.jpg', 'abonofutbol2.jpg'],
  },
  {
    key: 'Acciones',
    weight: '1',
    images: ['acciones1.jpg', 'acciones2.jpg'],
  },
  {
    key: 'Ahorrar',
    weight: '2',
    images: ['ahorrar1.jpg', 'ahorrar2.jpg', 'ahorrar3.jpg'],
  },
  {
    key: 'Ahorro',
    weight: '2',
    images: ['ahorro1.jpg', 'ahorro2.jpg', 'ahorro3.jpg'],
  },
  {
    key: 'Air jordan',
    weight: '3',
    images: ['airjordan1.jpg'],
  },
  {
    key: 'Airpods',
    weight: '3',
    images: ['airpods1.jpg', 'airpods2.jpg'],
  },
  {
    key: 'Alemania',
    weight: '3',
    images: ['alemania1.jpg', 'alemania2.jpg'],
  },
  {
    key: 'Alexa',
    weight: '3',
    images: ['alexa1.jpg'],
  },
  {
    key: 'Alquiler piso',
    weight: '2',
    images: ['alquilerpiso1.jpg'],
  },
  {
    key: 'Altavoces',
    weight: '2',
    images: ['altavoces1.jpg'],
  },
  {
    key: 'Amazon',
    weight: '3',
    images: ['amazon1.jpg'],
  },
  {
    key: 'Amberes',
    weight: '3',
    images: ['amberes1.jpg'],
  },
  {
    key: 'América',
    weight: '3',
    images: ['america1.jpg'],
  },
  {
    key: 'Amortización',
    weight: '1',
    images: ['amortizacion1.jpg'],
  },
  {
    key: 'Amsterdam',
    weight: '3',
    images: ['amsterdam1.jpg', 'amsterdam2.jpg'],
  },
  {
    key: 'Anillo',
    weight: '2',
    images: ['anillo1.jpg', 'anillo2.jpg'],
  },
  {
    key: 'Anillo de compromiso',
    weight: '2',
    images: ['anillocompromiso1.jpg', 'anillocompromiso2.jpg'],
  },
  {
    key: 'Aniversario',
    weight: '2',
    images: ['aniversario1.jpg'],
  },
  {
    key: 'Apple',
    weight: '3',
    images: ['apple1.jpg', 'apple2.jpg'],
  },
  {
    key: 'Apple Watch',
    weight: '3',
    images: ['applewatch1.jpg', 'applewatch2.jpg'],
  },
  {
    key: 'Argentina',
    weight: '3',
    images: ['argentina1.jpg', 'argentina2.jpg'],
  },
  {
    key: 'Asia',
    weight: '3',
    images: ['asia1.jpg', 'asia2.jpg', 'asia3.jpg'],
  },
  {
    key: 'Aspirador',
    weight: '2',
    images: ['aspirador1.jpg', 'aspirador2.jpg'],
  },
  {
    key: 'Athletic',
    weight: '3',
    images: ['athletic1.jpg'],
  },
  {
    key: 'Atlético de Madrid',
    weight: '3',
    images: ['atleticodemadrid1.jpg'],
  },
  {
    key: 'Audi',
    weight: '3',
    images: ['audi1.jpg', 'audi2.jpg'],
  },
  {
    key: 'Auriculares',
    weight: '2',
    images: ['auriculares1.jpg', 'auriculares2.jpg'],
  },
  {
    key: 'Australia',
    weight: '3',
    images: ['australia1.jpg', 'australia2.jpg'],
  },
  {
    key: 'Austria',
    weight: '3',
    images: ['austria1.jpg', 'austria2.jpg'],
  },
  {
    key: 'Autocaravana',
    weight: '3',
    images: ['autocaravana1.jpg', 'autocaravana2.jpg', 'autocaravana2.jpg'],
  },
  {
    key: 'Autoescuela',
    weight: '2',
    images: ['autoescuela1.jpg', 'autoescuela2.jpg'],
  },
  {
    key: 'Aventura',
    weight: '1',
    images: ['aventura1.jpg', 'aventura2.jpg'],
  },
  {
    key: 'Avión',
    weight: '2',
    images: ['avion1.jpg', 'avion2.jpg'],
  },
  {
    key: 'Baby',
    weight: '2',
    images: ['baby1.jpg', 'baby2.jpg'],
  },
  {
    key: 'Bali',
    weight: '3',
    images: ['bali1.jpg', 'bali2.jpg'],
  },
  {
    key: 'Bañera',
    weight: '2',
    images: ['bañera1.jpg', 'bañera2.jpg'],
  },
  {
    key: 'Baño',
    weight: '2',
    images: ['baño1.jpg', 'baño2.jpg'],
  },
  {
    key: 'Baqueira',
    weight: '3',
    images: ['baqueira1.jpg', 'baqueira2.png'],
  },
  {
    key: 'Bar',
    weight: '2',
    images: ['bar1.jpg', 'bar2.jpg'],
  },
  {
    key: 'Barbacoa',
    weight: '2',
    images: ['barbacoa1.jpg', 'barbacoa2.jpg'],
  },
  {
    key: 'Barbería',
    weight: '2',
    images: ['barberia1.jpg', 'barberia2.jpg'],
  },
  {
    key: 'Barcelona',
    weight: '3',
    images: ['barcelona1.jpg', 'barcelona2.jpg'],
  },
  {
    key: 'Barco',
    weight: '2',
    images: ['barco1.jpg', 'barco2.jpg'],
  },
  {
    key: 'Bautizo',
    weight: '2',
    images: ['bautizo1.jpg', 'bautizo2.jpg'],
  },
  {
    key: 'BBQ',
    weight: '2',
    images: ['bbq1.jpg', 'bbq2.jpg'],
  },
  {
    key: 'Bebe',
    weight: '1',
    images: ['bebe1.jpg', 'bebe1.jpg'],
  },
  {
    key: 'Berlín',
    weight: '3',
    images: ['berlin1.jpg', 'berlin2.jpg'],
  },
  {
    key: 'Betis',
    weight: '3',
    images: ['betis1.jpg'],
  },
  {
    key: 'Bicicleta',
    weight: '2',
    images: ['bicicleta1.jpg', 'bicicleta2.jpg'],
  },
  {
    key: 'Birmania',
    weight: '3',
    images: ['birmania1.jpg', 'birmania2.jpg'],
  },
  {
    key: 'BMW',
    weight: '3',
    images: ['bmw1.jpg', 'bmw2.jpg'],
  },
  {
    key: 'Boda',
    weight: '3',
    images: ['boda1.jpg', 'boda2.jpg', 'boda3.jpg'],
  },
  {
    key: 'Bolivia',
    weight: '3',
    images: ['bolivia1.jpg', 'bolivia2.jpg'],
  },
  {
    key: 'Bolso',
    weight: '2',
    images: ['bolso1.jpg', 'bolso2.jpg'],
  },
  {
    key: 'Boston',
    weight: '3',
    images: ['boston1.jpg', 'boston2.jpg'],
  },
  {
    key: 'Brasil',
    weight: '3',
    images: ['brasil1.jpg', 'brasil2.jpg'],
  },
  {
    key: 'Bucarest',
    weight: '3',
    images: ['bucarest1.jpg'],
  },
  {
    key: 'Buceo',
    weight: '2',
    images: ['buceo1.jpg', 'buceo2.jpg'],
  },
  {
    key: 'Caballo',
    weight: '2',
    images: ['caballo1.jpg', 'caballo2.jpg'],
  },
  {
    key: 'Cadillac',
    weight: '3',
    images: ['cadillac1.jpg', 'cadillac2.jpg'],
  },
  {
    key: 'Cádiz',
    weight: '3',
    images: ['cadiz1.jpg', 'cadiz2.jpg'],
  },
  {
    key: 'Cafetera',
    weight: '2',
    images: ['cafetera1.jpg', 'cafetera2.jpg'],
  },
  {
    key: 'Caida libre',
    weight: '2',
    images: ['caidalibre1.jpg', 'caidalibre2.jpg'],
  },
  {
    key: 'California',
    weight: '3',
    images: ['california1.jpg', 'california2.jpg'],
  },
  {
    key: 'Cámara',
    weight: '2',
    images: ['camara1.jpg', 'camara2.jpg'],
  },
  {
    key: 'Camboya',
    weight: '3',
    images: ['camboya.jpg', 'Camboya1.jpg'],
  },
  {
    key: 'Camiseta baloncesto',
    weight: '2',
    images: ['camisetabaloncesto.jpg', 'camisetabaloncesto1.jpg'],
  },
  {
    key: 'Camiseta fútbol',
    weight: '2',
    images: [
      'camisetafutbol.jpg',
      'camisetafutbol1.jpg',
      'camisetafutbol2.jpg',
    ],
  },
  {
    key: 'Camper',
    weight: '3',
    images: ['camper.jpg', 'camper1.jpg'],
  },
  {
    key: 'Camping',
    weight: '2',
    images: ['camping.jpg', 'camping1.jpg'],
  },
  {
    key: 'Canadá',
    weight: '3',
    images: ['canada.jpg', 'canada1.jpg'],
  },
  {
    key: 'Canarias',
    weight: '3',
    images: ['canarias.jpg', 'canarias1.jpg'],
  },
  {
    key: 'Cancún',
    weight: '3',
    images: ['cancun.jpg', 'cancun1.jpg', 'cancun2.jpg'],
  },
  {
    key: 'Capri',
    weight: '3',
    images: ['capri.jpg', 'capri1.jpg'],
  },
  {
    key: 'Capricho',
    weight: '1',
    images: ['capricho.jpg', 'capricho1.jpg'],
  },
  {
    key: 'Caravana',
    weight: '3',
    images: ['caravana.jpg', 'caravana1.jpg'],
  },
  {
    key: 'Caribe',
    weight: '3',
    images: ['caribe.jpg', 'caribe1.jpg', 'caribe2.jpg'],
  },
  {
    key: 'Carné',
    weight: '1',
    images: ['carne.jpg.jpg', 'carne1.jpg.jpg'],
  },
  {
    key: 'Carnet',
    weight: '1',
    images: ['carnet.jpg', 'carnet1.jpg'],
  },
  {
    key: 'Carnet de conducir',
    weight: '2',
    images: ['carnetdeconducir.jpg', 'carnetdeconducir1.jpg'],
  },
  {
    key: 'Carrito',
    weight: '1',
    images: ['carrito.jpg', 'carrito1.jpg'],
  },
  {
    key: 'Casa',
    weight: '2',
    images: ['casa.jpg', 'casa1.jpg', 'casa2.jpg'],
  },
  {
    key: 'Casa de la playa',
    weight: '2',
    images: ['casaplaya.jpg', 'casaplaya1.jpg'],
  },
  {
    key: 'Casa de la sierra',
    weight: '2',
    images: ['casasierra.jpg', 'casasierra1.jpg'],
  },
  {
    key: 'Casa Rural',
    weight: '2',
    images: ['casarural.jpg', 'casarural1.jpg'],
  },
  {
    key: 'Casco',
    weight: '1',
    images: ['casco.jpg', 'casco1.jpg', 'casco2.jpg'],
  },
  {
    key: 'Cascos',
    weight: '1',
    images: ['cascos.jpg', 'cascos1.jpg', 'cascos2.jpg'],
  },
  {
    key: 'Cena navidad',
    weight: '2',
    images: ['cenanavidad.jpg', 'cenanavidad1.jpg', 'cenanavidad2.jpg'],
  },
  {
    key: 'Cerveza',
    weight: '2',
    images: ['cerveza.jpg', 'cerveza1.jpg', 'cerveza2.jpg'],
  },
  {
    key: 'Chalet',
    weight: '2',
    images: ['chalet.jpg', 'chalet1.jpg'],
  },
  {
    key: 'China',
    weight: '3',
    images: ['china.jpg', 'china1.jpg', 'china2.jpg'],
  },
  {
    key: 'Cirugía',
    weight: '2',
    images: ['cirugia.jpg', 'cirugia1.jpg'],
  },
  {
    key: 'Coche',
    weight: '2',
    images: ['coche.jpg', 'coche1.jpg', 'coche2.jpg'],
  },
  {
    key: 'Coche eléctrico',
    weight: '3',
    images: ['cocheelectrico.jpg', 'cocheelectrico1.jpg'],
  },
  {
    key: 'Cocina',
    weight: '2',
    images: ['cocina.jpg', 'cocina1.jpg', 'cocina2.jpg'],
  },
  {
    key: 'Colchón',
    weight: '1',
    images: ['colchon.jpg', 'colchon1.jpg', 'colchon2.jpg'],
  },
  {
    key: 'Colegio',
    weight: '2',
    images: ['colegio.jpg', 'colegio1.jpg', 'colegio2.jpg'],
  },
  {
    key: 'Colombia',
    weight: '3',
    images: ['colombia.jpg', 'colombia1.jpg', 'colombia2.jpg'],
  },
  {
    key: 'Comida navidad',
    weight: '2',
    images: ['comidanavidad.jpg', 'comidanavidad1.jpg', 'comidanavidad2.jpg'],
  },
  {
    key: 'Comunión',
    weight: '3',
    images: ['comunion.jpg', 'comunion1.jpg'],
  },
  {
    key: 'Concierto',
    weight: '2',
    images: ['concierto.jpg', 'concierto1.jpg', 'concierto2.jpg'],
  },
  {
    key: 'Confinamiento',
    weight: '2',
    images: ['confinamiento.jpg', 'confinamiento1.jpg'],
  },
  {
    key: 'Consola',
    weight: '2',
    images: ['consola.jpg', 'consola1.jpg', 'consola2.jpg'],
  },
  {
    key: 'Copas',
    weight: '2',
    images: ['copas.jpg', 'copas1.jpg', 'copas2.jpg'],
  },
  {
    key: 'Copenhague',
    weight: '3',
    images: ['copenhague.jpg', 'copenhague1.jpg'],
  },
  {
    key: 'Costa Rica',
    weight: '3',
    images: ['costarica.jpg', 'costarica1.jpg'],
  },
  {
    key: 'Crossfit',
    weight: '3',
    images: ['crossfit.jpg', 'crossfit1.jpg', 'crossfit2.jpg'],
  },
  {
    key: 'Crucero',
    weight: '3',
    images: ['crucero.jpg', 'crucero1.jpg', 'crucero2.jpg'],
  },
  {
    key: 'Cuba',
    weight: '3',
    images: ['cuba.jpg', 'cuba1.jpg', 'cuba2.jpg'],
  },
  {
    key: 'Cuenta conjunta',
    weight: '1',
    images: ['cuentaconjunta.jpg'],
  },
  {
    key: 'Cumpleaños',
    weight: '2',
    images: ['cumpleaños.jpg', 'cumpleaños1.jpg', 'cumpleaños2.jpg'],
  },
  {
    key: 'Decoración',
    weight: '1',
    images: ['decoración.jpg', 'decoración1.jpg', 'decoración2.jpg'],
  },
  {
    key: 'Dejar de fumar',
    weight: '1',
    images: ['dejardefumar.jpg', 'dejardefumar1.jpg'],
  },
  {
    key: 'Dentista',
    weight: '3',
    images: ['dentista.jpg', 'dentista1.jpg'],
  },
  {
    key: 'Descapotable',
    weight: '2',
    images: ['descapotable.jpg', 'descapotable1.jpg', 'descapotable2.jpg'],
  },
  {
    key: 'Despedida',
    weight: '1',
    images: ['despedida.jpg', 'despedida1.jpg', 'despedida2.jpg'],
  },
  {
    key: 'Deudas',
    weight: '1',
    images: ['deudas.jpg', 'deudas1.jpg'],
  },
  {
    key: 'Diamante',
    weight: '2',
    images: ['diamante.jpg', 'diamante1.jpg'],
  },
  {
    key: 'Dientes',
    weight: '2',
    images: ['dientes.jpg', 'dientes1.jpg', 'dientes2.jpg'],
  },
  {
    key: 'Disney',
    weight: '3',
    images: ['disney.jpg', 'disney1.jpg', 'disney2.jpg'],
  },
  {
    key: 'Disneyland',
    weight: '3',
    images: ['disneyland.jpg', 'disneyland1.jpg'],
  },
  {
    key: 'Disneyworld',
    weight: '3',
    images: ['disneyworld.jpg', 'disneyworld1.jpg', 'disneyworld2.jpg'],
  },
  {
    key: 'Dron',
    weight: '3',
    images: ['dron.jpg', 'dron1.jpg', 'dron2.jpg'],
  },
  {
    key: 'Dublin',
    weight: '3',
    images: ['dublin.jpg', 'dublin1.jpg', 'dublin2.jpg'],
  },
  {
    key: 'Ecuador',
    weight: '3',
    images: ['ecuador.jpg', 'ecuador1.jpg'],
  },
  {
    key: 'EEUU',
    weight: '3',
    images: ['eeuu.jpg', 'eeuu1.jpg', 'eeuu2.jpg'],
  },
  {
    key: 'Emergencia',
    weight: '2',
    images: ['emergencia.jpg', 'emergencia1.jpg', 'emergencia2.jpg'],
  },
  {
    key: 'Empresa',
    weight: '2',
    images: ['empresa.jpg', 'empresa1.jpg', 'empresa2.jpg'],
  },
  {
    key: 'Entrada',
    weight: '1',
    images: ['entrada.jpg', 'entrada1.jpg', 'entrada2.jpg'],
  },
  {
    key: 'Entrada casa',
    weight: '1',
    images: ['entradacasa.jpg', 'entradacasa1.jpg'],
  },
  {
    key: 'Entrada coche',
    weight: '1',
    images: ['entradacoche.jpg', 'entradacoche1.jpg'],
  },
  {
    key: 'Entrada piso',
    weight: '1',
    images: ['entradapiso.jpg', 'entradapiso1.jpg'],
  },
  {
    key: 'Erasmus',
    weight: '2',
    images: ['erasmus.jpg', 'erasmus1.jpg', 'erasmus2.jpg'],
  },
  {
    key: 'Escapada',
    weight: '2',
    images: ['escapada.jpg', 'escapada1.jpg', 'escapada2.jpg'],
  },
  {
    key: 'Espectáculo',
    weight: '2',
    images: ['espectaculo.jpg', 'espectaculo1.jpg', 'espectaculo2.jpg'],
  },
  {
    key: 'Esqui',
    weight: '2',
    images: ['esqui1.jpg', 'esqui2.jpg'],
  },
  {
    key: 'Estudios',
    weight: '1',
    images: ['estudios1.jpg', 'estudios2.jpg'],
  },
  {
    key: 'Europa',
    weight: '3',
    images: ['europa1.jpg', 'europa2.jpg'],
  },
  {
    key: 'Extranjero',
    weight: '2',
    images: ['extranjero1.jpg'],
  },
  {
    key: 'Familia',
    weight: '2',
    images: ['familia1.jpg'],
  },
  {
    key: 'Feria',
    weight: '1',
    images: ['feria1.jpg'],
  },
  {
    key: 'Ferrari',
    weight: '3',
    images: ['ferrari1.jpg'],
  },
  {
    key: 'Festival',
    weight: '2',
    images: ['festival1.jpg'],
  },
  {
    key: 'Fianza',
    weight: '1',
    images: ['fianza1.jpg'],
  },
  {
    key: 'Fiat',
    weight: '3',
    images: ['fiat1.jpg'],
  },
  {
    key: 'Fiesta',
    weight: '2',
    images: ['fiesta.jpg'],
  },

  {
    key: 'Finca',
    weight: '2',
    images: ['finca1.jpg'],
  },
  {
    key: 'Florencia',
    weight: '3',
    images: ['florencia1.jpg'],
  },
  {
    key: 'Fondo',
    weight: '1',
    images: ['fondo1.jpg'],
  },
  {
    key: 'Fotografía',
    weight: '2',
    images: ['fotografia1.jpg'],
  },
  {
    key: 'Frigorífico',
    weight: '2',
    images: ['frigorifico1.jpg'],
  },
  {
    key: 'Furgoneta',
    weight: '3',
    images: ['furgoneta1.jpg'],
  },
  {
    key: 'Fútbol',
    weight: '2',
    images: ['futbol1.jpg', 'futbol2.jpg'],
  },
  {
    key: 'Futuro',
    weight: '1',
    images: ['futuro1.jpg'],
  },
  {
    key: 'Gafas',
    weight: '2',
    images: ['gafas1.jpg'],
  },
  {
    key: 'Garaje',
    weight: '2',
    images: ['garaje1.jpg'],
  },
  {
    key: 'Gasolina',
    weight: '2',
    images: ['gasolina1.jpg'],
  },
  {
    key: 'Gastos',
    weight: '1',
    images: ['gastos1.jpg'],
  },
  {
    key: 'Gimnasio',
    weight: '2',
    images: ['gimnasio1.jpg'],
  },
  {
    key: 'GoPro',
    weight: '3',
    images: ['gopro1.jpg'],
  },
  {
    key: 'Granada',
    weight: '3',
    images: ['granada.jpg'],
  },
  {
    key: 'Guatemala',
    weight: '3',
    images: ['guatemala.jpg'],
  },
  {
    key: 'Guitarra',
    weight: '2',
    images: ['guitarra1.jpg'],
  },
  {
    key: 'Gym',
    weight: '2',
    images: ['gym.jpg'],
  },
  {
    key: 'Hacienda',
    weight: '3',
    images: ['hacienda1.jpg'],
  },
  {
    key: 'Harley Davidson',
    weight: '3',
    images: ['harleydavinson1.jpg'],
  },
  {
    key: 'Hawaii',
    weight: '3',
    images: ['hawaii1.jpg'],
  },
  {
    key: 'Hipoteca',
    weight: '2',
    images: ['hipoteca.jpg'],
  },
  {
    key: 'Hogar',
    weight: '2',
    images: ['hogar.jpg'],
  },
  {
    key: 'Hong Kong',
    weight: '3',
    images: ['hongkong.jpg'],
  },
  {
    key: 'Hotel',
    weight: '2',
    images: ['hotel1.jpg'],
  },
  {
    key: 'Huawei',
    weight: '3',
    images: ['huawei1.jpg'],
  },
  {
    key: 'Hucha',
    weight: '2',
    images: ['hucha1.jpg'],
  },
  {
    key: 'Ibiza',
    weight: '3',
    images: ['ibiza1.jpg'],
  },
  {
    key: 'Ikea',
    weight: '3',
    images: ['ikea.jpg'],
  },
  {
    key: 'Impresora 3d',
    weight: '3',
    images: ['impresora3D1.jpg'],
  },
  {
    key: 'Imprevisto',
    weight: '1',
    images: ['imprevisto.jpg'],
  },
  {
    key: 'Independencia',
    weight: '1',
    images: ['independencia1.jpg'],
  },
  {
    key: 'Independizarme',
    weight: '1',
    images: ['independizarme1.jpg'],
  },
  {
    key: 'India',
    weight: '3',
    images: ['india1.jpg', 'india2.jpg'],
  },
  {
    key: 'Indonesia',
    weight: '3',
    images: ['indonesia1.jpg'],
  },
  {
    key: 'Injerto capilar',
    weight: '3',
    images: ['injertocapilar.jpg'],
  },
  {
    key: 'Interrail',
    weight: '2',
    images: ['interrail.jpg'],
  },
  {
    key: 'Inversión',
    weight: '1',
    images: ['inversion.jpg'],
  },
  {
    key: 'Ipad',
    weight: '3',
    images: ['ipad.jpg'],
  },
  {
    key: 'Iphone',
    weight: '3',
    images: ['iphone.jpg'],
  },
  {
    key: 'Irlanda',
    weight: '3',
    images: ['irlanda.jpg'],
  },
  {
    key: 'Isla',
    weight: '3',
    images: ['isla.jpg'],
  },
  {
    key: 'Islandia',
    weight: '3',
    images: ['islandia.jpg'],
  },
  {
    key: 'Italia',
    weight: '3',
    images: ['italia.jpg'],
  },
  {
    key: 'Iva',
    weight: '1',
    images: ['IVA1.jpg'],
  },
  {
    key: 'Japón',
    weight: '3',
    images: ['Japon1.jpg'],
  },
  {
    key: 'Jardín',
    weight: '2',
    images: ['Jardin1.jpg'],
  },
  {
    key: 'Jet privado',
    weight: '2',
    images: ['jetprivado.jpg'],
  },
  {
    key: 'Jubilación',
    weight: '2',
    images: ['jubilacion1.jpg', 'jubilacion2.jpg'],
  },
  {
    key: 'Juegos',
    weight: '2',
    images: ['juegos1.jpg'],
  },
  {
    key: 'Kayak',
    weight: '3',
    images: ['kayak1.jpg'],
  },
  {
    key: 'Kenia',
    weight: '3',
    images: ['kenia1.jpg'],
  },
  {
    key: 'Las vegas',
    weight: '3',
    images: ['lasvegas1.jpg'],
  },
  {
    key: 'Láser',
    weight: '2',
    images: ['laser1.jpg'],
  },
  {
    key: 'Lavadora',
    weight: '2',
    images: ['lavadora1.jpg'],
  },
  {
    key: 'Lentillas',
    weight: '2',
    images: ['lentillas1.jpg'],
  },
  {
    key: 'Levante',
    weight: '3',
    images: ['levante1.jpg'],
  },
  {
    key: 'Lexus',
    weight: '3',
    images: ['lexus.jpg', 'lexus-negro.jpg', 'lexus-blanco.jpg'],
  },
  {
    key: 'Libro',
    weight: '2',
    images: ['libro.jpg', 'libros.jpg', 'libro-abierto.jpg'],
  },
  {
    key: 'Londres',
    weight: '3',
    images: ['big-ben-london.jpg', 'london-bridge.jpg', 'London-cabin.jpg'],
  },
  {
    key: 'Mac',
    weight: '3',
    images: ['mac.jpg', 'mac-blanco.jpg', 'mac-gris.jpg'],
  },
  {
    key: 'MacBook',
    weight: '3',
    images: ['macbook.jpg', 'mackbook-gris.jpg', 'macbook-open.jpg'],
  },
  {
    key: 'MacBook Pro',
    weight: '3',
    images: ['mac-book-pro.jpg', 'macbook-pro.jpg', 'macbookpro.jpg'],
  },
  {
    key: 'Madrid',
    weight: '3',
    images: [
      'madrid-puerta.jpg',
      'madrid-gran-via.jpg',
      'madrid-plaza-mayor.jpg',
    ],
  },
  {
    key: 'Maldivas',
    weight: '3',
    images: ['Maldivas.jpg', 'Maldivas-playa.jpg', 'Maldivas-resort.jpg'],
  },
  {
    key: 'Marruecos',
    weight: '3',
    images: ['Marruecos.jpg', 'Marruecos-calle.jpg', 'Marruecos-desierto.jpg'],
  },
  {
    key: 'Mascota',
    weight: '2',
    images: ['cachorros.jpg', 'Gato.jpg', 'mascotas.jpg'],
  },
  {
    key: 'Máster',
    weight: '2',
    images: [
      'master-biblioteca.jpg',
      'master-graduacion.jpg',
      'master-estudio.jpg',
    ],
  },
  {
    key: 'Material',
    weight: '1',
    images: [
      'material-rotus.jpg',
      'material-lapices.jpg',
      'material-escolar.jpg',
    ],
  },
  {
    key: 'Matrícula',
    weight: '1',
    images: [
      'matricula-estudio.jpg',
      'matricula-trabajo.jpg',
      'matricula-universidad.jpg',
    ],
  },
  {
    key: 'Menorca',
    weight: '3',
    images: ['menorca-mar.jpg', 'menorca-cala.jpg', 'menorca-playa.jpg'],
  },
  {
    key: 'Mercedes-Benz',
    weight: '3',
    images: ['mercedes.jpg', 'mercedes-frontal.jpg', 'mercedes-volante.jpg'],
  },
  {
    key: 'Miami',
    weight: '3',
    images: ['miami-barco.jpg', 'miami.jpg', 'miami-playa.jpg'],
  },
  {
    key: 'Mini cooper',
    weight: '3',
    images: ['mini-trasero.jpg', 'mini.jpg'],
  },
  {
    key: 'Monedero',
    weight: '2',
    images: [
      'monedero-cartera.jpg',
      'monedero-cuero.jpg',
      'monedero-marron.jpg',
    ],
  },
  {
    key: 'Moto',
    weight: '2',
    images: ['moto-carretera.jpg', 'moto-verde.jpg', 'moto-sport.jpg'],
  },
  {
    key: 'Motocicleta',
    weight: '2',
    images: [
      'motocicleta-naranja.jpg',
      'motocicleta-negra.jpg',
      'motocicleta-vespa.jpg',
    ],
  },
  {
    key: 'Motor',
    weight: '2',
    images: ['motor.jpg', 'motor-coche.jpg', 'motor-vehiculo.jpg'],
  },
  {
    key: 'Movil',
    weight: '2',
    images: ['movil-iphone.jpg', 'movil-google.jpg', 'movil-xiaomi.jpg'],
  },
  {
    key: 'Mudanza',
    weight: '2',
    images: ['mudanza.jpg', 'mudanza-cajas.jpg'],
  },
  {
    key: 'Mueble',
    weight: '2',
    images: ['mueble-cama.jpg', 'mueble-cuarto.jpg', 'mueble-sofa.jpg'],
  },
  {
    key: 'Muebles',
    weight: '2',
    images: ['mueble-cama.jpg', 'mueble-cuarto.jpg', 'mueble-sofa.jpg'],
  },
  {
    key: 'Música',
    weight: '2',
    images: ['musica.jpg', 'musica-vintage.jpg', 'musica-vinilo.jpg'],
  },
  {
    key: 'Navidad',
    weight: '2',
    images: ['navidad.jpg', 'navidades.jpg', 'navidad-arbol.jpg'],
  },
  {
    key: 'Navidades',
    weight: '2',
    images: ['navidad.jpg', 'navidades.jpg', 'navidad-arbol.jpg'],
  },
  {
    key: 'Negocio',
    weight: '2',
    images: [
      'negocios-agenda.jpg',
      'negocios-trabajo.jpg',
      'negocios-local.jpg',
    ],
  },
  {
    key: 'New York',
    weight: '3',
    images: [
      'new-york-statue-of-liberty.jpg',
      'new-york-empire.jpg',
      'new-york-times-square.jpg',
    ],
  },
  {
    key: 'Nicaragua',
    weight: '3',
    images: [
      'nicaragua-playa.jpg',
      'nicaragua-mar.jpg',
      'nicaragua-pueblo.jpg',
    ],
  },
  {
    key: 'Nintendo',
    weight: '3',
    images: [
      'nintendo-retro.jpg',
      'nintendo-switch.jpg',
      'nintendo-switch mando.jpg',
    ],
  },
  {
    key: 'Nintendo Switch',
    weight: '3',
    images: [
      'nintendo-switch dark.jpg',
      'nintendo-switch.jpg',
      'nintendo-switch mando.jpg',
    ],
  },
  {
    key: 'Nueva York',
    weight: '3',
    images: [
      'new-york-statue-of-liberty.jpg',
      'new-york-empire.jpg',
      'new-york-times-square.jpg',
    ],
  },
  {
    key: 'Nueva Zelanda',
    weight: '3',
    images: [
      'nueva-zelanda-lago.jpg',
      'nueva-zelanda-playa.jpg',
      'nueva-zelanda-montana.jpg',
    ],
  },
  {
    key: 'NY',
    weight: '3',
    images: [
      'new-york-statue-of-liberty.jpg',
      'new-york-empire.jpg',
      'new-york-times-square.jpg',
    ],
  },
  {
    key: 'Objetivo',
    weight: '1',
    images: [
      'objetivo-diana.jpg',
      'objetivo-porteria.jpg',
      'objetivo-linea.jpg',
    ],
  },
  {
    key: 'Obra',
    weight: '2',
    images: ['obra.jpg', 'obra planos.jpg'],
  },
  {
    key: 'Ocio',
    weight: '1',
    images: ['ocio-amigos.jpg', 'ocio-butacas.jpg', 'ocio-viaje.jpg'],
  },
  {
    key: 'Operación',
    weight: '1',
    images: ['operacion-medico.jpg', 'operacion-doctor.jpg'],
  },
  {
    key: 'Operación ojos',
    weight: '1',
    images: ['operacion-ojos.jpg', 'operacion-clinica.jpg'],
  },
  {
    key: 'Oposición',
    weight: '1',
    images: [
      'oposicion-estudio.jpg',
      'oposicion-examen.jpg',
      'oposicion-apuntes.jpg',
    ],
  },
  {
    key: 'Ordenador',
    weight: '2',
    images: ['pc-desktop.jpg', 'pc-escritorio.jpg', 'pc-mac.jpg'],
  },
  {
    key: 'Orlando',
    weight: '3',
    images: [
      'orlando-florida.jpg',
      'orlando-universal.jpg',
      'orlando-disney.jpg',
    ],
  },
  {
    key: 'Ortodoncia',
    weight: '2',
    images: ['ortodoncia-radio.jpg', 'ortodoncia-sonrisa.jpg'],
  },
  {
    key: 'Pádel',
    weight: '2',
    images: ['padel-pala.jpg', 'padel-pista.jpg'],
  },
  {
    key: 'Panamá',
    weight: '3',
    images: ['panama-playa.jpg', 'panama-ciudad.jpg', 'panama-palmera.jpg'],
  },
  {
    key: 'París',
    weight: '3',
    images: ['paris1.jpg', 'paris2.jpg', 'paris3.jpg'],
  },
  {
    key: 'Patinete',
    weight: '2',
    images: ['patinete1.jpg', 'patinete2.jpg'],
  },
  {
    key: 'Pc',
    weight: '2',
    images: ['pc1.jpg', 'pc2.jpg', 'pc3.jpg'],
  },
  {
    key: 'Perro',
    weight: '2',
    images: ['perro1.jpg', 'perro2.jpg', 'perro3.jpg'],
  },
  {
    key: 'Perú',
    weight: '3',
    images: ['peru1.jpg', 'peru2.jpg'],
  },
  {
    key: 'Perfume',
    weight: '2',
    images: ['perfume1.jpg', 'perfume2.jpg', 'perfume3.jpg'],
  },
  {
    key: 'Piano',
    weight: '2',
    images: ['piano1.jpg', 'piano2.jpg'],
  },
  {
    key: 'Piscina',
    weight: '2',
    images: ['piscina1.jpg', 'piscina2.jpg'],
  },
  {
    key: 'Piso',
    weight: '1',
    images: ['piso1.jpg', 'piso2.jpg', 'piso3.jpg'],
  },
  {
    key: 'Plancha',
    weight: '2',
    images: ['plancha.jpg', 'plancha2.jpg', 'plancha3.jpg'],
  },
  {
    key: 'Play',
    weight: '3',
    images: ['play1.jpg', 'play2.jpg'],
  },
  {
    key: 'Playa',
    weight: '2',
    images: ['playa1.jpg', 'playa2.jpg', 'playa3.jpg'],
  },
  {
    key: 'Polaroid',
    weight: '3',
    images: ['polaroid1.jpg', 'polaroid2.jpg'],
  },
  {
    key: 'Polonia',
    weight: '3',
    images: ['polonia1.jpg', 'polonia2.jpg', 'polonia3.jpg'],
  },
  {
    key: 'Porsche',
    weight: '3',
    images: ['porsche1.jpg', 'porsche2.jpg', 'porsche3.jpg'],
  },
  {
    key: 'Port aventura',
    weight: '3',
    images: ['port-aventura1.jpg', 'port-aventura2.jpg'],
  },
  {
    key: 'Portátil',
    weight: '2',
    images: ['portatil1.jpg', 'portatil2.jpg', 'portatil3.jpg'],
  },
  {
    key: 'Portugal',
    weight: '3',
    images: ['portugal1.jpg', 'portugal2.jpg', 'portugal3.jpg'],
  },
  {
    key: 'Praga',
    weight: '3',
    images: ['praga1.jpg', 'praga2.jpg'],
  },
  {
    key: 'Ps4',
    weight: '3',
    images: ['ps4.jpg', 'ps41.jpg'],
  },
  {
    key: 'PS5',
    weight: '3',
    images: ['ps51.jpg', 'ps5.jpg'],
  },
  {
    key: 'Punta Cana',
    weight: '3',
    images: ['puntacana1.jpg', 'puntacana2.jpg'],
  },
  {
    key: 'Real Madrid',
    weight: '3',
    images: ['realmadrid1.jpg', 'realmadrid2.jpg', 'realmadrid3.jpg'],
  },
  {
    key: 'Real Sociedad',
    weight: '3',
    images: ['real sociedad.jpg', 'real sociedad anoeta.jpg'],
  },
  {
    key: 'Reforma',
    weight: '2',
    images: ['reforma1.jpg', 'reforma2.jpg'],
  },
  {
    key: 'Regalos',
    weight: '1',
    images: ['regalo.jpg'],
  },
  {
    key: 'Reloj',
    weight: '2',
    images: ['reloj.jpg'],
  },
  {
    key: 'Rey León',
    weight: '3',
    images: ['lionking.jpg'],
  },
  {
    key: 'Reyes',
    weight: '1',
    images: ['reyes.jpg'],
  },
  {
    key: 'Reyes magos',
    weight: '2',
    images: ['reyesmagos.jpg'],
  },
  {
    key: 'Riga',
    weight: '3',
    images: ['riga.jpg'],
  },
  {
    key: 'Río de Janeiro',
    weight: '3',
    images: ['riodejaneiro.jpg'],
  },
  {
    key: 'Riviera Maya',
    weight: '3',
    images: ['rivieramaya.jpg'],
  },
  {
    key: 'Rolex',
    weight: '3',
    images: ['rolex.jpg'],
  },
  {
    key: 'Roma',
    weight: '3',
    images: ['roma.jpg'],
  },
  {
    key: 'Ropa',
    weight: '2',
    images: ['ropa.jpg'],
  },
  {
    key: 'Rueda',
    weight: '1',
    images: ['rueda.jpg'],
  },
  {
    key: 'Rumanía',
    weight: '3',
    images: ['rumania.jpg'],
  },
  {
    key: 'Samsung',
    weight: '3',
    images: ['samsumg.jpg'],
  },
  {
    key: 'San Francisco',
    weight: '3',
    images: ['sanfrancisco.jpg'],
  },
  {
    key: 'Savings',
    weight: '2',
    images: ['saving.jpg'],
  },
  {
    key: 'Scooter',
    weight: '2',
    images: ['scooter.jpg'],
  },
  {
    key: 'Seguro',
    weight: '1',
    images: ['seguro.jpg'],
  },
  {
    key: 'Seguro Coche',
    weight: '3',
    images: ['segurocoche.jpg'],
  },
  {
    key: 'Seguro moto',
    weight: '1',
    images: ['seguromoto.jpg'],
  },
  {
    key: 'Semana Santa',
    weight: '1',
    images: ['semanasanta.jpg'],
  },
  {
    key: 'Sevilla',
    weight: '3',
    images: ['sevilla.jpg'],
  },
  {
    key: 'Smart TV',
    weight: '3',
    images: ['smarttv.jpg'],
  },
  {
    key: 'Smartphone',
    weight: '3',
    images: ['smartphone.jpg'],
  },
  {
    key: 'Smartwatch',
    weight: '3',
    images: ['smartwatch.jpg'],
  },
  {
    key: 'Sofá',
    weight: '2',
    images: ['sofa.jpg'],
  },
  {
    key: 'Sri Lanka',
    weight: '3',
    images: ['srilanka.jpg'],
  },
  {
    key: 'Switch',
    weight: '3',
    images: ['switch.jpg'],
  },
  {
    key: 'Tablet',
    weight: '2',
    images: ['tablet.jpg'],
  },
  {
    key: 'Tag heuer',
    weight: '3',
    images: ['tagheuer.jpg'],
  },
  {
    key: 'Tailandia',
    weight: '3',
    images: ['tailandia.jpg'],
  },
  {
    key: 'Taiwan',
    weight: '3',
    images: ['taiwan.jpg'],
  },
  {
    key: 'Tanzania',
    weight: '3',
    images: ['tanzania.jpg'],
  },
  {
    key: 'Tatoo',
    weight: '2',
    images: ['tatoo.jpg'],
  },
  {
    key: 'Tatuaje',
    weight: '3',
    images: ['tatuaje.jpg'],
  },
  {
    key: 'Tele',
    weight: '2',
    images: ['tele.jpg'],
  },
  {
    key: 'Teléfono',
    weight: '2',
    images: ['telefono.jpg'],
  },
  {
    key: 'Telescopio',
    weight: '2',
    images: ['telescopia.jpg'],
  },
  {
    key: 'Televisión',
    weight: '2',
    images: ['television.jpg'],
  },
  {
    key: 'Tenerife',
    weight: '3',
    images: ['tenerife.jpg'],
  },
  {
    key: 'Terreno',
    weight: '1',
    images: ['terreno.jpg'],
  },
  {
    key: 'Tesla',
    weight: '3',
    images: ['tesla.jpg', 'tesla1.jpg'],
  },
  {
    key: 'Test',
    weight: '1',
    images: ['test.jpg'],
  },
  {
    key: 'Turquia',
    weight: '3',
    images: ['turquia.jpg'],
  },
  {
    key: 'TV',
    weight: '3',
    images: ['tv.jpg'],
  },
  {
    key: 'UK',
    weight: '3',
    images: ['uk.jpg'],
  },
  {
    key: 'Universidad',
    weight: '2',
    images: ['univesidad.jpg'],
  },
  {
    key: 'US',
    weight: '3',
    images: ['us.jpg'],
  },
  {
    key: 'Vacaciones',
    weight: '2',
    images: ['vacaciones.jpg'],
  },
  {
    key: 'Vacaciones 2020',
    weight: '2',
    images: ['vacaciones2020.jpg'],
  },
  {
    key: 'Valencia',
    weight: '3',
    images: ['valencia.jpg'],
  },
  {
    key: 'Vans',
    weight: '3',
    images: ['vans.jpg'],
  },
  {
    key: 'Vehículo',
    weight: '2',
    images: ['vehiculo.jpg'],
  },
  {
    key: 'Verano',
    weight: '2',
    images: ['verano.jpg'],
  },
  {
    key: 'Vespa',
    weight: '3',
    images: ['vespa.jpg'],
  },
  {
    key: 'Vestidor',
    weight: '2',
    images: ['vestidor.jpg'],
  },
  {
    key: 'Viajar',
    weight: '2',
    images: ['viajar.jpg'],
  },
  {
    key: 'Viaje',
    weight: '2',
    images: ['viaje.jpg'],
  },
  {
    key: 'Viaje verano',
    weight: '2',
    images: ['viajeverano.jpg'],
  },
  {
    key: 'Vida',
    weight: '1',
    images: ['vida.jpg'],
  },
  {
    key: 'Vietnam',
    weight: '3',
    images: ['vietnam.jpg'],
  },
  {
    key: 'Violín',
    weight: '2',
    images: ['violin.jpg', 'violin1.jpg'],
  },
  {
    key: 'Vitrocerámica',
    weight: '2',
    images: ['vitroceramica.jpg'],
  },
  {
    key: 'Zapatillas',
    weight: '2',
    images: ['zapatillas.jpg'],
  },
  {
    key: 'Zapatos',
    weight: '2',
    images: ['zapatos.jpg', 'zapatos1.jpg'],
  },
];
