export default {
  black: '#000000',
  black2: '#25303B',
  blackLight: '#444444',
  grey: '#4A4A4A',
  grey2: '#727272',
  greyText: '#9B9B9B',
  greyText2: '#6F7779',
  greyBc: '#F4F4F4',
  greyBc2: '#F4F6F7',
  greyBcInput: '#FAFBFC',
  greyLight: '#F6F5F4',
  greyLightBorders: '#D8D8D8',
  greyLightBorders2: '#DBE0E3',
  white: '#FFFFFF',
  whiteBc: '#F7F7F7',
  green: '#137e84',
  blue: '#8CBABD',
  blue2: '#9BC3D3',
  yellowLight: '#FFF9E9',
  red: '#EC0000',
  red2: '#DD5858',
  violet: '#9E3667',
};
