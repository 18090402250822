import React, { useState, useEffect } from "react";

import * as moment from "moment";

import {
  UiWrapper,
  NavigationWrapper,
  View,
  Text,
  PickerHOC,
  Layout,
  Var,
} from "coinscrap-webapp-core";

import { getCurrency } from "../../libs/Currency";
import {
  pocketMovements,
  getCategoryIcon,
} from "../data/categories/categories";
import { AddButton } from "../common/AddButton";
// import PDF from "../common/svgs/PDF";
import colors from "../../config/colors";
import routes from "../../config/routes";

import CreateExpense from "../CreatePocket/components/CreateExpense";
import MonthGraphic from "./components/MonthGraphic";
import { useSave } from "../../libs/hooks/useSave";

export default PickerHOC()(() => {
  // eslint-disable-next-line no-unused-vars
  const [localStorage, setLocalStorage] = useState(
    JSON.parse(global.localStorage.getItem("pocketData"))
  );
  const [myData, saveMyData] = useSave();

  const { selectedCategoryExpense } = localStorage;
  const findPocket = (myData?.pockets || []).find(
    (pocket) => pocket.id === selectedCategoryExpense.pocketId
  );
  const [tab, setTab] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [pocketCategory, setPocketCategory] = useState(null);
  const [expensesList, setExpensesList] = useState([]);

  const { useBackground, windowDimensions } = UiWrapper.use();
  const { navigateTo, useBackRoute } = NavigationWrapper.use();

  useBackground(colors.white);

  useBackRoute(routes.details);

  useEffect(() => {
    const array = [];
    [...Array(3)].reduce((acc, curr) => {
      const object = {
        year: Number(moment().subtract(acc, "months").format("YYYY")),
        month: Number(moment().subtract(acc, "months").format("M")),
      };
      array.push(object);
      return acc + 1;
    }, 0);

    const monthsArray = array.reverse();

    const findCategory = findPocket.categories.find(
      (category) => category.name === selectedCategoryExpense.category
    );
    let price = 0;

    let movementValue = 0;
    (findCategory.movements || []).forEach((item2) => {
      movementValue = movementValue + item2.value;
    });

    price = price + movementValue;
    setPocketCategory({
      name: findCategory.name,
      icon: getCategoryIcon(findCategory.name),
      totalExpenditure: Math.abs(price),
    });

    const formatLast3Months = monthsArray.map((item) => {
      let price = 0;

      let movementValue = 0;
      (findCategory.movements || [])
        .filter((filterItem) => {
          return (
            moment(filterItem.last).format("YYYY M") ===
            `${item.year} ${item.month}`
          );
        })
        .forEach((item2) => {
          movementValue = movementValue + item2.value;
        });

      price = price + movementValue;

      // const movementsArray = findPocket.categories.map((category) => {
      //   const categoryExpense = category.movements
      //     .filter((filterItem) => {

      //       return (
      //         moment(filterItem.last).format("YYYY M") ===
      //         `${item.year} ${item.month}`
      //       );
      //     })
      //     .reduce((acc, curr) => {
      //       return acc + curr.value;
      //     }, 0);
      //   return {
      //     icon: getCategoryIcon(category.name),
      //     name: category.name,
      //     value: Math.abs(categoryExpense),
      //     movements: category.movements.length,
      //     percentage: Math.abs(categoryExpense) / Math.abs(price),
      //   };
      // });

      return {
        date: moment(item.month, "M"),
        value: Math.abs(price),
        expenses: findCategory.movements
          .filter((filterItem) => {
            return (
              moment(filterItem.last).format("YYYY M") ===
              `${item.year} ${item.month}`
            );
          })
          .map((item) => {
            return {
              ...item,
              value: Math.abs(item.value),
              giveBack: item.value >= 0 ? true : false,
            };
          }),
      };
    });
    const date = new Date(selectedCategoryExpense.date);
    const findIndex = formatLast3Months.findIndex(
      (item) => moment(item.date).format("M") === moment(date).format("M")
    );

    setExpensesList(formatLast3Months);
    setTab(formatLast3Months[findIndex]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Layout.Container>
      <View
        whiteBcSt
        fullWidthSt
        fullHeightSt
        startSt
        style={{ padding: "15px 0", paddingBottom: 100 }}
      >
        <View fullWidthSt startSt horizontalSt style={{ marginBottom: 7 }}>
          {pocketCategory?.icon && (
            <pocketCategory.icon.svg
              style={{ width: 24, height: 24, margin: "0 10px 0 16px" }}
            />
          )}
          <Text boldSt>{pocketCategory?.name}</Text>
        </View>

        <MonthGraphic list={expensesList} tab={tab} setTab={setTab} />

        <View selfEndSt bottomSt style={{ margin: "20px 25px 25px 0" }}>
          <Text normalSt>Total gastado</Text>
          <Text h3St>
            <Var>{getCurrency(tab?.value || 0, 2, false)}</Var>
          </Text>
        </View>

        <View fullWidthSt>
          <View fullWidthSt horizontalSt spaceBetweenSt>
            <Text labelSt boldSt uppercaseSt style={{ marginLeft: 16 }}>
              Movimientos
            </Text>
            <View horizontalSt>
              {/* <PDF />
              <Text smallerSt greenSt style={{ margin: "0 15px 0 5px" }}>
                PDF
              </Text> */}
            </View>
          </View>
          {/* MOVEMENTS LIST */}

          {tab?.expenses
            ?.sort((a, b) => new Date(b.last) - new Date(a.last))
            .map((element, index) => {
              const last = index === tab.expenses?.length - 1 ? true : false;
              return (
                <View fullWidthSt topSt borderTop2St style={{ marginTop: 12 }}>
                  <Text
                    normalSt
                    boldSt
                    redSt
                    uppercaseSt
                    left16St
                    style={{ marginTop: 16, marginBottom: 9 }}
                  >
                    {element.last === moment(Date()).format("YYYY/MM/DD") &&
                      "HOY, "}
                    <Var>{moment(element.last).format("DD MMM")}</Var>
                  </Text>

                  <ListElement
                    item={element}
                    width={windowDimensions.width - 16}
                    last={last}
                    onPress={() => {
                      const data = {
                        movementDetails: {
                          ...element,
                          category: selectedCategoryExpense.category,
                          pocketId: selectedCategoryExpense.pocketId,
                        },
                      };
                      const mergedData = { ...localStorage, ...data };
                      global.localStorage.setItem(
                        "pocketData",
                        JSON.stringify(mergedData)
                      );
                      navigateTo(routes.expenseInfo);
                    }}
                  />
                </View>
              );
            })}
        </View>
      </View>

      <CreateExpense
        noText
        visibleModal={visibleModal}
        setVisibleModal={setVisibleModal}
      />
      <AddButton onClick={() => setVisibleModal(true)} />
    </Layout.Container>
  );
});

export const ListElement = ({ item, width, last, onPress }) => {
  return (
    <View
      topSt
      selfEndSt
      horizontalSt
      spaceBetweenSt
      // borderBottomDashedSt={!last}
      style={{ width: width }}
      onClick={onPress}
    >
      <View flex3St topSt style={{ paddingRight: 10 }}>
        <Text labelSt boldSt noT>
          <Var>{item.name || ""}</Var>
        </Text>
        {/* Comprobar si es de vuelta o no */}
        {item.giveBack ? (
          <Text giveBackSt>Devuelto</Text>
        ) : (
          item.info && (
            <Text smallerSt lightSt style={{ marginTop: 4 }}>
              <Var>{item.info || ""}</Var>
            </Text>
          )
        )}
        {/* <View
          style={{
            border: `1px solid #137E84`,
            borderRadius: 2,
            padding: 2,
            marginTop: 2,
          }}
        >
          <Text style={{ fontSize: 10, color: "#137E84", fontWeight: 700 }}>
            AÑADIDO MANUALMENTE
          </Text>
        </View> */}
      </View>

      {/* Comprobar si es de vuelta o no */}
      <View flexSt bottomSt>
        <Text boldSt right15St noT>
          <Var>
            {item.giveBack
              ? getCurrency(item.value, 2, false)
              : "-" + getCurrency(item.value, 2, false)}
          </Var>
        </Text>
        {/* <Text normalSt lightSt right15St noT>
          {item.giveBack ? (
            <Var>{getCurrency(0, 2, false)}</Var>
          ) : (
            item.value2 && <Var>"-" + {getCurrency(item.value, 2, false)}</Var>
          )}
        </Text> */}
      </View>
    </View>
  );
};
