import { UiWrapper } from "coinscrap-webapp-core";
import colors from "../colors";

UiWrapper.defineComponent("inputDate", {
  styles: {
    default: {
      containerComponent: {
        width: "100%",

        backgroundColor: "#FAFBFC",
        display: "flex",
      },

      inputContainerComponent: {
        height: "48px",
        border: "1px solid #EEF0F2",
        boxSizing: "border-box",
        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
      },
      inputComponent: {
        // paddingBottom: '25px',
        boxSizing: "border-box",
        paddingRight: 0,
        height: "100%",
        // paddingLeft: 5,
        fontSize: 17,
        color: colors.blackLight,
        lineHeight: "20px",
        fontFamily: "SantanderTextRegular",
      },
      iconContainerComponent: {
        padding: 0,
        height: 48,
        width: 26,
      },
      dayComponent: {
        color: colors.grey,
        fontFamily: "SantanderTextRegular",
      },

      daySelectedComponent: {
        backgroundColor: colors.red,
        color: colors.white,
      },
      actionTextComponent: {
        display: "none",
      },
    },
    fullWidthSt: {
      containerComponent: {
        width: "100%",
      },
    },

    dateWithBorderSt: {
      inputContainerComponent: {
        backgroundColor: "#FAFBFC",
        borderBottom: "1px solid #9BC3D3",
        paddingRight: 6,
      },
      containerComponent: {
        marginTop: 0,
        marginBottom: 0,
        borderBottom: "none",
      },
      labelComponent: {
        color: "#727272",
        fontFamily: "SantanderTextRegular",
        fontWeight: "normal",
        marginTop: "-2px",
        fontSize: 17,
      },
      iconComponent: {
        borderLeft: "1px solid #E5E5E5",
        paddingLeft: 10,
        paddingRight: 4,
        height: "28px",
        boxSizing: "content-box",
        width: "24px",
      },
    },

    inputMovementSt: {
      inputContainerComponent: {
        height: "55px",
      },
    },
  },
});
