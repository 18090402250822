import React, { useState } from "react";
import { InputCheck, Text, View } from "coinscrap-webapp-core";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";

export const CheckOption = ({
  item,
  index,
  expenses,
  setExpenses,
  ...props
}) => {
  const [checked, setChecked] = useState(false);
  let refName = item.name.replace(/ /g, "");

  const change = () => {
    setChecked((prevState) => !prevState);

    if (!checked) setExpenses((prevState) => [...prevState, item]);
    else {
      setExpenses(expenses.filter((element) => element.name !== item.name));
    }
  };

  return (
    <View
      fullWidthSt
      horizontalSt
      spaceBetweenSt
      checkOptionSt
      onClick={() => change()}
    >
      {item.icon && (
        <View selfCenterSt style={{ width: 32 }}>
          {/* <Image src={item.icon.png} style={{ height: 28, width: 28 }} /> */}
          <item.icon />
        </View>
      )}
      <View flexSt topSt style={{ marginLeft: 15 }}>
        <Text font16St leftSt boldSt ellipsisSt>
          {item.name}
        </Text>
        <Text font14St leftSt>
          {item.info}
        </Text>
      </View>
      <InputCheck
        key={refName}
        value={expenses.find((element) => element.name === item.name)}
        checkedIcon={<CheckBoxOutlinedIcon />}
      />
    </View>
  );
};
