import React from 'react'

export default function EditIcon(props){
    return(
        <svg 
            width="16" 
            height="16" 
            viewBox="0 0 16 16" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            style={{...props.styleSVG}}
        >
            <g id="Group 11 Copy 4">
                <rect id="Rectangle" width="16" height="16" fill="white" fill-opacity="0.01"/>
                <g id="BAN/BAN01">
                    <path id="Combined Shape" fill-rule="evenodd" clip-rule="evenodd" d="M2 10.3566V12.6562C2 12.8462 2.15376 13 2.34377 13H4.64343C4.73475 13 4.82203 12.9637 4.88649 12.8993L11.0194 6.7669L12.6039 5.18235C13.132 4.65395 13.132 3.7942 12.6039 3.26579L11.7344 2.3963C11.2057 1.86756 10.3459 1.86823 9.81783 2.3963L8.23325 3.98085L2.10071 10.1136C2.03626 10.178 2 10.2653 2 10.3566ZM10.3039 2.88241C10.5645 2.62324 10.9878 2.62257 11.2483 2.88241L12.1178 3.7519C12.378 4.01207 12.378 4.43607 12.1178 4.69624L10.7763 6.03774L8.96242 4.2239L10.3039 2.88241ZM2.68755 10.499L8.47631 4.71001L10.2902 6.52384L4.50109 12.3125H2.68755V10.499Z" fill="#137E84"/>
                </g>
            </g>
        </svg>
    )
}