import { UiWrapper } from "coinscrap-webapp-core";
import colors from "../colors";
import fonts from "../fonts";

UiWrapper.defineComponent("inputSelect", {
  styles: {
    default: {
      containerComponent: {
        width: "100%",
        maxWidth: 356,
      },

      selectContainerComponent: {
        height: "48px",
        border: "1px solid #EEF0F2",
        boxSizing: "border-box",
        backgroundColor: "#FAFBFC",
        borderRadius: "0px",
        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
        width: "100%",
        paddingRight: 0,
      },

      labelComponent: {
        fontSize: 14,
        fontFamily: fonts.SantanderTextRegular,
        fontWeight: 400,
        color: colors.grey2,
        textAlign: "left",
      },

      selectComponent: {
        width: "90%",
      },
      iconComponent: {},
    },

    borderGreenSt: {
      selectContainerComponent: {
        borderBottom: "1px solid #8CBABD",
      },
    },

    desplegableSt: {
      containerComponent: {
        height: "48px",
        width: "100%",
        backgroundColor: "transparent",
        margin: 0,
        border: 0,
        borderWidth: 0,
      },
      selectContainerComponent: {
        backgroundColor: "transparent",
        borderRadius: "0px",
        border: 0,
        borderWidth: 0,
        width: "100%",
        paddingRight: 0,
      },
      selectComponent: {
        width: "100%",
        margin: 0,

        border: 0,
        borderWidth: 0,
        padding: 5,
      },
      menuContainerComponent: {
        backgroundColor: colors.greyLight,
      },
    },

    selectWithBorderSt: {
      containerComponent: {
        height: 56,
        maxWidth: "none",
        width: "100%",
        marginTop: 0,
        marginBottom: 15,
        boxSizing: "border-box",
      },
      selectContainerComponent: {
        borderBottom: "2px solid" + colors.greyLightBorders2,
        height: "100%",

        border: "1px solid #EEF0F2",
        boxSizing: "border-box",
        backgroundColor: "#FAFBFC",
        borderRadius: "0px",

        width: "100%",
        paddingRight: 0,
      },
      selectComponent: {
        color: colors.blackLight,
        fontSize: 17,
        lineHeight: "20px",
        fontFamily: "SantanderTextRegular",
        fontWeight: "normal",
        width: "100%",
        flexShrink: 1,
      },
      iconComponent: {
        borderLeft: "1px solid #E5E5E5",
        paddingLeft: 10,
        paddingRight: 4,
        // paddingTop: 15,
        // paddingBottom: 5,
        height: "28px",
        boxSizing: "content-box",

        top: "50%",
        transform: "translateY(-50%)",
        color: colors.green,
      },
    },
    selectWithBorderGreenSt: {
      containerComponent: {
        height: "48px",
        width: "100%",
        backgroundColor: "transparent",
        boxSizing: "border-box",
        margin: "0px 0px 20px",
      },
      selectContainerComponent: {
        backgroundColor: colors.greyBcInput,
        borderRadius: "0px",
        border: "1px solid #EEF0F2",
        borderBottomColor: "#8CBABD",
        width: "100%",
        paddingRight: 0,
      },
      selectComponent: {
        width: "90%",
        color: colors.black,
        fontSize: 17,
        fontFamily: "SantanderTextRegular",
      },
      iconComponent: {
        color: colors.green,
        width: 35,
        borderLeft: "1px solid #E5E5E5",
        paddingLeft: 10,
      },
    },
    centerVerticalLabelSt: {
      labelComponent: {
        transform: "translate(12px, 21px) scale(1)",
      },
    },
    marginTopSt: {
      containerComponent: {
        marginTop: 16
      },
    },
  },
});
