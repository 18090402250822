import { UiWrapper } from "coinscrap-webapp-core";
import colors from "../colors";
import fonts from "../fonts";

UiWrapper.defineComponent("textField", {
  styles: {
    default: {
      color: "#ccc",
      containerComponent: {
        width: "80%",
        // border: "1px solid #f60",
      },
      inputContainerComponent: {
        borderRadius: "30px",
        backgroundColor: "rgb(100, 100, 100, 0.35)",
        border: "1px solid " + colors.orange,
        fontFamily: fonts.regular,
      },
      inputComponent: {
        textAlign: "center",
        // padding: "16px 0 12px 0",
        // padding: "25px 0px 7px",
      },
      labelComponent: {
        fontSize: 16,
        fontWeight: 400,
        fontFamily: fonts.regular,
        textAlign: "center",
        width: "90%",
        color: colors.grey,
        // display: "none",
      },
      noLabelMode: {
        inputComponent: {
          paddingTop: 7,
        },
      },
    },

    centerSt: {
      inputComponent: {
        textAlign: "center",
      },
    },
    transparentSt: {
      inputComponent: {
        backgroundColor: "rgba(0, 0, 0, 0)",
      },
    },

    integerSt: {
      inputComponent: {
        textAlign: "center",
        paddingTop: "12px",
        fontSize: "22px",
      },
    },

    squareWithBorderSt: {
      containerComponent: {
        border: "1px solid #DEEDF2",
        boxSizing: "border-box",
        width: "100%",
        height: "64px",
        borderBottom: 0,
        borderRadius: 0,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        marginBottom: 0,
      },
      inputContainerComponent: {
        backgroundColor: "#FAFBFC",
        borderBottom: "1px solid #9BC3D3",
        borderRadius: 0,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        height: "100%",
      },
      inputComponent: {
        height: "100%",
        paddingBottom: 6,
        fontSize: 20,
        fontFamily: fonts.SantanderTextRegular,
        fontWeight: 400,
        color: colors.blackLight,
        textAlign: "left",
      },
      labelComponent: {
        // paddingTop: 6,
        fontSize: 14,
        fontFamily: fonts.SantanderTextRegular,
        fontWeight: 400,
        color: colors.grey2,
        textAlign: "left",
      },
    },
    centerVerticalLabelSt: {
      labelComponent: {
        transform: "translate(12px, 21px) scale(1)",
      },
    },

    font16St: {
      containerComponent: {
        height: "48px",
      },
      inputComponent: {
        fontSize: 16,
      },
    },

    height56St: {
      containerComponent: {
        height: "56px",
        marginTop: 0,
      },
    },

    bigSt: {
      containerComponent: {
        width: "100%",
        borderBottom: "2px solid" + colors.blue,
        paddingBottom: 12,
      },
      inputComponent: {
        color: colors.grey,
        fontSize: 35,
        fontWeight: 400,
        textAlign: "center",
        fontFamily: fonts.SantanderTextRegular,

        padding: 0,
        height: 40,
      },
    },
    lightBcSt: {
      inputContainerComponent: {
        backgroundColor: "rgba(255,255,255,0)",
      },
      focusedMode: {
        inputContainerComponent: {
          backgroundColor: "rgba(255,255,255,0)",
        },
      },
    },
    borderBottomSt: {
      inputContainerComponent: {
        paddingBottom: 0,
      },
    },
    inputImgModalBackgroundSt: {
      containerComponent: {
        width: "100%",
        height: 50,
        backgroundColor: colors.white,
        borderRadius: "4px 4px 0px 0px",
        paddingBottom: 0,
        justifyContent: "center",
        padding: "0px 15px",
      },
      inputComponent: {
        color: colors.blackLight,
        fontSize: "17px",
        fontWeight: "normal",
        lineHeight: "24px",
        textAlign: "left",
      },
    },
    paddingTopSt: {
      inputComponent: {
        paddingTop: 15,
        height: 25,
      },
    },
    mediumSt: {
      containerComponent: {
        borderBottom: "2px solid" + colors.blue,
        paddingBottom: 12,
      },
      labelComponent: {
        width: "auto",
      },
      inputComponent: {
        color: "#444444",
        fontSize: 30,
        fontWeight: "light",
        textAlign: "center",
        fontFamily: "SantanderTextRegular",
        backgroundColor: colors.white,
        textDecorationColor: colors.green,
        padding: 0,
      },
    },
  },
});
