import { UiWrapper } from "coinscrap-webapp-core";

UiWrapper.defineComponent("radioOption", {
  styles: {
    default: {
      // border: "1px solid #f60",
      textComponent: {
        fontSize: "15px",
        marginTop: 12,
      },
      containerComponent: {
        alignItems: "flex-start",
        color: "#fff",
      },
      radioComponent: {
        color: "#fff",
      },
    },
  },
});
