import React from "react";

import {
  UiWrapper,
  NavigationWrapper,
  View,
  Text,
  PickerHOC,
  Layout,
  Page,
} from "coinscrap-webapp-core";

import RightIcon from "../common/svgs/RightIcon";
import Bin from "../common/svgs/Bin";
import colors from "../../config/colors";
import routes from "../../config/routes";
import Footer from "../common/Footer";
import ButtonFooter from "../common/ButtonFooter";
import { useSave } from "../../libs/hooks/useSave";

export default PickerHOC()(() => {
  const {
    query: { pocketId },
  } = Page.use();
  const [myData, saveMyData] = useSave();
  const { useBackground, useFooter } = UiWrapper.use();
  const { navigateTo, useBackRoute } = NavigationWrapper.use();

  const settings = [
    {
      name: "Nombre e imagen",
      route: routes.editName,
    },
    {
      name: "Gastos",
      route: routes.editExpenses,
    },
    {
      name: "Presupuestos",
      route: routes.createTarget,
    },
    {
      name: "Notificaciones",
      route: routes.details,
    },
  ];

  useBackground(colors.white);

  useBackRoute(routes.details);

  useFooter(
    <Footer>
      <ButtonFooter text="Guardar" action={() => navigateTo(routes.details)} />
    </Footer>
  );

  return (
    <Layout.Container>
      <View fullWidthSt>
        {settings.map((item, index) => {
          return (
            <ListElement
              name={item.name}
              last={index === settings.length - 1 ? true : false}
              route={item.route}
            />
          );
        })}
      </View>
      <View
        fullWidthSt
        startSt
        horizontalSt
        borderTop2St
        style={{ borderTopColor: "#DDE2E5", paddingTop: 17 }}
        onClick={async () => {
          try {
            await saveMyData({
              ...myData,
              pockets: myData.pockets.filter((item) => item.id !== pocketId),
            });

            navigateTo(routes.home);
          } catch (error) {
            console.error(error);
          }
        }}
      >
        <Bin style={{ margin: "0 8px 0 16px" }} />
        <Text normalSt greenSt>
          Borrar este pocket
        </Text>
      </View>
    </Layout.Container>
  );
});

export const ListElement = ({ name, last, route, onPress }) => {
  const { navigateTo } = NavigationWrapper.use();

  return (
    <View
      fullWidthSt
      centerSt
      selfEndSt
      horizontalSt
      spaceBetweenSt
      borderBottomDashedSt={!last}
      style={{
        borderBottomColor: colors.blue2,
        padding: "15px 0",
        marginBottom: 0,
      }}
      onClick={() => {
        name === "Presupuestos"
          ? navigateTo(routes.createTarget, {
              queryParams: { amount: 2500, mode: "edit" },
            })
          : navigateTo(route);
      }}
    >
      <Text labelSt left16St>
        {name}
      </Text>
      <RightIcon style={{ marginRight: 23 }} />
    </View>
  );
};
