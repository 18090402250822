import React from "react";

import { View, Text, Image, Var, UiWrapper } from "coinscrap-webapp-core";

import { getCurrency } from "../../../libs/Currency";
import Arrow from "../svgs/Arrow";

const SlideItem = (props) => {
  const { windowDimensions } = UiWrapper.use();
  const minWidth = windowDimensions.width < 350;

  return (
    <View onClick={props.onClick} boxSt>
      {props.image && (
        <View style={{ background: props.imgColor, width: "100%" }}>
          <Image
            src={props.image}
            topRadiusSt
            fullWidthSt
            style={{
              height: "145px",
              opacity: props.opacity,
            }}
          />
        </View>
      )}
      <View
        width90St
        horizontalSt
        spaceBetweenSt
        topSt
        style={{
          padding: "15px 0px",
        }}
      >
        <View autoWidthSt>
          <Text boldSt noT style={minWidth && { fontSize: 18 }}>
            {(props.title && <Var>{props.title}</Var>) || <br />}
          </Text>
        </View>
        <View horizontalSt>
          <View autoWidthSt bottomSt>
            <Text font14St grey2St regularSt>
              Este mes
            </Text>

            <Text font24St boldSt noT style={minWidth && { fontSize: 22 }}>
              <Var>{getCurrency(props.objective, 2, false)}</Var>
            </Text>
          </View>

          <Arrow style={{ marginLeft: 10 }} />
        </View>
      </View>
    </View>
  );
};

export default SlideItem;
