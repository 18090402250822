import React from "react";

export default function CalendarIcon(props) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...props.styleSVG }}
    >
      <g id="Group 18">
        <g id="Group 17">
          <path
            style={{ ...props.stylePath }}
            id="Combined Shape"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M27.3131 9.23136V5.69539C27.3131 4.36727 26.2324 3.28654 24.9043 3.28654H21.9863V1.33341C21.9863 0.964925 21.6881 0.666748 21.3196 0.666748C20.9511 0.666748 20.653 0.964925 20.653 1.33341V3.28654H7.34696V1.33341C7.34696 0.964925 7.04879 0.666748 6.6803 0.666748C6.31181 0.666748 6.01363 0.964925 6.01363 1.33341V3.28654H3.08459C1.75647 3.28654 0.675741 4.36727 0.675741 5.69539V9.28621C0.675213 9.29352 0.673222 9.30032 0.671235 9.30711L0.671234 9.30711C0.668927 9.31499 0.666626 9.32285 0.666626 9.33146C0.666626 9.34007 0.668928 9.34794 0.671235 9.35581C0.673222 9.3626 0.675213 9.3694 0.675741 9.37671V24.9096C0.675741 26.2377 1.75647 27.3184 3.08459 27.3184H24.9043C26.2324 27.3184 27.3131 26.2377 27.3131 24.9096V9.43156C27.3152 9.41826 27.3188 9.40549 27.3223 9.39275C27.3278 9.3729 27.3333 9.35312 27.3333 9.33146C27.3333 9.30981 27.3278 9.29002 27.3223 9.27018C27.3188 9.25743 27.3152 9.24467 27.3131 9.23136ZM3.08459 4.61987H6.01363V6.66479C6.01363 7.03328 6.31181 7.33146 6.6803 7.33146C7.04879 7.33146 7.34696 7.03328 7.34696 6.66479V4.61987H20.653V6.66479C20.653 7.03328 20.9511 7.33146 21.3196 7.33146C21.6881 7.33146 21.9863 7.03328 21.9863 6.66479V4.61987H24.9043C25.4974 4.61987 25.9798 5.10229 25.9798 5.69539V8.66479H2.00907V5.69539C2.00907 5.10229 2.4915 4.61987 3.08459 4.61987ZM3.08459 25.9851H24.9043C25.4974 25.9851 25.9798 25.5027 25.9798 24.9096V9.99813H2.00907V24.9096C2.00907 25.5027 2.4915 25.9851 3.08459 25.9851Z"
            fill="#137E84"
          />
        </g>
      </g>
    </svg>
  );
}
