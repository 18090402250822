import axios from "axios";

export const getPockets = async (content) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const res = await axios.get(
    `https://api.pockets-dev.coinscrap.com/backend/s3/defaultUser`,
    content,
    config
  );

  return res.data;
};
export const updatePockets = async (content) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const res = await axios.post(
    `https://api.pockets-dev.coinscrap.com/backend/s3/defaultUser`,
    content,
    config
  );

  return res.data;
};
